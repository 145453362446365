import Vue from 'vue'
import store from '../store'

const primitiveFields = [
  'cd',
  'name'
]
export default class Country {
  constructor (fromStore) {
    for (const field of primitiveFields) {
      this[field] = fromStore[field]
    }
  }

  toString () {
    return this.name
  }

  static byCd (cd) {
    return store.getters.countries.find(c => c.cd === cd)
  }

  static async loadAll (reload = false) {
    try {
      if (reload || (store.getters.countries.length === 0)) {
        const { data } = await Vue.$api.country.all()
        store.commit(
          'setCountries',
          Country.parseMultipleFromStore(data.items)
        )
      }
      return true
    } catch (error) {
      return false
    }
  }

  static parseMultipleFromStore (objs) {
    if (objs && objs[0].constructor !== Country) {
      const ess = []
      for (const obj of objs) {
        ess.push(new Country(obj))
      }
      return ess
    }
    return objs || []
  }

  static sort (countries) {
    return countries.sort((a, b) => a.name < b.name ? -1 : 1)
  }
}
