import store from '../store'
import Vue from 'vue'
import ChargeType from './ChargeType'
import Lodge from './Lodge'
import PropertyType from './PropertyType'
import ItineraryIcon from './ItineraryIcon'
import Gallery from './Gallery'

export default class Property {
  static primitiveFields () {
    return [
      'altName',
      'description',
      'destinationId',
      'disabled',
      'id',
      'location',
      'more',
      'name',
      'order',
      'pref',
      'propertyTypeId',
      'standardCurrency'
    ]
  }

  constructor (fromStore) {
    if (fromStore) {
      for (const field of Property.primitiveFields()) {
        this[field] = fromStore[field]
      }
      const chargeTypeField =
      fromStore.chargeTypes && fromStore.chargeTypes.length > 0
        ? 'chargeTypes'
        : 'chargetypes'
      if (fromStore[chargeTypeField] && fromStore[chargeTypeField].length > 0) {
        this.chargeTypes = []
        for (const chargeType of fromStore[chargeTypeField]) {
          this.chargeTypes.push(new ChargeType(chargeType, this.standardCurrency))
        }
      }
      if (fromStore.lodges && fromStore.lodges.length > 0) {
        this.lodges = []
        for (const lodge of fromStore.lodges) {
          this.lodges.push(new Lodge(lodge, this.standardCurrency))
        }
        if (!this.more && this.lodges.length === 0) {
          this.lodges.push(new Lodge(null, this.standardCurrency))
        }
      }
      if (fromStore.type) {
        this.type = new PropertyType(fromStore.type)
      }
    } else {
      for (const field of Property.primitiveFields()) {
        this[field] = null
      }
    }
  }

  get icon () {
    const itineraryIcon = ItineraryIcon.all().find(
      ii => ii.id === this.propertyTypeId
    )
    return itineraryIcon || null
  }

  get image () {
    return Gallery.firstForProperty(this.id)?.name
  }

  get imageLink () {
    if (this.image) {
      return store.getters.serverMedia + 'images/small_landscape/' + this.image
    } else {
      return ''
    }
  }

  hasLodgeById (lodgeId) {
    if (this.hasLodges()) {
      return this.lodges.find(l => l.id === lodgeId)
    }
  }

  hasLodges () {
    return this.lodges && this.lodges.length > 0
  }

  hasOnlyOneChargeType () {
    if (this.chargeTypes && this.chargeTypes.length === 1) {
      return this.chargeTypes[0]
    }
    return false
  }

  hasOnlyOneLodge () {
    if (this.lodges && this.lodges.length === 1) {
      return this.lodges[0]
    }
    return false
  }

  hasMoreThanOneLodge () {
    return this.hasLodges() && !this.hasOnlyOneLodge()
  }

  lodgeById (lodgeId) {
    if (this.hasLodges()) {
      return Lodge.byId(lodgeId, this.lodges)
    }
  }

  isThirdParty () {
    return !this.more
  }

  saveThirdParty () {
    return this.id ? Vue.$api.property.editThirdParty(this.id, this) : Vue.$api.property.newThirdParty(this)
  }

  static async loadAll (reload = false) {
    try {
      if (reload || !store.state.properties.properties) {
        const { data } = await Vue.$api.property.all()
        store.commit(
          'setProperties',
          Property.parseMultipleFromStore(data.items)
        )
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static all () {
    if (store.state.properties.properties && store.state.properties.properties[0].constructor !== Property) {
      store.commit('setProperties', Property.parseMultipleFromStore(store.state.properties.properties))
    }
    return store.state.properties.properties
  }

  static byId (id, properties = null) {
    if (!properties) {
      return Property.all() ? Property.all().find(p => p.id === id) : false
    }
    return properties.find(p => p.id === id)
  }

  static byLodgeId (lodgeId) {
    return Property.more() ? Property.more().find(p => p.lodges.findIndex(l => l.id === lodgeId) >= 0) : false
  }

  static byName (name, properties = null) {
    return (properties || Property.all()).find(p => p.name === name)
  }

  static more (withDisabled = false) {
    return Property.all() ? Property.all().filter(p => p.more && (!p.disabled || withDisabled)) : []
  }

  static parseMultipleFromStore (objs) {
    const ps = []
    for (const obj of objs) {
      ps.push(new Property(obj))
    }
    return ps
  }

  static thirdParties (withDisabled = false) {
    return Property.all()
      ? Property.all().filter(p => !p.more && (!p.disabled || withDisabled)).sort((a, b) => {
        return (a.name.trim().localeCompare(b.name.trim()))
      })
      : []
  }
}
