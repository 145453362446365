import DateTime from './DateTime'
import TravelExecutive from './TravelExecutive'

export default class EnquiryFirstReply {
  constructor (obj = null) {
    if (obj) {
      this.id = obj.id
      this.handlerId = obj.handlerId
      this.date = new DateTime(obj.date)
      this.reason = obj.reason
    }
  }

  get handler () {
    return TravelExecutive.byId(this.handlerId)
  }
}
