import Amount from './Amount'
import DateTime from './DateTime'
import TreeHouse from './TreeHouse'

export default class QuoteElementRoomTreeHouse extends TreeHouse {
  constructor (obj, currency = 'ZAR') { // if not from store, then it's from API
    super(obj)
    this.currency = currency
    if (obj) {
      const isQuoteElementRoomTreeHouse = typeof obj === 'object' && obj.constructor === QuoteElementRoomTreeHouse
      this.discountApplies = obj.discountApplies !== false
      this._nights = isQuoteElementRoomTreeHouse ? obj._nights : obj.nights
      this.quoteEmailElementRoomTypeId = obj.quoteEmailElementRoomTypeId
      this._rate = obj._rate || (isNaN(obj.rate) ? 0 : obj.rate) || 0
      this._total = obj._total || (isNaN(obj.total) ? 0 : obj.total) || 0
      this._dateIn = obj._dateIn || obj.dateIn
      this._dateOut = obj._dateOut || obj.dateOut
    } else {
      this.discountApplies = true
      this._nights = 0
      this._rate = 0
      this._total = 0
    }
  }

  get dateIn () {
    return new DateTime(this._dateIn)
  }

  set dateIn (d) {
    this._dateIn = DateTime.startOfDay(d).dateOnlyDB
    if (this.dateOut?.isValid()) {
      if (DateTime.diffDays(this.dateIn, this.dateOut) <= 0) {
        this._dateOut = new DateTime(
          this.dateIn.moment.clone().add(1, 'd')
        ).dateOnlyDB
      }
    } else {
      this._dateOut = new DateTime(
        this.dateIn.moment.clone().add(this.nights || 1, 'd')
      ).dateOnlyDB
    }
  }

  get dateOut () {
    return new DateTime(this._dateOut)
  }

  set dateOut (d) {
    this._dateOut = DateTime.startOfDay(d).dateOnlyDB
  }

  get nights () {
    this._nights = this.dateOut.isValid() && this.dateIn.isValid()
      ? DateTime.diffDays(this.dateIn, this.dateOut)
      : this._nights || 0
    return this._nights
  }

  get rate () {
    return new Amount(this._rate, this.currency)
  }

  set rate (value) {
    this._rate = Amount.parseValue(value)
  }

  get total () {
    return new Amount(this._total, this.currency)
  }

  changeCurrency (newCurrency) {
    this.currency = newCurrency
  }

  clear (dateIn, dateOut) {
    this.id = null
    this.name = null
    this._rate = 0
    this._dateIn = dateIn
    this._dateOut = dateOut
    this.discountApplies = true
  }

  getTotalDiscounted (adults, childrenOver2, discount) {
    const totalAmount = this.getTotalNonDiscountedAmount(adults, childrenOver2)
    if (totalAmount.amount > 0) {
      if (this.discountApplies && discount.isPercentage()) { // discount only if percentage
        totalAmount.amount -= discount.getDiscountAmount(totalAmount).amount
      }
    }
    return totalAmount
  }

  getTotalNonDiscountedAmount (adults, childrenOver2) {
    let totalAmount = 0
    if (this._rate) {
      totalAmount =
          adults * this.nights * this._rate +
           childrenOver2 * this.nights * this._rate / 2
    }
    return new Amount(totalAmount, this.currency)
  }

  static fromAutosave (data) {
    const treeHouse = new QuoteElementRoomTreeHouse(data, data.currency)
    treeHouse._dateIn = new DateTime(data._dateIn)
    treeHouse._dateOut = new DateTime(data._dateOut)
    return treeHouse
  }

  static objectToSave (treeHouse, totalAmount) {
    return treeHouse
      ? {
          id: treeHouse.id,
          name: treeHouse.name,
          discountApplies: treeHouse.discountApplies,
          rate: treeHouse.rate?.amount || 0,
          dateIn: treeHouse.dateIn?.dateOnlyDB,
          dateOut: treeHouse.dateOut?.dateOnlyDB,
          total: treeHouse.totalAmount?.amount || 0,
          nights: treeHouse.nights
        }
      : null
  }
}
