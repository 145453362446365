import Vue from 'vue'
import store from '../store'
import Role from './Role'
import SideBarMenu from './SideBarMenu'
import Signature from './Signature'

export default class User {
  static primitiveFields () {
    return [
      'email',
      'expires',
      'id',
      'initials',
      'isHoTEs',
      'isMT',
      'isOC',
      'isSU',
      'isTC',
      'isTE',
      'isTPSM',
      'name',
      'resetPassword',
      'surname',
      'token',
      'username',
      'verifyEmail'
    ]
  }

  constructor (fromLogin) {
    for (const field of User.primitiveFields()) {
      this[field] = fromLogin[field]
    }
    this.role = new Role(fromLogin.role) // role
    this.signature = new Signature(fromLogin.signature) // signature
  }

  get fullName () {
    return `${this.name} ${this.surname}`
  }

  get isOnlyTE () {
    return this.isTE && !(this.isHoTEs || this.isMT || this.isOC || this.isSU || this.isTC || this.isTPSM)
  }

  get superuser () {
    return this.isSU
  }

  canSee (pageName) { // TODO
    let canSee = false
    switch (pageName) {
      case 'dashboard':
        canSee = this.isHoTEs || this.isSU
        break
      default:
        canSee = true
        break
    }
    return canSee
  }

  hasAnyOfRoles (roles) {
    return roles.findIndex(r => {
      return this[`is${r}`]
    }) >= 0
  }

  toString () {
    return this.fullName
  }

  static setAfterLoginRoute () {
    const user = User.current()
    if (user.isMT) {
      SideBarMenu.selectItemByName('additional')
      return 'Additional'
    } else if (user.isTC || user.isOC) {
      SideBarMenu.selectItemByName('createTask')
      return 'Enquiries'
    } else if (user.isHoTEs || user.isTPSM) {
      SideBarMenu.selectItemByName('dashboard')
      return 'Dashboard'
    } else { // user.isTE
      SideBarMenu.selectItemByName('unattendedTasks')
      return 'Enquiries'
    }
  }

  static current () {
    const user = store.state.users.user
    if (user) {
      if (user.constructor !== User) {
        return new User(user)
      }
    }
    return user
  }

  static isLoggedIn () {
    return !!store.state.users.user
  }

  static async login (username, password) {
    try {
      const { data } = await Vue.$api.user.login(username, password)
      if (data) {
        data.user.expires = data.expires
        store.commit('setUser', { ...data.user, token: data.token })
      } else {
        console.error('NOT LOGGED IN')
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  static loginErrorMessage () {
    return 'Wrong username or password'
  }

  static async logout () {
    if (User.current()) {
      await store.dispatch('logout')
    }
  }

  static async passwordForgotten (username) {
    return Vue.$api.user.passwordForgotten({ username })
  }
}
