import store from '@/store'
import Vue from 'vue'

export default class Role {
  constructor (obj = null) {
    this.id = obj?.id || null
    this.assignable = obj?.assignable
    this.description = obj?.description || null
    this.level = obj?.level || null
    this.roleShort = obj?.roleShort || null
  }

  toString () {
    return this.description
  }

  static all () {
    return Role.parseMultipleFromStore(store.state.users.roles)
  }

  static allAssignable () {
    return Role.parseMultipleFromStore(store.state.users.roles.filter(r => r.assignable))
  }

  static async loadAll (reload = false) {
    try {
      if (reload || store.state.users.roles.length === 0) {
        const { data: roles } = await Vue.$api.user.getRoles()
        store.commit('setRoles', roles.map(role => new Role(role)).sort((a, b) =>
          a.roleShort < b.roleShort ? -1 : 1
        ))
      }
      return true
    } catch (error) {
      return false
    }
  }

  static parseMultipleFromStore (objs) {
    const roles = []
    if (objs && objs[0].constructor !== Role) {
      for (const obj of objs) {
        roles.push(new Role(obj))
      } store.commit('setRoles', roles.sort((a, b) =>
        a.roleShort < b.roleShort ? -1 : 1
      ))
      return roles
    }
    return objs || []
  }
}
