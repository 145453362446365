import axios from 'axios'
import store from '../store'
import User from '../classes/MyUser'

export default () => {
  axios.interceptors.request.use(config => {
    if (!config.url.startsWith('http')) {
      config.url = `${store.getters.serverApi}${config.url}`
    }
    if (User.current() && User.current().token) {
      config.headers.Authorization = `Bearer ${User.current().token}`
    }
    config.headers.Accept = 'application/json'
    config.headers['X-Env'] = store.getters.isTest ? 'test' : 'live'
    config.headers['X-Platform'] = 'MPTC'
    return config
  }, err => {
    return Promise.reject(err)
  })

  axios.interceptors.response.use(response => {
    return response
  }, error => {
    console.error('ERROR', error)

    if (error.response && error.response.status === 401) { // if token expired, send to login
      User.logout()
      return Promise.reject(error)
    } else if (error.response && error.response.status === 500) { // don't know why now we are getting here
      User.logout()
      return Promise.reject(error)
    } else {
      return Promise.reject(error)
    }
  })
}
