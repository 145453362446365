import DateTime from './DateTime'
import TravelExecutive from './TravelExecutive'

export default class Autosave {
  constructor (obj) {
    this.enquiryId = obj.enquiryId
    this.id = obj.id
    this.json = obj.json
    this._lastUpdatedAt = obj.lastUpdatedAt
    this._userId = obj.userId
  }

  get date () {
    return new DateTime(this._lastUpdatedAt)
  }

  get text () {
    return `${this.date.short} | Created by ${this.user}`
  }

  get user () {
    return TravelExecutive.byId(this._userId)
  }
}
