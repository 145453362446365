export default class LodgePackageItem {
  static primitiveFields () {
    return ['createdAt', 'createdById', 'deletedAt', 'deletedById', 'id', 'included', 'name']
  }

  constructor (obj) {
    this.createdAt = obj.createdAt
    this.createdById = obj.createdById
    this.deletedAt = obj.deletedAt
    this.deletedById = obj.deletedById
    this.id = obj.id
    this.included = obj.included
    this.name = obj.name
    this.toBeDeleted = false
  }
}
