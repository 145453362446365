import Vue from 'vue'

const state = {
  newInterest: null
}

const mutations = {
  clearNewInterestState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setNewInterest (state, newInterest) {
    state.newInterest = newInterest
  }
}

export default {
  state,
  mutations
}
