import store from '../store'
import Vue from 'vue'
import User from './MyUser'
import DateTime from './DateTime'

const archivableTypes = ['failed', 'reassigned', 'unattended24']
const deletableTypes = ['failed', 'reassigned', 'noTP', 'unattended24']
const enqIdOnCardOpensNotificationHoTEs = [
  'ogplinknotsent',
  'OGPquestionnairecritical',
  'payment',
  'unattended24'
]
const enqIdOnCardOpensNotificationTE = [
  'assigned', // assigned and not attended
  'failed',
  'ogplinknotsent',
  'OGPquestionnairecritical',
  'payment',
  'unattended24',
  'unopened']
const notificationsTypesToDisplay = [
  'assigned', // assigned and not attended
  'failed',
  'noTP', // unassigned
  'ogplinknotsent',
  'OGPquestionnairecritical',
  'payment',
  'travelinsurancerequested',
  'unattended24'
]
const restorableTypes = ['deleted']
const repeaterInterval = 60000

function getText (notification, user) {
  let txt
  switch (notification.type) {
    case 'assigned':
      txt = `${notification.teName} assigned it to you`
      break
    case 'failed':
      txt = `Quote ${notification.quoteId} not delivered to ${notification.descr.replace(';', ', ')}`
      if (notification.descrExtra) {
        txt += ` [ ${notification.descrExtra} ]`
      }
      break
    case 'noTP':
      txt = 'Not assigned to a TP'
      break
    case 'ogplinknotsent':
      txt = `Link to OGP not sent to ${notification.descr}`
      break
    case 'OGPquestionnairecritical':
      txt = `${notification.descr || 'Guest'}. Questionnaire on OGP with flags`
      break
    case 'travelinsurancerequested':
      txt = `${notification.descr || 'Guest'}. Requested <strong>travel insurance</strong>`
      break
    case 'unattended24':
      txt = 'Assigned'
      if (!user.isTE) {
        txt += ' to ' + notification.teName
      }
      txt += ' and not yet attended'
      break
    default:
  }
  return txt
}

export default class Notification {
  constructor (notificationObj, fromStore = false) {
    for (const [k, notification] of Object.entries(notificationObj)) {
      this[k] = notification
    }
    this.date = new DateTime(this.date)
    this.notificationDate = new DateTime(this.notificationDate)
    this.displayDate = this.notificationDate

    if (!fromStore) {
      const user = User.current()
      this.displayOnCardTE = enqIdOnCardOpensNotificationTE.includes(this.type)
      this.displayOnCardHoTEs = enqIdOnCardOpensNotificationHoTEs.includes(this.type)
      this.hiding = false
      this.isArchivable = archivableTypes.includes(this.type)
      this.isDeletable = deletableTypes.includes(this.type)
      this.isRestorable = restorableTypes.includes(this.type)
      this.isDismissible = !this.dismissed
      this.severity = 'N'
      this.severityHoTEs = 'N'
      switch (this.type) {
        // case 'deleted':
        //    this.severityHoTEs = 'Err'
        //   break
        case 'assigned':
          this.isQuotable = user.isTE
          this.severity = 'R'
          break
        case 'failed':
          this.isQuotable = user.isTE
          this.isReAssignable = user.isHoTEs || user.isTC
          this.severity = 'R'
          break
        case 'noTP':
          this.isAssignable = user.isHoTEs || user.isTC
          this.severity = 'R'
          break
        // case 'payment':
        //    this.severity = 'R'
        //   break
        // case 'reassigned':
        //    this.isQuotable = user.isTE
        //    this.isReAssignable = true
        //   break
        case 'unattended24':
          this.isQuotable = user.isTE
          this.isReAssignable = true
          this.severity = 'R'
          break
        case 'ogplinknotsent':
          this.severity = 'R'
          break
        case 'OGPquestionnairecritical':
          this.severity = 'R'
          break
        // case 'unopened':
        //    this.severity = 'R'
        //   break
        default:
      }
    }
  }

  get text () {
    const user = User.current()
    return getText(this, user)
  }

  async dismiss () {
    try {
      const { data } = await Vue.$api.notification.dismiss(this.id)
      if (data) {
        store.commit('dismissNotification', this.id)
        this.dismissed = true
      }
      return data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  getColor () {
    // let color = 'green'
  // if ( this.displayOnCardTE && user.isTE) {
  //   color =  this.displayOnCardTE
  // } else if ( this.displayOnCardHoTEs && (user.isHoTEs || user.isTPSM)) {
  //   color =  this.displayOnCardHoTEs
  // } else if (user.isHoTEs || user.isTPSM) {
  //   switch ( this.severityHoTEs) {
  //     case 'N':
  //       color = 'green'
  //       break
  //     case 'R':
  //       color = 'orange'
  //       break
  //     case 'Err':
  //       color = 'red'
  //       break
  //   }
  // } else {
  //   switch ( this.severity) {
  //     case 'N':
  //       color = 'green'
  //       break
  //     case 'R':
  //       color = 'orange'
  //       break
  //     case 'Err':
  //       color = 'red'
  //       break
  //   }
  // }
    return 'red'
  }

  setHidden () {
    store.commit('setNotificationHidden', this)
  }

  setHiding () {
    store.commit('setNotificationHiding', this)
  }

  setNotHiding () {
    store.commit('setNotificationNotHiding', this)
  }

  static get () {
    if (
      store.state.notifications.notifications &&
      store.state.notifications.notifications.length > 0 &&
      store.state.notifications.notifications[0].constructor !== Notification
    ) {
      store.commit(
        'setNotifications',
        Notification.parseMultipleFromStore(
          store.state.notifications.notifications
        )
      )
    }
    return store.state.notifications.notifications
  }

  static getById (id) {
    const notifications = store.state.notifications && store.state.notifications.notifications ? store.state.notifications.notifications : null
    if (notifications) {
      const notification = notifications.find(n => n.id === id)
      if (notification) {
        if (notification.constructor !== Notification) {
          return new Notification(notification, true)
        }
        return notification
      }
    }
    return false
  }

  static async load (limit, reload = true) {
    try {
      if (reload) {
        let { status, data } = await Vue.$api.notification.all(limit)
        data = status === 200 ? data : null
        let processed
        if (data) {
          processed = Notification.processNotifications(data)
        }
        store.commit('setNotifications', processed || [])
      }
      return store.state.notifications.notifications
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static numNewEnquiries () {
    return (store.getters.newNotificationsEnqIds || []).length
  }

  static parseMultipleFromStore (objs) {
    const ps = []
    for (const obj of objs) {
      ps.push(new Notification(obj, true))
    }
    return ps
  }

  static processNotifications (notifications) {
    const myNs = []
    const newNotificationsEnqIds = []
    notifications = notifications.filter(n => notificationsTypesToDisplay.includes(n.type))
    for (const notificationObj of notifications) {
      const notification = new Notification(notificationObj)
      myNs.push(notification)
      if (notification.viewed === 0) {
        newNotificationsEnqIds.push(notification.enqId)
      }
    }
    return { new: myNs, newNotificationsEnqIds }
  }

  static setRepeater () {
    try {
      const r = setInterval(async () => {
        await Notification.load()
      }, repeaterInterval)
      store.commit('setNotificationsRepeater', r)
      return true
    } catch (error) {
      console.error(error)
      return error
    }
  }

  static async setAllViewed () {
    try {
      const { data } = await Vue.$api.notification.setViewed()
      if (data) {
        store.commit('viewNotifications')
      }
      return data
    } catch (error) {
      console.error(error)
      return error
    }
  }
}
