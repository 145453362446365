import Vue from 'vue'
import store from '../store'

const primitiveFields = [
  'id',
  'name',
  'value',
  'enquirySourceId',
  'propertyId',
  'lodgeId',
  'fromWebsite',
  'extraInfoRequired',
  'disabled',
  'order'
]

const defaultSelectionId = 1 // Concierge Desk [MPTCD]
export default class EnquirySource {
  constructor (fromStore) {
    for (const field of primitiveFields) {
      this[field] = fromStore[field]
    }
    this.options = []
    if (fromStore.options && fromStore.options.length > 0) {
      for (const option of fromStore.options) {
        this.options.push(new EnquirySource(option))
      }
    }
  }

  get activeOptions () {
    return this.options.filter(o => !o.disabled)
  }

  update () {
    return Vue.$api.enquiry.editSource(this)
  }

  static active () {
    return EnquirySource.all().filter(o => !o.disabled)
  }

  static all () {
    return store.getters.enquirySources
  }

  static byId (id) {
    return EnquirySource.all().find(es => es.id === id)
  }

  static async create (obj) {
    let source
    try {
      const { data } = await Vue.$api.enquiry.createSource(obj)
      source = new EnquirySource(data)
    } catch (error) {

    }
    return source
  }

  static getDefaultSelection () {
    return store.getters.enquirySources.find(es => es.id === defaultSelectionId)
  }

  static async loadAll (reload = false) {
    let ics = []
    try {
      if (reload || (store.getters.enquirySources.length === 0)) {
        const { data } = await Vue.$api.enquiry.getSources()
        ics = EnquirySource.parseMultipleFromStore(data)
        store.commit('setEnquirySources', EnquirySource.sort(ics))
      }
      return ics
    } catch (error) {
      return false
    }
  }

  static parseMultipleFromStore (objs) {
    if (objs && objs.length > 0 && objs[0].constructor !== EnquirySource) {
      const ess = []
      for (const obj of objs) {
        ess.push(new EnquirySource(obj))
      }
      return ess
    }
    return objs || []
  }

  static sort (enquirySources) {
    return enquirySources.sort((a, b) =>
      a.order < b.order
        ? -1
        : a.order === b.order
          ? a.name < b.name
            ? -1
            : 1
          : 1
    )
  }
}
