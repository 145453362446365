import Vue from 'vue'
import ChargeType from './ChargeType'

export default class RoomType {
  constructor (obj = null) {
    this.name = null
    this.toBeDeleted = false
    if (obj) {
      this.createdById = obj.createdById
      this.deletedById = obj.deletedById
      this.id = obj.id
      this.name = obj.name
      this.number = obj.number
      this.order = obj.order
      this.updatedById = obj.updatedById
      const chargeTypeField = obj.chargeTypes && obj.chargeTypes.length > 0 ? 'chargeTypes' : 'chargetypes'
      if (obj[chargeTypeField] && obj[chargeTypeField].length > 0) {
        this.chargeTypes = []
        for (const chargeType of obj[chargeTypeField]) {
          this.chargeTypes.push(new ChargeType(chargeType))
        }
      }
    }
  }

  hasOnlyOneChargeType () {
    if (this.chargeTypes && this.chargeTypes.length === 1) {
      return this.chargeTypes[0]
    }
    return false
  }

  static byId (id, roomTypes) {
    return (roomTypes || []).find(rt => rt.id === id)
  }

  static updateLodgeRoomTypes (changes) {
    return Vue.$api.property.updateLodgeRoomTypes(changes)
  }
}
