export default class QuoteInfo {
  constructor (obj) {
    this.field = obj.field
    this.heading = obj.heading
    this.text = obj.text
  }

  static fromAutosave (data) {
    return new QuoteInfo(data)
  }
}
