import Vue from 'vue'
import Role from './Role'
import Signature from './Signature'

export default class Staff {
  constructor (staff) {
    this.id = staff?.id || null
    this.username = staff?.username || null
    this.name = staff?.name || null
    this.surname = staff?.surname || null
    this.initials = staff?.initials || null
    this.email = staff?.email || null
    this.active = staff?.active || false
    this.blocked = staff?.blocked || false
    this.fullPathProfilePic = staff?.fullPathProfilePic || null
    this.resetPassword = staff?.resetPassword || false
    this.verifyEmail = staff?.verifyEmail || false
    this.role = new Role(staff?.role || null)
    this.signature = new Signature(staff?.signature || null)
  }

  get fullName () {
    return `${this.name} ${this.surname}`
  }

  blockToggle () {
    return Vue.$api.user.blockUnblock(this.id)
  }

  checkInitialsAvailable () {
    return Vue.$api.user.checkInitials({
      userId: this.id || 0,
      initials: this.initials
    })
  }

  checkUsernameAvailable () {
    return Vue.$api.user.checkUsername(this.username)
  }

  isActive () {
    return this.active
  }

  create () {
    const obj = {}
    obj.role = this.role
    obj.signature = new Signature(this.signature, true)
    obj.username = this.username
    obj.name = this.name
    obj.surname = this.surname
    obj.initials = this.initials
    obj.email = this.email

    return Vue.$api.user.saveDetails(obj)
  }

  sendNewPassword () {
    return Staff.sendNewPassword(this.id)
  }

  toString () {
    return this.fullName
  }

  uploadProfilePic (newProfilePic) {
    return Staff.uploadProfilePic(this.id, newProfilePic)
  }

  // return cloned Staff // null
  static byId (id, staffMembers = []) {
    return id ? staffMembers.find(sm => sm.id === id) : null
  }

  static clone (staffMember) {
    return new Staff(staffMember)
  }

  static async loadAll () {
    try {
      const { data } = await Vue.$api.user.allWithRole()
      return data.map(u => new Staff(u)).sort((u1, u2) => u1.fullName < u2.fullName ? -1 : 1)
    } catch (error) {
      console.error(error)
    }
  }

  static save (details) {
    if (details.constructor === Staff) {
      return details.create()
    }
    return Vue.$api.user.saveDetails(details)
  }

  static sendNewPassword (userId) {
    return Vue.$api.user.resetPassword(userId)
  }

  static uploadProfilePic (userId, newProfilePic) {
    const formData = new FormData()
    formData.append('files[0]', newProfilePic)
    return Vue.$api.user.setProfilePicture({ userId, formData })
  }
}
