import store from '../store'
import Vue from 'vue'
import Staff from './Staff'

export default class TravelExecutive extends Staff {
  static all () {
    if (
      store.state.users.travelExecutives &&
      store.state.users.travelExecutives[0].constructor !== TravelExecutive
    ) {
      store.commit(
        'setTravelExecutives',
        TravelExecutive.parseMultiple(
          store.state.users.travelExecutives
        )
      )
    }
    return store.state.users.travelExecutives
  }

  static allActive () {
    return TravelExecutive.all()?.filter(t => t.active) || []
  }

  static byId (id, tes = null) {
    if (!tes) {
      tes = TravelExecutive.all()
    }
    return tes.find(te => te.id === id)
  }

  static async loadAll (reload = false) {
    try {
      if (reload || !store.state.users.travelExecutives) {
        const { data } = await Vue.$api.user.getTravelExecutives()
        store.commit('setTravelExecutives', TravelExecutive.parseMultiple(data.items))
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static parseMultiple (objs) {
    const items = []
    for (const obj of objs) {
      items.push(new TravelExecutive(obj))
    }
    return items.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : a.surname > b.surname ? 1 : -1)
  }
}
