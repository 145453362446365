import Vue from 'vue'
const state = {
  attachments: null,
  defaultInfo: null,
  quote: null
}

const mutations = {
  clearQuoteState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setAttachments (state, attachments) {
    state.attachments = attachments
  },
  setDefaultInfo (state, defaultInfo) {
    state.defaultInfo = defaultInfo
  },
  setQuote (state, quote) {
    // if (quote) {
    //   state.quotes = state.quotes || {}
    //   quote.options = quote.options || []
    //   quote.options[0] = quote.options[0] || { services: [], tours: [], travelArrangements: [] }
    //   for (const i of quote.services) {
    //     quote.options[0].services.push(JSON.parse(JSON.stringify(i)))
    //     i.adultRate = isNaN(i.adultRate) ? 0 : parseFloat(i.adultRate)
    //     i.childRate = isNaN(i.childRate) ? 0 : parseFloat(i.childRate)
    //     i.total = i.adultRate * i.adults + i.childRate * i.children
    //     quote.options[0].totalCostServices += i.total
    //   }
    //   for (const i of quote.tours) {
    //     quote.options[0].tours.push(JSON.parse(JSON.stringify(i)))
    //     i.adultRate = isNaN(i.adultRate) ? 0 : parseFloat(i.adultRate)
    //     i.childRate = isNaN(i.childRate) ? 0 : parseFloat(i.childRate)
    //     i.total = i.adultRate * i.adults + i.childRate * i.children
    //     quote.options[0].totalCostTours += i.total
    //   }
    //   quote.totalCostTravelArrangements = 0
    //   for (const i of quote.travelArrangements) {
    //     quote.elements[0].travelArrangements.push(JSON.parse(JSON.stringify(i)))
    //     i.adultRate = isNaN(i.adultRate) ? 0 : parseFloat(i.adultRate)
    //     i.childRate = isNaN(i.childRate) ? 0 : parseFloat(i.childRate)
    //     i.total = i.adultRate * i.adults + i.childRate * i.children
    //     quote.totalCostTravelArrangements += i.total
    //   }
    //   state.quotes[quote.id] = { ...quote }
    //   state.quote = { ...quote }
    // } else {
    state.quote = quote
    // }
  },
  updateDefaultInfo (state, updatedDI) {
    state.defaultInfo.find(dI => dI.field === updatedDI.field && dI.international === updatedDI.international).text = updatedDI.text
  }
}

const actions = {
  async setDelivered ({ commit }, payload) {
    const { data } = await Vue.$api.quote.setDelivered(payload)
    commit('setDelivered', payload.id)
    return data
  },
  async updateDefaultInfo ({ dispatch }, info) {
    await Vue.$api.quote.updateDefaultInfo(info)
    await dispatch('getDefaultInfo', true)
    return true
  }
}

export default {
  state,
  mutations,
  actions
}
