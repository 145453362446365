import SideBarMenuItem from '@/classes/SideBarMenuItem'
import moment from 'moment'
import Vue from 'vue'

const state = {
  appVersion: null,
  appVersionNum: null,
  isTest: null,
  menuItems: null,
  nav: null,
  selectedMenuItem: null,
  newVersion: true,
  serverApi: null,
  serverMedia: null,
  windowHeight: null
}

const mutations = {
  clearSettingState (state) {
    state.menuItems = null
    state.selectedMenuItem = null
  },
  clearAppVersion (state) {
    state.newVersion = true
    state.appVersion = null
  },
  setAppVersion (state, version) {
    state.newVersion = true
    state.appVersion = version.date
    state.appVersionNum = '1.0.' + version.id.toString()
  },
  setMenu (state, menuItems) {
    state.menuItems = menuItems
  },
  setNav (state, page) {
    state.nav = page
  },
  setNoNewVersion (state) {
    state.newVersion = false
  },
  setSelectedMenuItem  (state, item) {
    state.selectedMenuItem = item
  },
  setServer (state, type) {
    state.isTest = type !== 'live'
    if (type === 'live') {
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
      state.serverApi = 'https://api.mptconsol.co.za/'
    } else if (type === 'test') {
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
      state.serverApi = 'https://api.mpttesting.co.za/'
    } else { // local
      state.serverMedia = 'https://mpt-media.s3.us-east-2.amazonaws.com/'
      state.serverApi = 'https://api.mpttesting.co.za/'
      // state.serverApi = 'http://mptconsolapi/'
    }
  },
  setWindowHeight (state, h) {
    state.windowHeight = h
  }
}

const actions = {
  async checkNewAppVersion ({ commit, getters }, force = false) {
    if (force) {
      commit('clearAppVersion')
    }
    const { data } = await Vue.$api.setting.getAppVersion()
    const version = data.date
    if (!moment(getters.appVersion).isValid() || (moment(version).isValid() && moment(version).isAfter(moment(getters.appVersion)))) {
      commit('setAppVersion', data)
      return true
    } else {
      return false
    }
  },
  setNav ({ commit }, page) {
    commit('setNav', page)
  },
  setNoNewVersion ({ commit }) {
    commit('setNoNewVersion', true)
  },
  setServerLive ({ commit }) {
    commit('setServer', 'live')
  },
  setServerLocal ({ commit }) {
    commit('setServer', 'local')
  },
  setServerTest ({ commit }) {
    commit('setServer', 'test')
  },
  setWindowHeight ({ commit }, h) {
    commit('setWindowHeight', h)
  }
}

const getters = {
  appVersion: state => state.appVersion,
  appVersionNum: state => state.appVersionNum,
  isTest: state => state.isTest,
  nav: state => state.nav,
  newVersion: state => state.newVersion,
  selectedMenuItem: state => SideBarMenuItem.parseFromStore(state.selectedMenuItem),
  serverApi: state => state.serverApi,
  serverMedia: state => state.serverMedia,
  windowHeight: state => state.windowHeight
}

export default {
  state,
  mutations,
  actions,
  getters
}
