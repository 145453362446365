import DateTime from './DateTime'

export default class OGPQuestionnaireAnswer {
  constructor (obj) {
    if (obj) {
      this._answer = obj.answer
      this.order = obj.order
      this.question = obj.question
      this.questionType = obj.questionType
    }
  }

  get answer () {
    if (this.questionType === 'date') {
      return this._answer ? (new DateTime(this._answer)).dateOnly : null
    }
    return this._answer
  }
}
