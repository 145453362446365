import DateTime from './DateTime'
import OGPQuestionnaireAnswer from './OGPQuestionnaireAnswer'

export default class OGPQuestionnaire {
  constructor (obj) {
    if (obj) {
      this.answers = []
      this._createdAt = obj.createdAt
      this._deletedAt = obj.deletedAt
      this.guestQuestionnaireShortLinkId = obj.guestQuestionnaireShortlinkId
      this._updatedAt = obj.updatedAt
      this.id = obj.id
      if (obj.answers?.length > 0) {
        for (const answer of obj.answers) {
          this.answers.push(new OGPQuestionnaireAnswer(answer))
        }
        this.answers.sort((a, b) => a.order === b.order ? 0 : a.order < b.order ? -1 : 1)
      }
    }
  }

  get createdAt () {
    return new DateTime(this._createdAt)
  }

  get deletedAt () {
    return new DateTime(this._deletedAt)
  }

  get updatedAt () {
    return new DateTime(this._updatedAt)
  }
}
