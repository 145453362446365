import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import store from './store'
import interceptorsSetup from './helpers/interceptors'

// import(process.env.BASE_URL + 'css/main.css')
import './assets/css/app.scss'

import VTooltip from 'v-tooltip'
// import VueResource from 'vue-resource'
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'
import factories from '@/api'

import axios from 'axios'
import VueAxios from 'vue-axios'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios)
Vue.use(VTooltip)
// Vue.use(VueResource)
// Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.$api = factories
Vue.prototype.$api = factories
Vue.config.productionTip = false

/* eslint-disable no-new */
interceptorsSetup()

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
