import moment from 'moment'
import Vue from 'vue'
import Amount from './Amount'

export default class LodgeFee {
  constructor (obj = null, currency = 'ZAR') {
    this.currency = currency
    if (obj) {
      this.amount = obj._amount || obj.amount
      this.category = obj.category
      this.daily = !!obj.daily
      this.id = obj.id
      this.local = !!obj.local
      this.name = obj.name
      this.typeId = obj.typeId
      this.year = obj.year
    } else {
      this.amount = 0
      this.category = null
      this.daily = true
      this.id = null
      this.local = true
      this.name = ''
      this.typeId = null
      this.year = moment().year()
    }
  }

  get amount () {
    return new Amount(this._amount, this.currency)
  }

  set amount (value) {
    this._amount = Amount.parseValue(value)
  }

  get currency () {
    return this._currency
  }

  set currency (currency) {
    this._currency = currency
  }

  static byId (id, fees) {
    return fees ? fees.filter(f => f.id === id) : null
  }

  // names is array
  static byNames (fees, names) {
    return fees.filter(f => names.includes(f.name))
  }

  static byYear (fees, year) {
    return fees.filter(f => f.year === year)
  }

  // names is array
  static byNamesAndYear (fees, names, year, international) {
    return fees.filter(
      f => {
        return names.includes(f.name) &&
        f.year === year &&
        f.local === !international
      }
    )
  }

  static filteredGroupedByName (fees, year, local) {
    const groupedFees = {}
    if (year) {
      fees = fees.filter(f => f.year === parseInt(year))
    }
    const fromPreviousYear = fees.length === 0
    if (fromPreviousYear) {
      fees = fees.filter(f => f.year === (parseInt(year) - 1))
    }
    if (local === true || local === false) {
      fees = fees.filter(f => f.local === local)
    }
    for (const fee of fees) {
      if (!groupedFees[fee.name]) {
        groupedFees[fee.name] = []
      }
      groupedFees[fee.name].push(fee)
    }
    const res = {}
    for (const [name, myGroupedFees] of Object.entries(groupedFees)) {
      const obj = {}
      for (const myGroupedFee of myGroupedFees) {
        obj.currency = obj.currency || myGroupedFee.amount.currency
        obj.daily = obj.daily || myGroupedFee.daily
        obj.typeId = obj.typeId || myGroupedFee.typeId
        obj[myGroupedFee.category] = fromPreviousYear ? 0 : myGroupedFee.amount.amount
        obj.k = `${name.toLowerCase().replace(/\s/g, '')}_${moment().valueOf()}`
      }
      obj.adult = obj.adult || 0
      obj.child = obj.child || 0
      obj.vehicle = obj.vehicle || 0
      res[name] = obj
    }
    return res
  }

  static filteredGroupedByNameForEditing (fees, year) {
    const local = LodgeFee.filteredGroupedByName(fees, year, true)
    const international = LodgeFee.filteredGroupedByName(fees, year, false)
    const res = {}
    for (const name of Object.keys(local)) {
      res[name] = {
        local: local[name],
        international: international[name],
        typeId: local[name].typeId,
        k: `${local[name].k}_${moment().valueOf()}`,
        currency: local[name].currency,
        daily: local[name].currency
      }
    }
    return res
  }

  static formatBeforeSave (fees, lodgeId) {
    const data = []
    for (const [year, names] of Object.entries(fees)) {
      for (const fs of Object.values(names)) {
        const typeId = fs.typeId
        data.push({ typeId, lodgeId, category: 'adult', amount: fs.local.adult, year, local: true })
        data.push({ typeId, lodgeId, category: 'child', amount: fs.local.child, year, local: true })
        data.push({ typeId, lodgeId, category: 'vehicle', amount: fs.local.vehicle, year, local: true })
        data.push({ typeId, lodgeId, category: 'adult', amount: fs.international.adult, year, local: false })
        data.push({ typeId, lodgeId, category: 'child', amount: fs.international.child, year, local: false })
        data.push({ typeId, lodgeId, category: 'vehicle', amount: fs.international.vehicle, year, local: false })
      }
    }
    return data
  }

  // fees comes from LodgeFee.formatBeforeSave
  static async update (fees) {
    return Vue.$api.property.updateLodgeFees(fees)
  }
}
