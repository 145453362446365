export default class Signature {
  static primitiveFields () {
    return [
      'email',
      'fullPathProfilePic',
      'id',
      'jobTitle',
      'label',
      'mobile',
      'name',
      'profilePic',
      'skype',
      'telephone',
      'userId']
  }

  constructor (obj, toSave = false) {
    this.email = obj?.email || null
    if (!toSave) {
      this.fullPathProfilePic = obj?.fullPathProfilePic || null
    }
    this.id = obj?.id || null
    this.jobTitle = obj?.jobTitle || null
    this.label = obj?.label || null
    this.mobile = obj?.mobile || null
    this.name = obj?.name || null
    this.profilePic = obj?.profilePic || null
    this.skype = obj?.skype || null
    this.telephone = obj?.telephone || null
    this.userId = obj?.userId || null
  }

  // toString () {
  //   return this.email + ' ' + this.jobTitle
  // }
}
