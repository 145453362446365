import store from '@/store'
import Vue from 'vue'
import Quote from './Quote'

export default class DefaultInfo {
  constructor (obj) {
    this.field = obj.field
    this.heading = obj.heading
    this.international = obj.international
    this.optional = obj.optional
    this.order = obj.order
    this.text = obj.text
  }

  toString () {
    return Quote.textAreaToHtml(this.text)
  }

  async updateText (text) {
    try {
      await Vue.$api.quote.updateDefaultInfo({ ...this, text })
      store.commit('updateDefaultInfo', { field: this.field, international: this.international, text })
    } catch (error) {
      console.error(error)
    }
  }

  static all () {
    return DefaultInfo.parseAndStore(store.state.quotes.defaultInfo)
  }

  static byField (field, international) {
    return DefaultInfo.all().find(
      di => di.field === field && di.international === international
    )
  }

  static getHeadingFromField (field) {
    return DefaultInfo.all().find(di => di.field === field)?.heading || null
  }

  static getTextFromField (field, international = null) {
    return DefaultInfo.all().find(
      di => di.field === field &&
        (international === null ? true : di.international === international)
    )?.text || null
  }

  static async loadAll (reload = false) {
    try {
      if (reload || !store.getters.defaultInfo || store.getters.defaultInfo.length === 0) {
        const { data } = await Vue.$api.quote.getDefaultInfo()
        DefaultInfo.parseAndStore(data)
      }
      return true
    } catch (error) {
      return false
    }
  }

  static parseAndStore (objs) {
    if (objs) {
      const wasDefaultInfo = objs[0].constructor === DefaultInfo
      const dis = []
      for (const obj of objs) {
        dis.push(new DefaultInfo(obj))
      }
      if (!wasDefaultInfo) {
        store.commit('setDefaultInfo', dis)
      }
      return dis
    }
    return objs || []
  }
}
