import Vue from 'vue'
import store from '../store'

const primitiveFields = ['id', 'filename', 'category', 'descr']

export default class ItineraryIcon {
  constructor (fromStore) {
    for (const field of primitiveFields) {
      this[field] = fromStore[field]
    }
  }

  get link () {
    return ItineraryIcon.folder() + this.filename
  }

  static all () {
    let icons = store.state.enquiries.itineraryIcons || []
    if (icons.length > 0 && icons[0].constructor !== ItineraryIcon) {
      icons = ItineraryIcon.parseMultipleFromStore(icons)
      store.commit(
        'setItineraryIcons',
        icons
      )
    }
    return icons
  }

  static byId (id) {
    return ItineraryIcon.all().find(i => i.id === id)
  }

  static folder () {
    return store.getters.serverMedia + 'images/icons_292939/'
  }

  static getImportantNotesIcon () {
    const icon = ItineraryIcon.all().find(
      i => i.filename.toLowerCase().includes('important-notes')
    )
    if (icon) {
      return ItineraryIcon.folder() + icon.filename
    }
  }

  static getWifiIcon () {
    const icon = ItineraryIcon.all().find(i =>
      i.filename.toLowerCase().includes('wi-fi')
    )
    if (icon) {
      return ItineraryIcon.folder() + icon.filename
    }
  }

  static async loadAll (reload = false) {
    try {
      if (reload || ItineraryIcon.all().length === 0) {
        const { data } = await Vue.$api.itinerary.getIcons()
        store.commit(
          'setItineraryIcons',
          ItineraryIcon.parseMultipleFromStore(data.items)
        )
      }
      return true
    } catch (error) {
      return false
    }
  }

  static parseMultipleFromStore (objs) {
    if (objs && objs[0].constructor !== ItineraryIcon) {
      const ess = []
      for (const obj of objs) {
        ess.push(new ItineraryIcon(obj))
      }
      return ess
    }
    return objs || []
  }
}
