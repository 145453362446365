import { trim } from 'lodash'
import Vue from 'vue'
import bankDetails from '../config/bankDetails'
import Amount from './Amount'
import DateTime from './DateTime'
import QuoteAttachment from './QuoteAttachment'
import QuoteOption from './QuoteOption'
import TravelExecutive from './TravelExecutive'
import { defaultFields, visasZim, visasSA } from '../config/quote'
import DefaultInfo from './DefaultInfo'
import QuoteInfo from './QuoteInfo'
import Autosave from './Autosave'
import QuotePayment from './QuotePayment'
const cleanText = text => {
  return text
    ? trim(text).replace('<span class="ql-cursor"></span>', '')
    : ''
}
export default class Quote {
  constructor (quoteObj = null) {
    this.fullyLoaded = false
    // START TO CHECK
    this.ids = []
    this.autosaveId = null
    this.autosaves = []
    this.formData = null
    this.filename = ''
    this.contacts = []
    this.contactsMore = []
    this.images = []
    this.options = []
    this._attachments = []
    this._enqAttachments = []
    this._serviceFeeAdd = false
    this._txt1 = ''
    this._txt2 = ''
    this._txtAfterSignature = ''
    this.confirmationNum = null
    this.infos = []
    this.isConfirmation = false
    this.isQuote = false
    this.itineraryAttached = false
    this.pdfAttached = false
    this.reference = null
    this.serviceFeeShow = false
    this.subject = ''
    this.nationality = null
    this.opened = null

    // END TO CHECK
    if (quoteObj) { // From Guest Details
      this.id = quoteObj.id
      this.opened = quoteObj.opened
      this.saved = quoteObj.saved
      this.sent = quoteObj.sent
      this.sentMore = quoteObj.sentMore
      this.isConfirmation = quoteObj.isConfirmation || false
      this.isQuote = quoteObj.isQuote || false
      this._date = quoteObj._date || quoteObj.date
      this._dateSent = quoteObj._dateSent || quoteObj.dateSent
      this._dateDelivered = quoteObj._dateDelivered || quoteObj.dateDelivered
      this._handlerId = quoteObj.handlerId
      this._total = quoteObj._total || quoteObj.total
      this.currency = quoteObj.currency
    }
  }

  get attachments () {
    return this._attachments
  }

  get autosaves () {
    return this._autosaves
  }

  set autosaves (value) {
    this._autosaves = value
  }

  get currency () {
    return this._currency
  }

  set currency (currency) {
    this._currency = currency
  }

  get enqAttachments () {
    return this._enqAttachments
  }

  get date () {
    return new DateTime(this._date)
  }

  get dateSent () {
    return new DateTime(this._dateSent)
  }

  get dateDelivered () {
    return new DateTime(this._dateDelivered)
  }

  get defaultInfo () {
    return Quote.defaultInfo().filter(
      (di) => di.international === this.isInternational
    )
  }

  get firstQuoteOption () {
    if (this.options && this.options.length > 0) {
      return this.options[0]
    }
  }

  get handler () {
    return TravelExecutive.byId(this._handlerId)
  }

  get hasOptions () {
    return this.options && this.options.length > 0
  }

  get isConfirmation () {
    return this._isConfirmation
  }

  set isConfirmation (isConfirmation) {
    const wasConfirmation = this._isConfirmation
    this._isConfirmation = isConfirmation
    if (isConfirmation && !wasConfirmation) {
      if (this.firstQuoteOption?.infos) {
        for (const defaultField of Quote.defaultFields()) {
          const infoArr = this.firstQuoteOption.infos.filter(
            (dt) => defaultField === dt.field
          )
          if (!infoArr || infoArr.length === 0) {
            const defInfoArr = this.defaultInfo.filter(
              (dt) => defaultField === dt.field
            )
            if (defInfoArr && defInfoArr.length > 0) {
              this.addInfo(defInfoArr[0])
            }
          } else {
            this.addInfo(infoArr[0])
          }
        }
      } else {
        this.infos = [...Quote.defaultInfo()]
      }
      if (this.visasSAForNationality) {
        this.addInfo(this.visasSAForNationality)
      }
      if (this.visasZimForNationality) {
        this.addInfo(this.visasZimForNationality)
      }
    }
  }

  get isInternational () {
    return (
      this.hasOptions &&
      this.options[0].elements &&
      this.options[0].elements.length > 0 &&
      this.options[0].elements[0].international
    )
  }

  get missingTexts () {
    let arrayMissingTexts = []
    if (!(this.contacts?.length > 0)) {
      arrayMissingTexts.push('Please add Recipient')
    }
    if (!(this.subject?.length > 0)) {
      arrayMissingTexts.push('Please add Subject')
    }
    if (!(this.txt1?.length > 0 || this.txt2?.length > 0 || this.options?.length > 0 || this.images?.length > 0)) {
      arrayMissingTexts.push('Email body is empty')
    }
    if (this.options) {
      for (const [indexOption, option] of this.options.entries()) {
        if (!option.isCompleted()) {
          arrayMissingTexts = arrayMissingTexts.concat(option.missingTexts.map(t => `Option ${indexOption + 1} - ${t}`))
        }
      }
    }
    return arrayMissingTexts
  }

  get nationality () {
    return this._nationality
  }

  set nationality (nationality) {
    this._nationality = nationality
  }

  get otherAutosaves () {
    return (this.autosaves || []).filter(
      aq => !this.autosaveId || aq.id !== this.autosaveId
    )
  }

  get serviceFeeAdd () {
    return this._serviceFeeAdd
  }

  set serviceFeeAdd (value) {
    this._serviceFeeAdd = value
    this.serviceFeeShow = !value ? false : this.serviceFeeShow
    this.serviceFee = value ? Quote.serviceFeePerc() : 0
    if (this.options) {
      for (const option of this.options) {
        option.serviceFeeAdd = value
        option.serviceFee = value ? Quote.serviceFeePerc() : 0
      }
    }
  }

  get totalAmount () {
    return new Amount(this._total, this.currency)
  }

  get txtAfterSignature () {
    return this._txtAfterSignature
  }

  set txtAfterSignature (value) {
    this._txtAfterSignature = cleanText(value)
  }

  get txt1 () {
    return this._txt1
  }

  set txt1 (value) {
    this._txt1 = cleanText(value)
  }

  get txt2 () {
    return this._txt2
  }

  set txt2 (value) {
    this._txt2 = cleanText(value)
  }

  get visasSAForNationality () {
    if (
      Quote.visasSA() &&
        this.nationality &&
        this.infos.filter((i) => i.field === 'visaSA').length === 0
    ) {
      const visaArr = Quote.visasSA().filter(
        (v) => v.country.cd === this.nationality.cd
      )
      if (visaArr && visaArr.length > 0) {
        return {
          field: 'visaSA',
          heading: 'Visa Requirements for South Africa',
          text: visaArr[0].text
        }
      }
    }
  }

  get visasZimForNationality () {
    if (
      Quote.visasZim() &&
        this.nationality &&
        this.infos.filter((i) => i.field === 'visaZim').length === 0
    ) {
      const visaArr = Quote.visasZim().filter(
        (v) => v.country.cd === this.nationality.cd
      )
      if (visaArr && visaArr.length > 0) {
        return {
          field: 'visaZim',
          heading: 'Visa Requirements for Zimbabwe',
          text: visaArr[0].text
        }
      }
    }
  }

  addInfo (t) {
    if (t.order) {
      this.infos.splice(t.order - 1, 0, t)
    } else {
      this.infos.push({ ...t })
    }
  }

  addAttachment (attachment) {
    const alreadyThere =
      this.attachments.findIndex(a => a.id === attachment.id) >= 0
    if (!alreadyThere) {
      this._attachments.push(attachment)
    }
  }

  addAttachments (attachments) {
    this._attachments.splice(0, this._attachments.length)
    for (const attachment of attachments) {
      this.addAttachment(attachment)
    }
  }

  addEnqAttachments (attachments) {
    this._enqAttachments.splice(0, this._enqAttachments.length)
    for (const attachment of attachments) {
      const alreadyThere =
        this.enqAttachments.findIndex(a => a.filename === attachment.filename) >= 0
      if (!alreadyThere) {
        this._enqAttachments.push(attachment)
      }
    }
  }

  addDefaultAttachments () {
    const defaultAttachments = QuoteAttachment.getAttachments().filter(a => a.default)
    for (const defaultAttachment of defaultAttachments) {
      this.addAttachment(defaultAttachment)
    }
  }

  addOption (quoteOption = null, isQuote = true) {
    // const empty = !!quoteOption
    if (!quoteOption) {
      quoteOption = new QuoteOption()
    }
    this.options = this.options || []
    this.options.push(quoteOption)

    if (this.options.length === 1) {
      this.addDefaultAttachments()
      const q = this.firstQuoteOption
      for (const attachment of q.attachments) {
        this.addAttachment(attachment)
      }

      this.confirmationNum = q.confirmationNum
      this.isConfirmation = this.isConfirmation || q.isConfirmation
      this.isQuote = isQuote
      this.pdfAttached = isQuote
      this.itineraryAttached = q.itineraryAttached || false
      this.reference = q.reference
      this.serviceFeeAdd = q.serviceFee && q.serviceFee > 0
      this.serviceFeeShow = q.serviceFeeShow
      this.contacts = this.contacts?.length > 0 ? this.contacts : q.contacts
      this.contactsMore = this.contactsMore?.length > 0 ? this.contactsMore : q.contactsMore

      this.subject = this.subject || q.subject || ''
      this.txt1 = this.txt1 || q.txt1
      this.txt2 = this.txt2 || q.txt2
      this.txtAfterSignature = this.txtAfterSignature || q.txtAfterSignature
    }
  }

  async autosave () {
    try {
      const json = JSON.parse(JSON.stringify(this))
      delete json._autosaves
      for (const option of json.options) {
        for (const element of option.elements) {
          if (element._lodge) {
            delete element._lodge.packages
            delete element._lodge.chargeTypes
            delete element._lodge.contacts
            delete element._lodge.roomTypes
            delete element._lodge.treehouses
            if (element.rooms) {
              for (const room of element.rooms) {
                delete room.lodge
              }
            }
          }
        }
      }
      const { data } = await Vue.$api.quote.autosave({
        id: this.autosaveId,
        enquiryId: this.enquiryId,
        json
      })
      this.autosaveId = data
      this.autosaves = await Quote.getAutosaves(this.enquiryId)
    } catch (error) {
      console.error(error)
    }
  }

  clearGallery (o, i) {
    if (i >= 0) {
      this.options[o].elements[i].clearGallery()
    } else if (o >= 0) {
      this.options[o].clearGallery()
    } else {
      if (this.images) {
        this.images.splice(0, this.images.length)
      }
    }
  }

  async createPDF () {
    const errorMsg = 'ERROR: Email saved. PDF NOT created'
    try {
      const { data: qPdf } = await Vue.$api.quote.createPdf(this.ids)
      if (qPdf.filename) {
        // quote with pdf created
        this.filename = qPdf.filename
        return qPdf
      } else {
        // PDF NOT created
        throw new Error(errorMsg)
      }
    } catch (error) {
      console.error(error)
      throw new Error(errorMsg)
    }
  }

  deleteOption (index) {
    this.options.splice(index, 1)
  }

  duplicateOption (quoteOption = null) {
    if (!quoteOption) {
      const prevIndex = this.options.length - 1
      quoteOption = new QuoteOption(this.options[prevIndex])
    }
    this.addOption(quoteOption)
  }

  async getPDF () {
    return await Vue.$api.quote.getPdfRaw(this.ids[0])
  }

  isCompleted () {
    return this.missingTexts.length === 0
  }

  async loadDetails (reload = false) {
    if (!this.fullyLoaded || reload) {
      const option = new QuoteOption(this.id)
      try {
        await option.load()
        this.addOption(option, option?.total?.amount)
        this.fullyLoaded = true
      } catch (error) {
        console.error(`ERROR LOADING QUOTE ${this.id}`)
        console.error(error)
      }
    }
  }

  // propertiesInEnquiry is array of EnquiryAccommodation
  mismatchingPropertyLodge (propertiesInEnquiry) {
    let mismatch = false
    if (propertiesInEnquiry?.length > 0) {
      const enqPropertyIds = propertiesInEnquiry.map(e => e._propertyId)
      const enqLodgeIds = {}
      for (const propertyInEnquiry of propertiesInEnquiry) {
        if (propertyInEnquiry._lodgeId) {
          enqLodgeIds[propertyInEnquiry._propertyId] = enqLodgeIds[propertyInEnquiry._propertyId] || []
          enqLodgeIds[propertyInEnquiry._propertyId].push(propertyInEnquiry._lodgeId)
        }
      }
      mismatch = true

      if (this.options?.length > 0) {
        for (const option of this.options) {
          if (option.elements?.length > 0) {
            for (const element of option.elements) {
              if (element.property?.id && enqPropertyIds.includes(element.property?.id)) {
                if (enqLodgeIds[element.property.id]) {
                  if (element.lodge && enqLodgeIds[element.property.id].includes(element.lodge.id)) {
                    mismatch = false
                    break
                  }
                } else {
                  mismatch = false
                  break
                }
              }
              if (!mismatch) {
                break
              }
            }
          }
          if (!mismatch) {
            break
          }
        }
      }
    }
    return mismatch
  }

  removeContact (index) {
    this.contacts.splice(index, 1)
  }

  removeContactMore (index) {
    this.contactsMore.splice(index, 1)
  }

  removeAttachment (index) {
    this._attachments.splice(index, 1)
  }

  removeAttachments () {
    this._attachments.splice(0, this._attachments.length)
  }

  removeEnqAttachment (index) {
    this._enqAttachments.splice(index, 1)
  }

  removeInfo (index) {
    this.infos.splice(index, 1)
  }

  async save (customAttachments = null, differentPropertyReason = null) {
    this.filename = null
    this.ids = []
    const errorMsg = 'ERROR: Email NOT saved'
    this.formData = new FormData()
    try {
      const { data } = await Vue.$api.quote.save(Quote.objectToSave(this, differentPropertyReason))
      if (data.success && data.ids?.length > 0) {
        this.ids = data.ids
        for (const [k, id] of this.ids.entries()) {
          this.formData.append('ids[' + k + ']', id)
        }
        if (customAttachments) {
          for (const [k, f] of Object.entries(customAttachments)) {
            this.formData.append('files[' + k + ']', f)
          }
        }
        return data
      }
      throw new Error(errorMsg)
    } catch (error) {
      console.error(error)
      throw new Error(errorMsg)
    }
  }

  async send () {
    const errorMsg = 'ERROR: Email saved, but NOT sent'
    try {
      await Vue.$api.quote.send(this.formData)
      return true
    } catch (error) {
      console.error(error)
      throw new Error(errorMsg)
    }
  }

  static bankDetails () {
    return bankDetails
  }

  static clearStore () {
    QuoteOption.clearStore()
  }

  static createFromOption (quoteOption, isResponse = false) {
    const quote = new Quote()
    quote.autosaveId = quoteOption.autosaveId
    quote.addOption(quoteOption)
    quote.isConfirmation = quoteOption.isConfirmation
    quote.enquiryId = quoteOption.enquiryId
    if (isResponse) {
      quote.isConfirmation = false
      quote.isQuote = false
      quote.pdfAttached = false
      quote.images = quote.options[0].images
      quote.removeAttachments()
      quote.deleteOption(0)
    }
    return quote
  }

  static defaultFields () {
    return defaultFields
  }

  static defaultInfo () {
    return DefaultInfo.all()
  }

  static async getAutosaves (enquiryId) {
    try {
      const { data } = await Vue.$api.quote.getAutosaved(enquiryId)
      const autosaves = []
      for (const autosave of data) {
        autosaves.push(new Autosave(autosave))
      }
      return autosaves.length > 0 ? autosaves : null
    } catch (error) {
      console.error(error)
    }
  }

  static getLatestEmail (quotes) {
    return quotes.sort((a, b) => DateTime.isBefore(a.date, b.date) ? 1 : -1)[0] // first is newest
  }

  static getLatestQuote (quotes) {
    return quotes.filter(q => q._total > 0).sort((a, b) => DateTime.isBefore(a.date, b.date) ? 1 : -1)[0] // first is newest
  }

  static getLastTotalAmount (quotes) {
    const quote = Quote.getLatestQuote(quotes)
    if (quote) {
      return quote.totalAmount
    }
    return null
  }

  static getPdf (id) {
    return Vue.$api.quote.getPdf(id)
  }

  static objectToSave (quote, differentPropertyReason) {
    const obj = {
      enquiryId: quote.enquiryId,
      subject: quote.subject,
      contacts: quote.contacts,
      contactsMore: quote.contactsMore,
      reference: quote.reference,
      txt1: quote.txt1,
      txt2: quote.txt2,
      afterSignature: quote.afterSignature,
      serviceFee: quote.serviceFee,
      serviceFeeShow: quote.serviceFeeShow,
      total: quote.totalAmount?.amount || 0,
      currency: quote.currency,
      itineraryAttached: quote.itineraryAttached || false,
      pdfAttached: quote.pdfAttached,
      isConfirmation: quote.isConfirmation,
      confirmationNum: quote.confirmationNum,
      images: quote.images,
      differentPropertyReason: differentPropertyReason,
      infos: quote.infos,
      options: (quote.options || []).map(option => (QuoteOption.objectToSave(option))),
      payments: quote.options?.length > 0 && quote.options[0].payments?.length > 0 ? quote.options[0].payments.map(p => QuotePayment.objectToSave(p)) : null,
      attachments: quote.attachments,
      enqAttachments: quote.enqAttachments
    }
    return obj
  }

  static restoreAutosave (autosaveId, autosaves) {
    const quote = new Quote()
    const autosave = autosaves.find(a => a.id === autosaveId)
    const autosavedQuote = JSON.parse(autosave.json)
    quote.autosaveId = autosaveId
    quote.fullyLoaded = autosavedQuote.fullyLoaded
    quote.filename = autosavedQuote.filename
    quote.contacts = autosavedQuote.contacts
    quote.contactsMore = autosavedQuote.contactsMore
    quote.images = autosavedQuote.images
    quote.options = []
    if (autosavedQuote.options?.length > 0) {
      for (const option of autosavedQuote.options) {
        quote.options.push(QuoteOption.fromAutosave(option, autosave.date, autosaveId, autosavedQuote))
      }
    }
    quote._attachments = []
    for (const attachment of autosavedQuote._attachments) {
      quote._attachments.push(QuoteAttachment.fromAutosave(attachment))
    }
    // _enqAttachments
    quote._serviceFeeAdd = autosavedQuote._serviceFeeAdd
    quote._txt1 = autosavedQuote._txt1
    quote._txt2 = autosavedQuote._txt2
    quote._txtAfterSignature = autosavedQuote._txtAfterSignature
    quote.confirmationNum = autosavedQuote.confirmationNum
    quote.infos = []
    for (const info of autosavedQuote.infos) {
      quote.infos.push(QuoteInfo.fromAutosave(info))
    }
    quote._isConfirmation = autosavedQuote._isConfirmation
    quote.isQuote = autosavedQuote.isQuote
    quote.itineraryAttached = autosavedQuote.itineraryAttached
    quote.pdfAttached = autosavedQuote.pdfAttached
    quote.reference = autosavedQuote.reference
    quote.serviceFeeShow = autosavedQuote.serviceFeeShow
    quote.subject = autosavedQuote.subject
    quote._nationality = autosavedQuote._nationality
    quote.enquiryId = autosavedQuote.enquiryId
    return quote
  }

  static serviceFeePerc () {
    return Quote.bankDetails() ? Quote.bankDetails().SERVICE_FEE_PERC : 0
  }

  static serviceFeePercText () {
    return Quote.serviceFeePerc() ? Quote.serviceFeePerc() * 100 + '%' : '0%'
  }

  static textAreaToHtml (html) {
    if (html) {
      return html
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<\/p><p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br>$/g, '')
    }
  }

  static visasSA () {
    return visasSA
  }

  static visasZim () {
    return visasZim
  }
}
