import Vue from 'vue'

const state = {
  notificationsRepeater: null,
  notifications: [],
  newNotificationsEnqIds: []
}

const mutations = {
  clearNotificationState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  dismissNotification (state, id) {
    const index = state.notifications.findIndex(n => n.id === id)
    if (index >= 0) {
      const index1 = state.newNotificationsEnqIds.findIndex(n => n === state.notifications[index].enqId)
      state.notifications.splice(index, 1)
      if (index1 >= 0) {
        state.newNotificationsEnqIds.splice(index, 1)
      }
    }
  },
  setNotificationsRepeater (state, r) {
    state.notificationsRepeater = r
  },
  setNotificationHidden (state, notification) {
    Vue.set(notification, 'dismissed', true)
  },
  setNotificationHiding (state, notification) {
    Vue.set(notification, 'hiding', true)
  },
  setNotificationNotHiding (state, notification) {
    Vue.set(notification, 'hiding', false)
  },
  //   for (let [k, v] of Object.entries(state.notifications)) {
  //     if (v.id === data.id) {
  //       Vue.set(state.notifications[k], 'dismissed', data.h)
  //       break
  //     }
  //   }
  // },
  // setNotificationHiding (state, data) {
  //   for (let [k, v] of Object.entries(state.notifications)) {
  //     if (v.id === data.id) {
  //       Vue.set(state.notifications[k], 'hiding', data.h)
  //       break
  //     }
  //   }
  // },
  // NEW
  setNotifications (state, processed) {
    state.notifications = state.notifications || []
    state.newNotificationsEnqIds = []
    if (processed) {
      let ids = (processed?.new || []).map(n => n.id)
      let i = state.notifications.length
      while (i--) {
        const notification = state.notifications[i]
        if (!ids.includes(notification.id)) {
          state.notification.splice(i, 1)
        }
      }
      ids = state.notifications.map(n => n.id)
      for (const notification of (processed.new?.values() || [])) {
        if (ids.includes(notification.id)) {
          const existingNotification = state.notifications.find(n => n.id === notification.id)
          for (const [k, v] of Object.entries(notification)) {
            existingNotification[k] = v
          }
        } else {
          state.notifications.push(notification)
        }
      }
      state.newNotificationsEnqIds = processed.newNotificationsEnqIds
    }
  },
  viewNotifications (state) {
    for (const notification of Object.values(state.notifications)) {
      notification.viewed = true
    }
    state.newNotificationsEnqIds = []
  }
}

const actions = {
  // setNotificationHidden ({ commit }, data) {
  //   commit('setNotificationHidden', data)
  // },
  // setNotificationHiding ({ commit }, data) {
  //   commit('setNotificationHiding', data)
  // }
}

const getters = {
  notificationsRepeater: state => state.notificationsRepeater,
  notifications: state => state.notifications,
  newNotificationsEnqIds: state => state.newNotificationsEnqIds
}

export default {
  state,
  mutations,
  actions,
  getters
}
