export default axios => ({
  add: data => axios.post('guest', data),
  addToBlacklist: data => axios.post('guest/blacklist', data),
  byEnquiryId: enqId => axios.get(`guests/enquiry/${enqId}`),
  delete: (id, enqId) => axios.delete(`guest/${id}/${enqId}`),
  edit: data => axios.put('guest', data),
  find: email => axios.get(`guest/${email}`),
  getAll: () => axios.get('guests/all'),
  getBlacklisted: () => axios.get('guests/blacklist'),
  getChildren: id => axios.get(`/guest/${id}/children`),
  getCompleted: () => axios.get('guests/completed'),
  getNoLink: () => axios.get('guests/nolink'),
  getNoQuestionnaire: () => axios.get('guests/noquestionnaire'),
  getNoUser: () => axios.get('guests/nouser'),
  getPast: () => axios.get('guests/past'),
  getQuestionnaire: id => axios.get(`/guest/${id}/questionnaire`),
  getUpcoming: () => axios.get('guests/upcoming'),
  getVerificationUrl: id => axios.get(`guest/${id}/vurl`),
  removeFromBlacklist: id => axios.delete(`guest/blacklist/${id}`),
  updateAttachments: (id, data) => axios.put(`guest/${id}/attachments`, data)
  // resendOGPQuestionnaireLink: guestId => axios.post(`guest/${guestId}/questionnaireshortlink`) // removed 21/09/2021s
})
