import ChargeType from './ChargeType'
import LodgeFee from './LodgeFee'
import LodgePackage from './LodgePackage'
import Property from './Property'
import QuoteElementRoomTreeHouse from './QuoteElementRoomTreeHouse'
import RoomType from './RoomType'

export default class Lodge {
  static primitiveFields () {
    return [
      'altName',
      'id',
      'lat',
      'long',
      'name',
      'order',
      'qrFilename',
      'qrLodgeInfoId'
    ]
  }

  constructor (obj, currency) {
    this.currency = currency
    if (obj) {
      for (const field of Lodge.primitiveFields()) {
        this[field] = obj[field]
      }
      const chargeTypeField =
      obj.chargeTypes && obj.chargeTypes.length > 0
        ? 'chargeTypes'
        : 'chargetypes'
      if (obj[chargeTypeField] && obj[chargeTypeField].length > 0) {
        this.chargeTypes = []
        for (const chargeType of obj[chargeTypeField]) {
          this.chargeTypes.push(new ChargeType(chargeType))
        }
      }

      if (obj.contacts && obj.contacts.length > 0) {
        this.contacts = []
        for (const contact of obj.contacts) {
          this.contacts.push(contact)
        }
      }
      if (obj.fees && obj.fees.length > 0) {
        this.fees = []
        for (const fee of obj.fees) {
          this.fees.push(new LodgeFee(fee, this.currency))
        }
      }
      if (obj.packages && obj.packages.length > 0) {
        this.packages = []
        for (const pkg of obj.packages) {
          this.packages.push(new LodgePackage(pkg))
        }
      }
      if (obj.roomTypes && obj.roomTypes.length > 0) {
        this.roomTypes = []
        for (const roomType of obj.roomTypes) {
          this.roomTypes.push(new RoomType(roomType))
        }
      }
      if (obj.treehouses && obj.treehouses.length > 0) {
        this.treehouses = []
        for (const treehouse of obj.treehouses) {
          this.treehouses.push(new QuoteElementRoomTreeHouse(treehouse))
        }
      }
    }
  }

  get firstRoomType () {
    if (this.hasRoomTypes() && this.roomTypes.length === 1) {
      return this.roomTypes[0]
    }
    return this.hasRoomTypes()
      ? this.roomTypes[0]
      : {
          name: null
        }
  }

  hasFees () {
    return this.fees && this.fees.length > 0
  }

  hasOnlyOneChargeType () {
    if (this.chargeTypes && this.chargeTypes.length === 1) {
      return this.chargeTypes[0]
    }
    return false
  }

  hasOnlyOneRoomType () {
    if (this.hasRoomTypes() && this.roomTypes.length === 1) {
      return this.roomTypes[0]
    }
    return false
  }

  hasPackageById (packageId) {
    if (this.hasPackages()) {
      return this.packages.find(p => p.id === packageId)
    }
  }

  hasPackages () {
    return this.packages && this.packages.length > 0
  }

  hasRoomTypes () {
    return this.roomTypes?.length > 0
  }

  hasTreeHouses () {
    return this.treehouses?.length > 0
  }

  static byId (id, lodges) {
    let lodge
    if (lodges) {
      lodge = lodges.find(l => l.id === id)
    } else {
      const properties = Property.more(false)
      for (const property of properties) {
        lodge = property.lodges.find(l => l.id === id)
        if (lodge) {
          break
        }
      }
    }
    return lodge
  }

  static inPropertiesById (id, properties) {
    const property = properties.find(p => p.hasLodgeById(id))
    return property ? property.hasLodgeById() : null
  }
}
