import Vue from 'vue'
import DateTime from './DateTime'
import TravelExecutive from './TravelExecutive'
import Property from './Property'
import Lodge from './Lodge'

export default class GuestEnquiryHistorical {
  constructor (obj = null) {
    if (obj) {
      this.enquiryId = obj.enquiryId
      this.quoted = obj.quoted
      this.contacted = obj.contacted
      this.confirmed = obj.confirmed
      this.itinerary = obj.itinerary
      this.saved = obj.saved
      this.categoryId = obj.categoryId
      this.image = obj.image
      this.name = obj.name
      this.surname = obj.surname
      this.previousVisitor = obj.previousVisitor
      this.travelInsuranceRequested = obj.travelInsuranceRequested
      this.primaryGuestEmail = obj.primaryGuestEmail
      this.numOtherEnquiries = obj.numOtherEnquiries
      this.confirmedPreviousVisitor = obj.confirmedPreviousVisitor
      this.guestDefaultHandlerId = obj.guestDefaultHandlerId
      this.lodgeId = obj.lodgeId
      this.propertyId = obj.propertyId
      this.site = obj.site
      this.createdAt = new DateTime(obj.createdAt)
      this.createdBy = obj.createdBy?.id ? TravelExecutive.byId(obj.createdBy.id) : obj.createdBy
      this.handlerId = obj.handlerId
      this.assignedAt = new DateTime(obj.assignedAt)
      this.assignedById = obj.assignedBy?.id || null
      if (obj.quote) {
        this.quote = {
          id: obj.quote.id,
          createdAt: new DateTime(obj.quote.createdAt),
          sentAt: new DateTime(obj.quote.sentAt),
          confirmation: obj.quote.confirmation,
          quote: obj.quote.quote,
          createdById: obj.quote.createdBy.id
        }
      }
      this.archived = obj.archived
      this.deleted = obj.deleted
    }
  }

  get enquiryDateShort () {
    return this.createdAt.short
  }

  get latestStatusString () {
    return this.deleted
      ? 'Deleted'
      : this.archived
        ? 'Archived'
        : this.quote
          ? this.quote.confirmation
            ? 'Confirmed'
            : this.quote.quote ? 'Quoted' : 'Responded'
          : this.assignedAt.isValid()
            ? 'Assigned'
            : 'Enquired'
  }

  get latestTravelAgentName () {
    const hId = this.quote?.createdById || this.handlerId
    return hId ? TravelExecutive.byId(hId)?.fullName || '' : ''
  }

  get lodge () {
    try {
      return this.lodgeId ? Lodge.byId(this.lodgeId, this.property?.lodges || []) : null
    } catch (error) {
      console.error(error)
    }
  }

  get placeName () {
    return this.lodge && this.lodge.name
      ? this.lodge.name
      : this.property && this.property.name
        ? this.property.name
        : this.site
  }

  get property () {
    try {
      return Property.byId(this.propertyId, Property.all())
    } catch (error) {
      console.error(error)
    }
  }

  get text () {
    let txt = `${this.enquiryDateShort}<br />${this.placeName}<br />`
    if (this.latestTravelAgentName) {
      txt += `${this.latestTravelAgentName}<br />`
    }
    txt += `${this.latestStatusString}`
    return txt
  }

  static async load (guestId) {
    try {
      const { data } = await Vue.$api.enquiry.getHistoricalByGuestId(guestId)
      return data.map(item => new GuestEnquiryHistorical(item))
      // return data.map(ec => new EnquiryCard(ec))
    } catch (error) {
      return []
    }
  }
}
