import Vue from 'vue'
import Amount from './Amount'
import LodgePackageItem from './LodgePackageItem'

export default class LodgePackage {
  constructor (obj) {
    if (obj) {
      this.defaultCost = obj.defaultCost
      this.id = obj.id
      this.local = obj.local
      this.name = obj.name
      for (const iName of ['excluded', 'included']) {
        this[`${iName}Full`] = []
        this[`${iName}`] = []
        if (obj[`${iName}Full`]?.length > 0) {
          for (const item of obj[`${iName}Full`]) {
            this[`${iName}Full`].push(new LodgePackageItem(item))
            this[`${iName}`].push(item.name)
          }
        }
      }
    } else {
      this.defaultCost = 0
    }
  }

  get defaultCost () {
    return new Amount(this._defaultCost, 'ZAR')
  }

  set defaultCost (value) {
    this._defaultCost = Amount.parseValue(value)
  }

  static byId (id, packages) {
    return packages.find(p => p.id === id)
  }

  static allByLodge (lodge, local) {
    return (lodge?.packages || []).filter(p => p.local === local)
  }

  static updatePackagesExtras (changes) {
    const promises = []
    for (const change of changes) {
      promises.push(Vue.$api.property.updateLodgePackageExtras(change))
    }
    return Promise.all(promises)
  }
}
