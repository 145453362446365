import Vue from 'vue'
import Property from '../../classes/Property'

const state = {
  lodgeFees: null,
  lodgeFeeTypes: null,
  properties: null,
  propertyTypes: null
}

const mutations = {
  clearPropertyState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setLodgeFees (state, lodgeFees) {
    state.lodgeFees = lodgeFees
  },
  setLodgeFeeTypes (state, lodgeFeeTypes) {
    state.lodgeFeeTypes = lodgeFeeTypes
  },
  setProperties (state, properties) {
    state.properties = properties
  },
  setPropertyTypes (state, propertyTypes) {
    state.propertyTypes = propertyTypes
  }
}

const actions = {
  async deleteThirdParty ({ dispatch }, id) {
    await Vue.$api.property.deleteThirdParty(id)
    await Property.loadAll(true)
    return true
  },
  async editThirdParty ({ dispatch }, property) {
    const id = property.id
    delete property.id
    await Vue.$api.property.editThirdParty(id, property)
    await Property.loadAll(true)
    return true
  },
  async getLodgeFees ({ commit, getters }, reload = false) {
    if (!getters.lodgeFees || reload) {
      const { data } = await Vue.$api.property.getLodgeFees()
      commit('setLodgeFees', data)
    }
    return getters.lodgeFees
  },
  async getLodgeFeeTypes ({ commit, getters }) {
    if (!getters.lodgeFeeTypes) {
      const { data } = await Vue.$api.property.getLodgeFeeTypes()
      commit('setLodgeFeeTypes', data)
    }
    return getters.lodgeFeeTypes
  },
  async newThirdParty ({ dispatch }, property) {
    await Vue.$api.property.newThirdParty(property)
    await Property.loadAll(true)
    return true
  }
}

const getters = {
  properties: () => Property.all(),
  lodgeFees: state => state.lodgeFees,
  lodgeFeeTypes: state => state.lodgeFeeTypes,
  propertyTypes: state => state.propertyTypes
}

export default {
  state,
  mutations,
  actions,
  getters
}
