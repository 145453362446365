import store from '../store'
import Vue from 'vue'

export default class Category {
  constructor (id, name, order, subcategories) {
    this.id = id
    this.name = name
    this.order = order
    if (subcategories) {
      this.subcategories = []
      for (const subcategory of subcategories) {
        const { id, name, order, subcategories } = subcategory
        this.subcategories.push(new Category(id, name, order, subcategories))
      }
    }
  }

  static async loadAll (reload = false) {
    try {
      if (reload || store.getters.categories.length === 0) {
        const { data } = await Vue.$api.category.all()
        const ics = []
        for (const iterator of data.items) {
          const { id, name, order, subcategories } = iterator
          ics.push(new Category(id, name, order, subcategories))
        }
        store.commit('setCategories', ics)
      }
      return true
    } catch (error) {
      return false
    }
  }

  static byId (id, categories = null) {
    return (categories || store.getters.categories).find(
      category => category.id === id
    )
  }

  static parseMultipleFromStore (objs) {
    if (objs && objs[0].constructor !== Category) {
      const cats = []
      for (const obj of objs) {
        const { id, name, order, subcategories } = obj
        cats.push(new Category(id, name, order, subcategories))
      }
      return cats
    }
    return objs || []
  }
}
