import Lodge from './Lodge'
import Property from './Property'
import RoomType from './RoomType'

export default class EnquiryAccommodation {
  constructor (accommodation = null) {
    this._propertyId = accommodation?._propertyId || accommodation?.propertyId || null
    this._lodgeId = accommodation?._lodgeId || accommodation?.lodgeId || null
    this._roomTypeId = accommodation?._roomTypeId || accommodation?.roomTypeId || null
  }

  get lodge () {
    return Lodge.byId(this._lodgeId, this.property.lodges)
  }

  get property () {
    return Property.byId(this._propertyId)
  }

  get roomType () {
    if (this.lodge?.roomTypes) {
      return RoomType.byId(this._roomTypeId, this.lodge.roomTypes)
    }
    return null
  }

  toString () {
    let accommodation = this.property ? `${this.property.name}` : ''
    if (this.lodge?.name) {
      accommodation += ` - ${this.lodge.name}`
    }
    if (this.roomType?.name) {
      accommodation += ` - ${this.roomType.name}`
    }
    return accommodation
  }
}
