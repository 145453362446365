export default axios => ({
  deleteThirdParty: id => axios.put(`thirdparty/${id}/delete`),
  editThirdParty: (id, property) =>
    axios.put(`thirdparty/${id}/edit`, property),
  getLodgeFees: () => axios.get('lodges/fees'),
  getLodgeFeeTypes: () => axios.get('lodges/fees/types'),
  all: () => axios.get('properties/dropdown'),
  getTypes: () => axios.get('property/types'),
  newThirdParty: property => axios.post('thirdparty', property),
  withNoImages: ids => axios.get('properties/gallery/empty', { params: ids }),
  updateLodgeFees: fees => axios.post('lodge/fees', fees),
  updateLodgePackageExtras: data => axios.put('lodge/extras', data),
  updateLodgeRoomTypes: data => axios.put('lodge/roomtypes', data)
})
