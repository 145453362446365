import Vue from 'vue'
import Country from './Country'
import GuestChild from './GuestChild'
import OGPQuestionnaire from './OGPQuestionnaire'
import Quote from './Quote'
import TravelExecutive from './TravelExecutive'
import GuestEnquiryHistorical from './GuestEnquiryHistorical'

const compare = (a, b) => {
  if ((a.primary && b.primary) || (!a.primary && !b.primary)) {
    const surnameComp = a.surname?.localeCompare(b.surname)
    return (surnameComp !== 0 ? surnameComp : a.name?.localeCompare(b.name))
  } else {
    return (a.primary ? -1 : 1)
  }
}

const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export default class Guest {
  constructor (objOrId) {
    if (objOrId) {
      if (isNaN(objOrId)) {
        this.id = objOrId.id
        this.primary = objOrId.primary
        this._userId = objOrId._userId || objOrId.userId
        this.name = objOrId.name
        this.surname = objOrId.surname
        this.cell = objOrId.cell
        this.email = objOrId.email
        this.country = objOrId.countryCd
        this._handlerId = objOrId._handlerId || objOrId.handlerId
        this.enquiries = []
        if (objOrId.enquiries) {
          for (const [enquiryId, v] of Object.entries(objOrId.enquiries)) {
            this.enquiries.push({
              enquiryId,
              quotes: (v.quotes || []).map(q => new Quote(q))
            })
          }
        }
      } else {
        this.id = objOrId
      }
    } else {
      this.name = ''
      this.surname = ''
      this.cell = ''
      this.email = ''
      this.country = ''
    }
  }

  get country () {
    return this._countryCd ? Country.byCd(this._countryCd) : null
  }

  set country (countryCd) {
    this._countryCd = countryCd
  }

  get countryCd () {
    return this._countryCd
  }

  set countryCd (countryCd) {
    this._countryCd = countryCd
  }

  get handler () {
    return this._handlerId ? TravelExecutive.byId(this._handlerId) : null
  }

  get fullName () {
    return `${this.surname ? this.surname : ''}${(this.surname && this.name ? ' ' : '')}${this.name ? this.name : ''}`
  }

  create (enquiryId, primaryGuestId) {
    return Vue.$api.guest.add({
      enquiryId,
      primaryGuestId,
      details: {
        name: this.name,
        surname: this.surname,
        email: this.email,
        cell: this.cell,
        countryCd: this.countryCd
      }
    })
  }

  edit () {
    return Vue.$api.guest.edit({
      details: {
        id: this.id,
        name: this.name,
        surname: this.surname,
        email: this.email,
        cell: this.cell,
        countryCd: this.countryCd
      }
    })
  }

  async getChildren () {
    try {
      const { data: children } = await Vue.$api.guest.getChildren(this.id)
      this.children = []
      if (children) {
        for (const child of children) {
          this.children.push(new GuestChild(child))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  async getHistorical () {
    return await GuestEnquiryHistorical.load(this.id)
  }

  async getQuestionnaire () {
    try {
      const { data: questionnaire } = await Vue.$api.guest.getQuestionnaire(this.id)
      this.questionnaire = new OGPQuestionnaire(questionnaire)
    } catch (error) {
      console.error(error)
    }
  }

  sendLinkToOGP () {
    return Guest.sendLinkToOGP(this.email)
  }

  toString () {
    return this.fullName
  }

  static async byEmail (email) {
    try {
      const { status, data } = await Vue.$api.guest.find(email)
      if (status === 200 && data) {
        return new Guest(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  static getVerificationUrl (guestId) {
    return Vue.$api.guest.getVerificationUrl(guestId)
  }

  // Guest number for Guest Card
  static number (guestId) {
    const numSize = 6
    return `GUEST${'0'.repeat(numSize - guestId.toString().length)}${guestId}`
  }

  static primary (guests) {
    return guests.find(g => g.primary)
  }

  static sort (guests) {
    return guests.sort(compare)
  }

  static sendLinkToOGP (email) {
    return Vue.$api.ogp.inviteGuest({ email })
  }

  static validEmail (email) {
    return reg.test(email)
  }
}
