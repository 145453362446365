import moment from 'moment'
import Amount from './Amount'
import DateTime from './DateTime'

const primitiveFields = [
  'adultRateShow',
  'adults',
  'childRateShow',
  'children',
  'description',
  'excluding',
  'id',
  'including',
  'quoteEmailId',
  'type'
]
const amountsFields = ['adultRate', 'childRate']
export default class QuoteExtra {
  constructor (obj = null, currency) {
    this.setKey()
    this.currency = currency
    this.adults = 0
    this.children = 0
    this.adultRateShow = true
    this.childRateShow = true
    this.adultRate = new Amount(0, currency)
    this.childRate = new Amount(0, currency)
    this.description = ''
    this.excluding = ''
    this.including = ''
    this.totalShow = true
    if (obj) {
      this.currency = this.currency || obj.currency
      for (const field of primitiveFields) {
        this[field] = obj[field]
      }
      for (const field of amountsFields) {
        this[field] = new Amount(obj[field], this.currency)
      }
      this._total = new Amount(obj.total, this.currency)
      this.totalShow = obj.totalShow !== false

      this.date = obj.date
    }
    const total = this.recalculatedTotal()
    if (this.total.amount !== total.amount) {
      this._total = total
    }
  }

  get date () {
    return new DateTime(this._date)
  }

  set date (d) {
    this._date = DateTime.startOfDay(d).dateOnlyDB
  }

  get total () {
    return this._total > 0 ? new Amount(this._total, this.currency) : this.recalculatedTotal()
  }

  recalculatedTotal () {
    return new Amount(
      this.adultRate.amount * (this.adults || 0) +
        this.childRate.amount * (this.children || 0),
      this.currency
    )
  }

  setKey () {
    this.key = moment().valueOf()
  }

  fromAutosave (data) {
    this.setKey()
    this.currency = data.currency
    this.adults = data.adults
    this.children = data.children
    this.adultRateShow = data.adultRateShow
    this.childRateShow = data.childRateShow
    if (data.adultRate) {
      this.adultRate = new Amount(data.adultRate._amount, data.adultRate.currency)
    }
    if (data.childRate) {
      this.childRate = new Amount(data.childRate._amount, data.childRate.currency)
    }
    this._date = data._date
    this.description = data.description
    this.excluding = data.excluding
    this.including = data.including
    this.totalShow = data.totalShow
    this.recalculatedTotal()
  }

  static objectToSave (extra) {
    return {
      date: extra.date?.dateOnlyDB,
      adults: extra.adults,
      children: extra.children,
      adultRate: extra.adultRate?.amount || 0,
      adultRateShow: extra.adultRateShow,
      childRate: extra.childRate?.amount || 0,
      childRateShow: extra.childRateShow,
      total: extra.total?.amount || 0,
      totalShow: extra.totalShow,
      type: extra.type,
      description: extra.description,
      including: extra.including,
      excluding: extra.excluding
    }
  }
}
