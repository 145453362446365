export default axios => ({
  assign: async ({ enqId, reason, teId }) => {
    const { status } = await axios.post('enquiry/' + enqId + '/assign/' + teId, { reason })
    return status === 200
  },
  changeStatus: async (enquiryId, reason, action) => {
    const res = await axios.post('enquiry/' + enquiryId + '/' + action, { reason })
    return res.data.success
  },
  create: async enqData => {
    const { data } = await axios.post('enquiry', enqData)
    return data.success ? data : false
  },
  createSource: data => axios.post('enquiry/source', data),
  editSource: data => axios.put('enquiry/source', data),
  getAttachments: id => axios.get('enquiry/' + id + '/attachments'),
  getAutocomplete: params => axios.get('enquiries/autocomplete', { params }),
  getByType: (endpoint, data) => axios.get(endpoint, { params: data }),
  getById: async ({ id, emptyEmail }) => {
    const { data } = await axios.get('enquiry/' + id)
    return { ...data, emptyEmail: emptyEmail }
  },
  getCardById: enquiryId => axios.get(`enquiry/${enquiryId}/card`),
  getCards: ids => axios.get('enquiries', { params: ids }),
  getFiltered: data => axios.get('enquiries/filtered', { params: data }),
  getHistoricalByGuestId: guestEmail => axios.get(`enquiries/historical/${guestEmail}`),
  getSource: id => axios.get('enquiry/source/' + id),
  getSources: () => axios.get('enquiry/sources'),
  setContacted: data => axios.post('enquiry/contacted', data),
  setPrimaryGuest: (id, guestId) => axios.put(`enquiry/${id}/primaryguest/${guestId}`)
})
