import moment from 'moment'
import Amount from './Amount'
import ChargeType from './ChargeType'
import DateTime from './DateTime'
import Lodge from './Lodge'
import LodgeFee from './LodgeFee'
import LodgePackage from './LodgePackage'
import Property from './Property'
import QuoteElementRoom from './QuoteElementRoom'
import QuoteElementRoomTreeHouse from './QuoteElementRoomTreeHouse'
import QuoteExtra from './QuoteExtra'
import QuoteTravelArrangement from './QuoteTravelArrangement'
import RoomType from './RoomType'

// TODO get only propertyId from API
// TODO get only lodgeId from API

// "travelArrangements":[
// ],
// "tours":[
// ],
// "services":[
// ]
const primitiveFields = [
  'id',
  'displayAccommodationContacts',
  'numRooms',
  // 'packageName',
  'nights',
  'international',
  'vehicles',
  'currency',
  'cancellationPolicy',
  'order'
]

const amountsFields = [
  // 'packageCost',
  // 'packageTotalCost',
  // 'rate',
  'parkFee',
  // 'totalLodgeFees',
  'total'
]

export default class QuoteElement {
  constructor (obj = null, thirdParty = false, index = 0, originalCurrency = null) { // if not from store, then it's from API
    this.setKey()
    this.index = index
    this.originalCurrency = originalCurrency
    this.thirdParty = thirdParty
    this.rooms = []
    this.treeHouses = []
    this.lodgeFeesTypes = []
    this.lodgeFeesTypesLabels = []
    this.lodgeFeesThirdParty = new LodgeFee()
    this.totalLodgeFeesShow = true
    this.totalShow = true
    this.rateShow = true
    this.parkFeeShow = true

    // this._lodgeFeesAdults = 0
    // this._lodgeFeesChildren = 0
    if (obj) {
      this.originalCurrency = this.originalCurrency || obj.originalCurrency
      this.index = this.index || obj.index

      this.thirdParty = this.thirdParty || obj.thirdParty
      // this._adults = obj.adults
      // this._children = obj.children
      for (const field of primitiveFields) {
        this[field] = obj[field]
      }
      this.totalLodgeFeesShow = !!obj.totalLodgeFeesShow
      this.totalShow = !!obj.totalShow
      this.rateShow = !!obj.rateShow
      this.parkFeeShow = !!obj.parkFeeShow

      this.isMonthSelection = !!obj._monthIn ||
        (!!obj.monthIn && typeof obj.monthIn === 'string')
      if (this.isMonthSelection) {
        this._monthIn = obj._monthIn || obj.monthIn
        this._monthOut = obj._monthOut || obj.monthOut
        this._dateIn = obj._monthIn
        this._dateOut = obj._monthOut
      } else {
        this._dateIn = obj._dateIn || obj.dateIn
        this._dateOut = obj._dateOut || obj.dateOut
      }
      this.packageCost = obj.packageCost || 0
      this.rate = obj.rate || 0
      this.totalLodgeFees = obj.totalLodgeFees || 0

      for (const field of amountsFields) {
        this[field] = new Amount(obj[field], this.currency)
      }

      this.travelArrangements = []
      if (obj.travelArrangements) {
        for (const travelArrangement of obj.travelArrangements) {
          this.travelArrangements.push(
            new QuoteTravelArrangement(
              travelArrangement,
              this.currency
            )
          )
        }
      }
      if (obj.property) {
        this.property = Property.byId(obj.property.id)
        if (obj.lodge?.id) {
          this.lodge = Lodge.byId(obj.lodge.id, this.property.lodges)
          if (
            this.lodge?.hasFees() &&
          obj.lodgeFeesTypes &&
          obj.lodgeFeesTypes.length > 0
          ) {
            const areStrings = typeof obj.lodgeFeesTypes[0] === 'string'

            if (areStrings) {
              const year = this.isMonthSelection ? this.monthIn.year : this.dateIn.year
              this.lodgeFeesTypes = LodgeFee.byNamesAndYear(
                this.lodge.fees,
                obj.lodgeFeesTypes,
                year,
                this.international
              )
            } else {
              for (const lodgeFeesType of obj.lodgeFeesTypes) {
                this.lodgeFeesTypes.push(new LodgeFee(lodgeFeesType))
              }
            }
            this.lodgeFeesTypesLabels = [...new Set(this.lodgeFeesTypes.map(lf => `${lf.name} ${lf.year}`))]
            if (obj.totalLodgeFees) {
              this.totalLodgeFees = obj.totalLodgeFees
            }
          }
          if (obj.package) {
            this.package = LodgePackage.byId(
              obj.package.id,
              this.lodge.packages
            )
            if (obj.packageCost) {
              this.packageCost = obj.packageCost
            }
          }
        } else if (this.thirdParty) {
          if (obj.lodgeFeesThirdParty
          ) {
            this.lodgeFeesThirdParty = new LodgeFee(obj.lodgeFeesThirdParty, this.currency)
          } else {
            this.lodgeFeesThirdParty = new LodgeFee({ amount: this.totalLodgeFees, name: obj.lodgeFeesTypes ? obj.lodgeFeesTypes[0] : '' }, this.currency)
          }
        }
        if (obj.rooms) {
          for (const room of obj.rooms) {
            this.rooms.push(new QuoteElementRoom(room, this.lodge, this.currency))
          }
        }

        // including and excluding need to be set after package
        this.excluding = obj.excluding
        this.including = obj.including
        this.images = [...obj.images]

        // additional needs to be set after property
        this.additional = obj.additional
      }
    } else {
      this._dateIn = DateTime.today().dateOnlyDB
      this._dateOut = DateTime.tomorrow().dateOnlyDB
      this._isMonthSelection = false
      this.images = []
      this.rooms = []
      this.travelArrangements = []
    }
    this.currency = this.currency || this.originalCurrency
    this.numRooms = this.numRooms || this.rooms?.length || 0
  }

  get additional () {
    return this._additional
  }

  set additional (additional) {
    if (!additional) {
      this.resetAdditional()
    } else {
      this._additional = additional
    }
  }

  get adults () {
    if (this.rooms) {
      this._adults = this.rooms.reduce((a, b) => {
        return a + b.adults + b.childrenOver12
      }, 0)
    }
    return this._adults
  }

  get children () {
    if (this.rooms) {
      this._children = this.rooms.reduce((a, b) => {
        return a + b.children - b.childrenOver12
      }, 0)
    }
    return this._children
  }

  get currentYearFees () {
    const fees = (this.lodge?.fees || []).filter(f => f.year === this.year && f.local === !this.international)
    const myFees = []
    for (const f of fees) {
      const label = f.name + ' ' + f.year
      const myFee = myFees.find(f => f.label === label)
      if (myFee) {
        myFee.fees.push(f)
      } else {
        myFees.push({
          label,
          fees: [f]
        })
      }
    }
    return myFees
  }

  get dateIn () {
    return new DateTime(this._dateIn)
  }

  set dateIn (d) {
    const dateIn = DateTime.startOfDay(d)
    let dateOut = this.dateOut
    if (dateOut?.isValid()) {
      dateOut = DateTime.startOfDay(dateOut.d)
      if (DateTime.diffDays(dateIn, dateOut) <= 0) {
        dateOut = new DateTime(DateTime.startOfDay(d).moment.add(this.nights || 1, 'd'))
      }
      if (!this.isMonthSelection) {
        this.nights = DateTime.diffDays(
          dateIn,
          dateOut
        )
      }
    }
    this._dateIn = dateIn.dateOnlyDB
    this._dateOut = dateOut.dateOnlyDB
  }

  get dateOut () {
    return new DateTime(this._dateOut)
  }

  set dateOut (d) {
    let dateOut = DateTime.startOfDay(d)
    if (this.isMonthSelection) {
      dateOut = new DateTime(dateOut.moment.endOf('month'))
    } else {
      this.nights = DateTime.diffDays(
        this.dateIn,
        dateOut
      )
    }
    this._dateOut = dateOut.dateOnlyDB
  }

  get dateInMin () {
    const today = DateTime.today()
    return this.isMonthSelection
      ? today.monthYearOnlyDB
      : today.dateOnlyDB
  }

  get dateOutMin () {
    if (this.dateIn?.isValid()) {
      const d = new DateTime(this.dateIn.moment
        .clone()
        .add(this.isMonthSelection ? 0 : 1, 'd'))
      return this.isMonthSelection
        ? d.monthYearOnlyDB
        : d.dateOnlyDB
    } else {
      return DateTime.tomorrow().dateOnlyDB
    }
  }

  get dateInTreeHouseMax () {
    const d = new DateTime(this.dateOut.moment
      .clone()
      .subtract(this.isMonthSelection ? 0 : 1, 'd'))
    return this.isMonthSelection
      ? d.monthYearOnlyDB
      : d.dateOnlyDB
  }

  get dateInTreeHouseMin () {
    return this.isMonthSelection
      ? this.dateIn.monthYearOnlyDB
      : this.dateIn.dateOnlyDB
  }

  get dateOutTreeHouseMax () {
    return this.isMonthSelection
      ? this.dateOut.monthYearOnlyDB
      : this.dateOut.dateOnlyDB
  }

  get dateOutTreeHouseMin () {
    const d = new DateTime(this.dateIn.moment
      .clone()
      .add(this.isMonthSelection ? 0 : 1, 'd'))
    return this.isMonthSelection
      ? d.monthYearOnlyDB
      : d.dateOnlyDB
  }

  // get datepickerFormat () {
  //   return this.isMonthSelection ? 'MMM yyyy' : 'dd MMM yyyy'
  // }

  get excluding () {
    return (this._excluding || this.package?.excluded || []).join('\n\n')
  }

  set excluding (items) {
    this._excluding = this._excluding || []
    if (items) {
      if (Array.isArray(items)) {
        this._excluding.splice(0, this._excluding.length)
        for (const item of items) {
          this._excluding.push(item)
        }
      } else {
        this._excluding = items.split('\n').filter(item => item)
      }
    } else {
      this._excluding.splice(0, this._excluding.length)
    }
  }

  get including () {
    return (this._including || this.package?.included || []).join('\n\n')
  }

  set including (items) {
    this._including = this._including || []
    if (items) {
      if (Array.isArray(items)) {
        this._including.splice(0, this._including.length)
        for (const item of items) {
          this._including.push(item)
        }
      } else {
        this._including = items.split('\n').filter(item => item)
      }
    } else { this._including.splice(0, this._including.length) }
  }

  get international () {
    return this._international
  }

  set international (international) {
    this._international = international
    this.resetPackage()
  }

  get isMonthSelection () {
    return this._isMonthSelection
  }

  set isMonthSelection (val) {
    this._isMonthSelection = val
    if (this._dateIn?.moment) {
      if (val) {
        this._dateIn = new DateTime(this.dateIn.moment.clone().startOf('month')).dateOnlyDB
        this._dateOut = new DateTime(this.dateOut.moment.clone().endOf('month')).dateOnlyDB
      } else if (this._dateIn) {
        this._dateOut = new DateTime(this.dateIn.moment.clone().add(this.nights || 1, 'day')).dateOnlyDB
      }
    }
  }

  get lodge () {
    return this._lodge
  }

  set lodge (lodge) {
    this._lodge = lodge
    if (this.property.more) {
      for (const r of this.rooms || []) {
        r.lodge = lodge
        r.chargeType = r.chargeType || ChargeType.default()
        if (lodge?.hasOnlyOneRoomType()) {
          r.roomType = lodge.hasOnlyOneRoomType()
        }
      }
      this.resetPackage()
      // if (resetAdditional) {
      // this.resetAdditional()
      // }
      this.setLodgeFees()
    }
    this.lodgeFeesTypes = this.lodgeFeesTypes || []
    this.lodgeFeesTypes.splice(0, this.lodgeFeesTypes.length)
  }

  get lodgeFeesAdults () {
    return this._lodgeFeesAdults || this.adults || 0
  }

  set lodgeFeesAdults (adults) {
    this._lodgeFeesAdults = adults || 0
  }

  get lodgeFeesChildren () {
    let children = 0
    if (this.rooms) {
      children = this.rooms.reduce((a, b) => {
        return a + b.children - b.childrenOver12 - b.childrenUnder2
      }, 0)
    }
    return this._lodgeFeesChildren || children || 0
  }

  set lodgeFeesChildren (children) {
    this._lodgeFeesChildren = children || 0
  }

  get property () {
    return this._propertyId ? Property.byId(this._propertyId) : null
  }

  set property (property) {
    this._propertyId = property.id
    this.thirdParty = !property.more
    if (property.more) {
      if (property.hasOnlyOneLodge()) {
        this.lodge = property.hasOnlyOneLodge()
      } else {
        const lodgeId = this.lodge?.id
        if (lodgeId) {
          if (!this.property.hasLodgeById(lodgeId)) {
            this.lodge = null
          }
        }
      }
      if (property && this.index === 0 && !this.originalCurrency) {
        this.currency = property.standardCurrency
      }
    } else {
      // thirdParty

      this.lodge = new Lodge(null, this.currency)
      // if (this.property.description) {
      //   this.additional = this.property.description
      // }
      this.lodgeFeesTypes.push(new LodgeFee(null, this.currency))
    }
    this.resetAdditional()
  }

  calculatedTotalLodgeFees = () => {
    let totalLodgeFees = 0
    if (
      this.lodgeFeesTypes &&
      Array.isArray(this.lodgeFeesTypes) &&
      !this.thirdParty
    ) {
      for (const lf of this.lodgeFeesTypes) {
        const category = lf.category
        let amount = 0
        switch (category) {
          case 'adult':
            amount = lf.amount.amount * this.lodgeFeesAdults
            if (lf.daily) {
              amount = amount * this.nights
            }
            break
          case 'child':
            amount = lf.amount.amount * this.lodgeFeesChildren
            if (lf.daily) {
              amount = amount * this.nights
            }

            break
          case 'vehicle':
            amount = lf.amount.amount * (this.vehicles || 0)
            if (lf.amount.daily) {
              amount = amount * this.nights
            }
            break
          default:
            break
        }
        totalLodgeFees += amount
      }
    } else {
      totalLodgeFees = this.lodgeFeesThirdParty.amount.amount
    }
    return Math.round((totalLodgeFees + Number.EPSILON) * 100) / 100
  }

  get totalLodgeFees () {
    if (this.currency !== 'USD') {
      const totalLodgeFees = this.calculatedTotalLodgeFees()
      if (!this._totalLodgeFees || this._totalLodgeFees === totalLodgeFees) {
        this._totalLodgeFees = 0
        return new Amount(totalLodgeFees, this.currency)
      }
    }
    return new Amount(this._totalLodgeFees, this.currency)
  }

  set totalLodgeFees (value) {
    this._totalLodgeFees = Amount.parseValue(value)
  }

  get lodgeHasContactDetails () {
    return this.lodge?.contacts
  }

  // get minimumView () {
  //   return this.isMonthSelection ? 'month' : 'day'
  // }

  get missingTexts () {
    const arrayMissingTexts = []
    if (!this.thirdParty && !this.lodge.id) {
      arrayMissingTexts.push('Lodge not selected')
    }
    const totalAmount = this.totalAmount?.amount
    if (totalAmount === 0) {
      arrayMissingTexts.push('Uncompleted')
    } else if (totalAmount < 0) {
      arrayMissingTexts.push('Total amount is negative')
    }
    if (!this.dateIn.isValid()) {
      arrayMissingTexts.push('Date in not valid')
    }
    if (!this.dateOut.isValid()) {
      arrayMissingTexts.push('Date out not valid')
    }
    if ((this.rooms || []).filter(r => r.roomType.id || r.roomType.name).length < this.numRooms) {
      arrayMissingTexts.push('Room info missing')
    }

    if (this.rooms && this.nights) {
      for (const [indexRoom, room] of this.rooms.entries()) {
        const totalAmount = room.totalAmount(this.nights)?.amount || 0
        if (totalAmount <= 0) {
          if (totalAmount === 0) {
            const discountAmount = room.discount?.amount?.amount || 0
            if (discountAmount < totalAmount) {
              arrayMissingTexts.push(`Room ${indexRoom + 1} - Uncompleted`)
            }
          } else {
            arrayMissingTexts.push(`Room ${indexRoom + 1} - Total amount is negative`)
          }
        }
      }
    }
    return arrayMissingTexts
  }

  get monthIn () {
    return new DateTime(this._monthIn)
  }

  get monthOut () {
    return new DateTime(this._monthOut)
  }

  get package () {
    return this._package
  }

  set package (newPackage) {
    this._package = new LodgePackage(newPackage)
    this.packageCost = newPackage?.defaultCost || 0
    for (const iterator of ['exclud', 'includ']) {
      if (Array.isArray(this[`${iterator}ing`])) {
        this[`${iterator}ing`] = this[`${iterator}ing`].join('\n\n')
      } else if (this._package && this._package[`${iterator}ed`]) {
        // if (refreshInclExcl) {
        this[`${iterator}ing`] = this.package[`${iterator}ed`].join('\n\n')
        // } else {
        //   this[`${iterator}ing`] = this[`${iterator}ing`] || this.package[`${iterator}ed`].join('\n\n')
        // }
      }
    }
  }

  get packageCost () {
    return new Amount(this._packageCost, this.currency)
  }

  set packageCost (value) {
    this._packageCost = Amount.parseValue(value)
  }

  get packageTotalAmount () {
    let totalCost = 0
    try {
      totalCost = this.packageCost.amount * this.nights * this.adults
    } catch (error) {
      console.error(error)
    }
    return new Amount(totalCost, this.currency)
  }

  get packages () {
    return LodgePackage.allByLodge(this.lodge, !this.international)
  }

  get periodMonths () {
    const monthIn = this.dateIn?.isValid() ? this.dateIn.monthYearOnly : this.monthIn.monthYearOnly
    const monthOut = this.dateOut?.isValid() ? this.dateOut.monthYearOnly : this.monthOut.monthYearOnly
    const differentMonths = monthIn !== monthOut
    return this.isMonthSelection
      ? differentMonths
        ? `${monthIn} - ${monthOut}`
        : monthIn
      : ''
  }

  get rate () {
    return new Amount(this._rate, this.currency)
  }

  set rate (value) {
    this._rate = Amount.parseValue(value)
  }

  get thirdParty () {
    return this._thirdParty
  }

  set thirdParty (thirdParty) {
    this._thirdParty = thirdParty
  }

  get totalAmount () {
    let totalCost = 0
    for (const room of this.rooms) {
      totalCost += room.totalAmount(this.nights)?.amount || 0
    }
    totalCost += this.totalLodgeFees?.amount || 0
    totalCost += this.packageTotalAmount?.amount || 0
    return new Amount(totalCost, this.currency)
  }

  get totalCostTravelArrangements () {
    if (this.travelArrangements) {
      const totals = this.travelArrangements.map(ta => ta.total)
      if (totals.length > 0) {
        return Amount.sum(totals)
      }
    }
    return new Amount(0, this.currency)
  }

  get treeHousesForRoom () {
    const newTreeHouses = (this.lodge?.treehouses || []).map(
      treeHouse => new QuoteElementRoomTreeHouse(treeHouse, this.currency)
    )
    const newTreeHousesIds = newTreeHouses.map(nth => nth.id)
    const same = this.treeHouses.filter(
      th => newTreeHousesIds.includes(th.id)
    ).length === newTreeHousesIds.length &&
      this.treeHouses.length === newTreeHousesIds.length
    if (!same) {
      this.treeHouses.splice(0, this.treeHouses.length)
      for (const newTreeHouse of newTreeHouses) {
        this.treeHouses.push(newTreeHouse)
      }
    }
    return this.treeHouses
  }

  get year () {
    return this.dateIn.isValid() ? this.dateIn.year : this.monthIn.isValid() ? this.monthIn.year : moment().year()
  }

  addRoom (enquiryAdults) {
    const roomType = !this.thirdParty && this.lodge.hasRoomTypes()
      ? this.lodge.firstRoomType
      : {
          name: null
        }
    const room = {
      adults: enquiryAdults && enquiryAdults - this.adults >= 0 ? enquiryAdults - this.adults : this.adults,
      children: 0,
      infants: 0,
      roomType,
      totalShow: true
    }
    this.rooms.push(new QuoteElementRoom(room, this.lodge, this.currency))
  }

  addRooms (number, enquiryAdults) {
    for (let i = 0; i < number; i++) {
      this.addRoom(enquiryAdults)
      enquiryAdults = 0
    }
  }

  addTravelArrangement () {
    this.travelArrangements.push(new QuoteExtra({
      adults: this.adults,
      children: this.children,
      currency: this.currency,
      date: this.dateIn
    }))
  }

  changeCurrency (newCurrency) {
    this.currency = newCurrency
    this.lodgeFeesThirdParty.currency = newCurrency
    this.parkFee.changeCurrency(newCurrency)
    this.total.changeCurrency(newCurrency)
    for (const room of this.rooms) {
      room.changeCurrency(newCurrency)
    }
  }

  clearGallery () {
    if (this.images) {
      this.images.splice(0, this.images.length)
    }
  }

  deleteTravelArrangement (index) {
    this.travelArrangements.splice(index, 1)
    this.setKey()
  }

  getChargeTypes (room) {
    const chargeTypes = ChargeType.byRoomTypeLodgeProperty(
      room,
      this.lodge,
      this.property
    )
    if (!room.chargeType && chargeTypes?.length > 0) {
      room.chargeType = chargeTypes[0]
    }
    // room.chargeType = chargeTypes?.length > 0 ? chargeTypes[0] : null
    return chargeTypes
  }

  hasImages () {
    return this.images && this.images.length > 0
  }

  isCompleted () {
    return this.missingTexts.length === 0
  }

  removeRooms (number) {
    const numRooms = this.rooms.length
    if (numRooms > 0) {
      this.rooms.splice(numRooms - number, number)
    }
  }

  resetAdditional () {
    this._additional = `${this.property?.description ? this.property.description : ''}<p>Any extras will be for the guest's account payable on departure.</p>`
  }

  resetPackage () {
    const packages = (this.lodge?.packages || []).filter(pkg => pkg.local === !this.international)
    this.package = packages?.length > 0 ? packages[0] : null
  }

  // selectLodge (lodge, resetAdditional = true) {
  //   this.lodge = lodge
  //   if (this.property.more) {
  //     for (const r of this.rooms) {
  //       r.lodge = lodge
  //       r.chargeType = r.chargeType || ChargeType.default()
  //       if (lodge?.hasOnlyOneRoomType()) {
  //         r.roomType = lodge.hasOnlyOneRoomType()
  //       }
  //     }
  //     if (resetAdditional) {
  //       this.resetAdditional()
  //     }
  //     this.setLodgeFees()
  //   }
  // }

  // selectProperty (property, isFirstElement, originalCurrency = null, resetAdditional = true) {
  //   this.property = property
  //   if (property.more) {
  //     if (property.hasOnlyOneLodge()) {
  //       this.lodge = property.hasOnlyOneLodge()
  //       this.selectLodge(this.lodge, resetAdditional)
  //     } else {
  //       const lodgeId = this.lodge?.id
  //       if (lodgeId) {
  //         if (!this.property.hasLodgeById(lodgeId)) {
  //           this.lodge = null
  //         }
  //       }
  //     }
  //     if (property && isFirstElement && !originalCurrency) {
  //       this.currency = property.standardCurrency
  //     }
  //   } else {
  //     // thirdParty
  //     this.lodge = { name: null }
  //     if (this.property.description) {
  //       this.additional = this.property.description
  //     }
  //     this.lodgeFeesTypes.push(new LodgeFee(null, this.currency))
  //   }
  // }

  setKey () {
    this.key = moment().valueOf()
  }

  setLodgeFees () {
    this._totalLodgeFees = 0
    this.lodgeFeesTypes = this.lodgeFeesTypes || []
    this.lodgeFeesTypes.splice(0, this.lodgeFeesTypes.length)
    for (const [index, label] of this.lodgeFeesTypesLabels.entries()) {
      const fees = this.currentYearFees?.find(f => f.label === label)?.fees
      if (fees) {
        for (const fee of fees) {
          this.lodgeFeesTypes.push(fee)
        }
      } else {
        this.lodgeFeesTypesLabels.splice(index, 1)
      }
    }
  }

  // TODO I am clearing the fees at moment
  // setLodgeFees () {
  //   if (this.lodgeFeesTypes?.length > 0) {
  //     const tmp = []
  //     const names = []
  //     for (const lodgeFeesType of this.lodgeFeesTypes) {
  //       for (const currentYearFee of Object.values(this.currentYearFees)) {
  //         const lft = Array.isArray(lodgeFeesType)
  //           ? lodgeFeesType[0]
  //           : lodgeFeesType
  //         if (
  //           lft.name === currentYearFee[0].name &&
  //             !names.includes(lft.name)
  //         ) {
  //           names.push(lft.name)
  //           tmp.push([...currentYearFee])
  //         }
  //       }
  //     }
  //     this.lodgeFeesTypes = tmp
  //   }
  // }

  setRoomTotalShow (show) {
    for (const room of this.rooms) {
      room.totalShow = show
    }
  }

  static clone (element, newIndex) {
    return new QuoteElement(element, null, newIndex)
  }

  static fromAutosave (data) {
    const element = new QuoteElement()
    for (const k of Object.keys(data)) {
      element[k] = data[k]
    }
    let lodge = element.lodge
    if (lodge?.id) {
      lodge = Lodge.byId(lodge.id)
    }
    if (data.rooms?.length > 0) {
      element.rooms = []
      for (const room of data.rooms) {
        element.rooms.push(QuoteElementRoom.fromAutosave(room, lodge))
      }
    }
    if (data.treeHouses?.length > 0) {
      element.treeHouses = []
      for (const treeHouse of data.treeHouses) {
        element.treeHouses.push(QuoteElementRoomTreeHouse.fromAutosave(treeHouse))
      }
    }
    if (data.lodgeFeesTypes?.length > 0) {
      element.lodgeFeesTypes = []
      for (const lodgeFeesType of data.lodgeFeesTypes) {
        element.lodgeFeesTypes.push(LodgeFee.byId(lodgeFeesType.id, lodge.fees))
      }
    }
    if (data.travelArrangements?.length > 0) {
      element.travelArrangements = []
      for (const travelArrangement of data.travelArrangements) {
        const t = new QuoteTravelArrangement()
        t.fromAutosave(travelArrangement)
        element.travelArrangements.push(t)
      }
    }

    element.lodgeFeesThirdParty = new LodgeFee(data.lodgeFeesThirdParty)
    if (data.package) {
      element.package = LodgePackage.byId(data.package.id, lodge.packages)
    }

    try {
      element.parkFee = new Amount(data.parkFee._amount, data.currency || data.parkFee.currency)
    } catch (error) {

    }
    try {
      element.total = new Amount(data.total._amount, data.currency || data.total.currency)
    } catch (error) {

    }
    if (data.package) {
      element.package = LodgePackage.byId(data.package.id, lodge.packages)
    }
    return element
  }

  static fromEnquiry (enquiry, thirdParty = null, index = 0, currency = null) {
    const element = new QuoteElement(null, thirdParty, index, currency)
    element.international = enquiry.countryCd !== 'ZA'
    if (enquiry.properties && enquiry.properties.length > 0) {
      const plr = (enquiry.properties?.length > index + 1) ? enquiry.properties[index] : enquiry.properties[0]
      if (thirdParty === null || plr.property.more !== thirdParty) {
        element.property = plr.property
        if (plr._lodgeId && element.property.hasLodges()) {
          element.lodge = Lodge.byId(plr._lodgeId, element.property.lodges)
          let roomType
          if (plr._roomTypeId && element.lodge.roomTypes?.length > 0) {
            roomType = RoomType.byId(plr._roomTypeId, element.lodge.roomTypes)
          } else if (element.lodge.hasOnlyOneRoomType()) {
            roomType = element.lodge.hasOnlyOneRoomType()
          }
          element.rooms.push(QuoteElementRoom.fromEnquiry(element.lodge, roomType, currency || element.property.standardCurrency, enquiry.adults || 0, enquiry.children || 0))
        }
      } else {
        element.property = Property[(thirdParty ? 'thirdParties' : 'more')]()[0]
      }
    } else {
      let roomType
      if (thirdParty === null || !thirdParty) {
        element.property = Property.more()[0]
        element.lodge = element.property.hasLodges() ? element.property.lodges[0] : new Lodge()
        if (element.lodge?.roomTypes?.length > 0) {
          roomType = element.lodge.roomTypes[0]
        } else if (element.lodge.hasOnlyOneRoomType()) {
          roomType = new RoomType()
        }
      } else {
        element.property = Property.thirdParties()[0]
        element.lodge = new Lodge()
        roomType = new RoomType()
      }
      element.rooms.push(QuoteElementRoom.fromEnquiry(element.lodge, roomType, currency || element.property.standardCurrency, enquiry.adults || 0, enquiry.children || 0))
    }
    element._dateIn = enquiry.dateStart
    element._dateOut = enquiry.dateEnd
    element._monthIn = enquiry.monthStart
    element._monthOut = enquiry.monthEnd
    element.nights = enquiry.noNights
    return element
  // 'numRooms',
  // 'packageName',
  // 'nights',
  // 'vehicles',
  // 'rateShow',
  // 'parkFeeShow',
  // 'totalLodgeFeesShow',
  // 'totalShow',
  // 'currency',
  // 'additional',
  // 'cancellationPolicy',
  //     'order',
  //     'packageCost',
  // 'packageTotalCost',
  // 'rate',
  // 'parkFee',
  // 'totalLodgeFees',
  //     'total',
  //      'excluding',
  // 'images',
  // 'including'
  }

  static multipleFromEnquiry (enquiry) {
    let index = 0
    const elements = []
    let currency
    for (const accommodation of enquiry.properties) {
      const property = accommodation.property
      if (index === 0) {
        currency = property.standardCurrency
      }
      const element = new QuoteElement(null, !property.more, index, currency)
      index++
      element.international = enquiry.countryCd !== 'ZA'
      element.property = property
      if (accommodation._lodgeId && element.property.hasLodges()) {
        element.lodge = Lodge.byId(accommodation._lodgeId, element.property.lodges)
        let roomType
        if (accommodation._roomTypeId && element.lodge.roomTypes?.length > 0) {
          roomType = RoomType.byId(accommodation._roomTypeId, element.lodge.roomTypes)
        } else if (element.lodge.hasOnlyOneRoomType()) {
          roomType = element.lodge.hasOnlyOneRoomType()
        }
        element.rooms.push(QuoteElementRoom.fromEnquiry(element.lodge, roomType, currency, enquiry.adults || 0, enquiry.children || 0, enquiry.childrenAges))
        element._dateIn = enquiry.dateStart
        element._dateOut = enquiry.dateEnd
        element._monthIn = enquiry.monthStart
        element._monthOut = enquiry.monthEnd
        element.nights = enquiry.noNights
      }
      elements.push(element)
    }
    return elements
  }

  static objectToSave (element) {
    return {
      propertyId: element.property.id,
      lodgeId: element.lodge?.id,
      lodgeName: element.lodge?.id ? null : element.lodgeName,
      displayAccommodationContacts: element.displayAccommodationContacts,
      numRooms: element.numRooms,
      package: element.package?.id,
      packageCost: element.packageCost?.amount || 0,
      packageTotalCost: element.packageTotalAmount?.amount || 0,
      dateIn: !element.isMonthSelection ? element.dateIn?.dateOnlyDB : null,
      dateOut: !element.isMonthSelection ? element.dateOut?.dateOnlyDB : null,
      monthIn: element.isMonthSelection ? element.dateIn?.dateOnlyDB : null,
      monthOut: element.isMonthSelection ? element.dateOut?.dateOnlyDB : null,
      nights: element.nights,
      international: element.international,
      adults: element.adults,
      children: element.children,
      vehicles: element.vehicles,
      rate: element.rate?.amount || 0,
      rateShow: element.rateShow,
      totalLodgeFees: element.totalLodgeFees?.amount || 0,
      totalLodgeFeesShow: !!element.totalLodgeFeesShow,
      lodgeFeesTypes: element.lodgeFeesTypes?.length > 0 && Array.isArray(element.lodgeFeesTypes)
        ? [...new Set(element.lodgeFeesTypes.map(lft => lft.name))]
        : element.lodgeFeesTypes,
      total: element.totalAmount?.amount || 0,
      totalShow: !!element.totalShow,
      currency: element.currency,
      additional: element.additional,
      including: element.including,
      excluding: element.excluding,
      rooms: (element.rooms || []).map(room => QuoteElementRoom.objectToSave(room, element.nights)),
      images: element.images,
      // services: (element.services || []).map(extra => QuoteExtra.objectToSave(extra)),
      // tours: (element.tours || []).map(extra => QuoteExtra.objectToSave(extra)),
      travelArrangements: (element.travelArrangements || []).map(extra => QuoteExtra.objectToSave(extra))
    }
  }
}
