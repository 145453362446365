import Vue from 'vue'
import store from '../store'
import DateTime from './DateTime'

export default class QuoteAttachment {
  constructor (obj, selected = false) {
    this.toBeDeleted = false
    if (obj.id) {
      this.id = obj.id
      this.filename = obj.filename || obj.name
      this.default = obj.default
      this.order = obj.order
      this._createdAt = obj._createdAt || obj.createdAt
      this.createdById = obj.createdById
      this._updatedAt = obj._updatedAt || obj.updatedAt
      this.updatedById = obj.updatedById
    } else {
      this.filename = obj.filename
      this.type = obj.type
      this.isConfirmation = obj.isConfirmation
      this._createdAt = obj.date
    }
    this.selected = selected
  }

  get createdAt () {
    return new DateTime(this._createdAt)
  }

  get name () {
    return this.type === 'quote'
      ? `${this.isConfirmation ? 'Confirmation' : 'Quote'}_${this.filename} (${this.createdAt})`
      : this.filename
  }

  get shortName () {
    return this.type === 'quote'
      ? `${this.isConfirmation ? 'Confirmation' : 'Quote'}_${this.filename}`
      : this.filename
  }

  delete () {
    return Vue.$api.quote.deleteAttachment(this.id)
  }

  getAttachment () {
    return Vue.$api.quote.getAttachment(this.id)
  }

  saveToggleDefault () {
    return Vue.$api.quote.toggleAttachmentDefault(this.id)
  }

  static fromAutosave (data) {
    return QuoteAttachment.getAttachments().find(a => a.id === data.id)
  }

  static getAttachments () {
    const attachments = store.state.quotes.attachments
    if (store.state.quotes.attachments[0].constructor !== QuoteAttachment) {
      return QuoteAttachment.parseMultiple(attachments)
    }
    return attachments
  }

  static async loadAttachments (reload = false) {
    try {
      if (reload || !store.state.quotes.attachments) {
        const { data } = await Vue.$api.quote.getAttachments()
        const parsed = QuoteAttachment.parseMultiple(data.items)
        store.commit('setAttachments', parsed)
        return parsed
      }
    } catch (error) {
      return []
    }
  }

  static async loadEnquiryAttachments (enquiryId) {
    try {
      const { data } = await Vue.$api.enquiry.getAttachments(enquiryId)
      return QuoteAttachment.parseMultiple(data)
    } catch (error) {
      return []
    }
  }

  static parseMultiple (unparsedAttachments = []) {
    const attachments = []
    if (
      unparsedAttachments.length > 0 &&
      unparsedAttachments[0].constructor !== QuoteAttachment
    ) {
      for (const attachment of unparsedAttachments) {
        attachments.push(new QuoteAttachment(attachment))
      }
    }
    return attachments
  }

  static uploadMultiple (formData) {
    return Vue.$api.quote.uploadAttachments(formData)
  }
}
