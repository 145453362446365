import axios from 'axios'

const files = require.context('.', false, /\.js$/)
const factories = {}

for (const key of files.keys()) {
  if (key !== './index.js') {
    factories[key.replace(/(\.\/|\.js)/g, '')] = files(key).default(axios)
  }
}
export default factories
