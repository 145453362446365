import DateTime from './DateTime'

const maxKidsAges = 15
export default class GuestChild {
  constructor (obj) {
    this.id = obj.id
    this.name = obj.name
    this.surname = obj.surname
    this._dob = obj.dob
    this.enquiriesIds = obj.enquiriesIds
    this.info = obj.info
  }

  get dob () {
    return new DateTime(this._dob)
  }

  get fullName () {
    return `${this.surname ? this.surname : ''}${(this.surname && this.name ? ' ' : '')}${this.name ? this.name : ''}`
  }

  get questionnaire () {
    return [
      {
        question: 'Name',
        answer: this.name
      },
      {
        question: 'Surname',
        answer: this.surname
      }, {
        question: 'Date of Birth',
        answer: this.dob.dateOnly
      }, {
        question: 'Any Dietary Requirements or Allergies',
        answer: this.info?.dietaryRequirementsAllergies
      }, {
        question: 'Favourite Delights',
        answer: this.info?.favouriteDelights
      }, {
        question: 'Favourite Animal',
        answer: this.info?.favouriteAnimal
      }
    ]
  }

  static optionsKidsAges () {
    return Array.from(Array(maxKidsAges + 1).keys())
  }
}
