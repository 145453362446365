import moment from 'moment'

export default class DateTime {
  constructor (s) {
    if (moment.isMoment(s)) {
      this.moment = s.clone()
    } else if (s) {
      if (typeof s === 'object' && typeof s.moment !== 'undefined') {
        this.moment = s.moment ? moment(s.moment) : null
      } else {
        this.moment = s ? moment(s) : null
      }
    } else {
      this.moment = null
    }
    if (!this.isValid()) {
      this.moment = null
    }
  }

  get d () {
    return this.moment.toDate()
  }

  set d (value) {
    this.moment = moment(value)
  }

  get time () {
    return this.format('HH:mm')
  }

  set time (time) {
    this.moment = moment(`${this.dateOnlyDB || DateTime.today().dateOnlyDB} ${time}`)
  }

  get dateOnly () {
    return this.isValid() ? this.format('Do MMM YYYY') : null
  }

  get dateOnlyNoYear () {
    return this.isValid() ? this.format('Do MMM') : null
  }

  get monthYearOnly () {
    return this.isValid() ? this.format('MMM YYYY') : null
  }

  get dateOnlyDB () {
    return this.isValid() ? this.format('YYYY-MM-DD') : null
  }

  get monthYearOnlyDB () {
    return this.isValid() ? this.format('YYYY-MM') : null
  }

  get short () {
    return this.isValid() ? this.format('DD/MM/YYYY @ HH:mm') : null
  }

  get year () {
    return this.moment.year()
  }

  format (format) {
    return this.isValid() ? DateTime.format(this, format) : null
  }

  isFutureDay () {
    try {
      return moment().diff(this.moment, 'days') < 0
    } catch (error) {
      console.error(error)
    }
  }

  isPastDay () {
    try {
      return moment().diff(this.moment, 'days') > 0
    } catch (error) {
      console.error(error)
    }
  }

  isToday () {
    try {
      return moment().diff(this.moment, 'days') === 0
    } catch (error) {
      console.error(error)
    }
  }

  isValid () {
    return this.moment && this.moment.isValid()
  }

  setToStartOfDay () {
    this.moment.startOf('day')
  }

  toString () {
    let s
    try {
      if (this.moment) {
        s =
          this.moment.format("D MMM 'YY - hh:mm") +
          this.moment.format(' A').toLowerCase()
      }
    } catch (error) {
      console.error(error)
      s = ''
    }
    return s
  }

  static daysAfter (date, days) {
    try {
      return new DateTime(date.moment.clone().add(days, 'd'))
    } catch (error) {}
  }

  static diffDays (d1, d2) {
    try {
      return d2.moment.diff(d1.moment, 'days')
    } catch (error) {}
  }

  static format (dt, format) {
    let s
    try {
      s = dt.moment.format(format)
    } catch (error) {
      console.error(error)
      s = ''
    }
    return s
  }

  static isAfter (d1, d2) {
    try {
      return d2.moment.isBefore(d1.moment)
    } catch (error) { }
  }

  static isBefore (d1, d2) {
    try {
      return d1.moment.isBefore(d2.moment)
    } catch (error) { }
  }

  static max (arrOfDateTimes) {
    try {
      return new DateTime(moment.max(arrOfDateTimes.filter(a => a?.isValid()).map(a => a.moment)))
    } catch (error) {}
  }

  static min (arrOfDateTimes) {
    try {
      return new DateTime(moment.min(arrOfDateTimes.filter(a => a?.isValid()).map(a => a.moment)))
    } catch (error) {}
  }

  static startOfDay (d) {
    d = d || moment().toDate()
    if (typeof d === 'string' || (typeof d === 'object' && d.constructor === Date)) {
      return new DateTime(moment(d).startOf('day'))
    }
    if (d.constructor === DateTime) {
      d = d.isValid() ? d.moment : moment()
      return new DateTime(d.startOf('day'))
    }
  }

  static today () {
    return new DateTime(moment())
  }

  static tomorrow () {
    return new DateTime(moment().add(1, 'd'))
  }
}
