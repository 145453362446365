import store from '@/store'
import Vue from 'vue'

export default class PropertyType {
  static primitiveFields () {
    return ['id', 'itineraryIconId', 'name', 'order']
  }

  constructor (fromStore) {
    for (const field of PropertyType.primitiveFields()) {
      this[field] = fromStore[field]
    }
  }

  static async loadAll (reload = false) {
    try {
      if (reload || !store.state.properties.propertyTypes) {
        const { data } = await Vue.$api.property.getTypes()
        store.commit(
          'setPropertyTypes',
          PropertyType.parseMultipleFromStore(data.items)
        )
      }
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  static all () {
    if (store.state.properties.propertyTypes && store.state.properties.propertyTypes[0].constructor !== PropertyType) {
      store.commit('setPropertyTypes', PropertyType.parseMultipleFromStore(store.state.properties.propertyTypes))
    }
    return store.state.properties.propertyTypes
  }

  static parseMultipleFromStore (objs) {
    const ps = []
    for (const obj of objs) {
      ps.push(new PropertyType(obj))
    }
    return ps
  }
}
