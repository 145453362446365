export default axios => ({
  addImages: formData => axios.post('gallery', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  ),
  copyImage: data => axios.post('gallery/copy', data),
  get: () => axios.get('galleries'),
  removeImage: id => axios.delete(`gallery/${id}`)
})
