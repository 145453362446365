import Notification from './Notification'
import User from './MyUser'
import store from '@/store'
import SideBarMenu from './SideBarMenu'

export default class SideBarMenuItem {
  constructor (item) {
    this.id = item.id
    this.name = item.name ? item.name.toLowerCase() : ''
    this.roles = item.roles
    this.link = item.link
    if (!this.name && item.label) {
      const labelParts = item.label.split(' ')
      for (const [index, labelPart] of labelParts.entries()) {
        if (index === 0) {
          this.name = labelPart.toLowerCase()
        } else {
          this.name += labelPart.charAt(0).toUpperCase() + labelPart.slice(1).toLowerCase()
        }
      }
    }
    this.label = item.label
    this.component = item.component
    this.type = item.type

    if (item.subItems && item.subItems.length > 0) {
      this.subItems = []
      for (const subItem of item.subItems) {
        this.subItems.push(new SideBarMenuItem(subItem))
      }
    }

    this.to = item.to || item.label
  }

  get counter () {
    if (this.id === SideBarMenu.tasksListItemId()) {
      return Notification.numNewEnquiries()
    }
  }

  get parent () {
    return SideBarMenu.getParentItemByChildId(this.id)
  }

  get selected () {
    const selectedMenuItem = store.getters.selectedMenuItem
    return selectedMenuItem && (selectedMenuItem.id === this.id ||
      (selectedMenuItem.parent && selectedMenuItem.parent.id === this.id))
  }

  get to () {
    return this._to
  }

  set to (to) {
    this._to = to.replace(/\s/g, '')
  }

  canBeAddedToMenu () {
    return (this.roles && User.current()?.hasAnyOfRoles(this.roles)) || !this.roles
  }

  hasSubmenu () {
    return this.subItems && this.subItems.length > 0
  }

  hasTopMenu () {
    return this.id.length >= 4
  }

  select () {
    if (this.link) {
      window.open(this.link, '_blank')
    } else {
      store.commit('setSelectedMenuItem', this)
    }
  }

  static parseFromStore (obj) {
    if (obj && obj.constructor !== SideBarMenuItem) {
      obj = new SideBarMenuItem(obj)
      obj.select()
    }
    return obj
  }
}
