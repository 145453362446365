import Vue from 'vue'
// import VueJwtDecode from 'vue-jwt-decode'
import User from '../../classes/MyUser'

const state = {
  travelExecutives: null,
  user: null,
  moreContacts: null,
  roles: null
}

const mutations = {
  clearUserState (state, notificationsRepeater) {
    clearInterval(notificationsRepeater)
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setMoreContacts (state, moreContacts) {
    state.moreContacts = moreContacts
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setTravelExecutives (state, travelExecutives) {
    state.travelExecutives = travelExecutives
  },
  setUser (state, user) {
    state.user = new User(user)
  }
}

const actions = {
  async logout ({ commit, getters }, invalidateToken = true) {
    const notificationsRepeater = getters.notificationsRepeater
    commit('clearEnquiryState')
    commit('clearNewInterestState')
    commit('clearNotificationState')
    commit('clearPropertyState')
    commit('clearQuoteState')
    commit('clearSettingState')
    commit('clearGalleryState')
    if (invalidateToken) {
      try {
        await Vue.$api.user.logout()
        commit('clearUserState', notificationsRepeater)
      } catch (error) {
        console.error(error)
        commit('clearUserState', notificationsRepeater)
      }
    }
  }
}

export default {
  state,
  mutations,
  actions
}
