export default axios => ({
  blockUnblock: userId => axios.put(`user/block/${userId}`),
  checkInitials: data => axios.get(`user/${data.userId}/initials/${data.initials}`),
  checkUsername: username => axios.get('user/username/available/' + username),
  getMoreContacts: () => axios.get('users/more'),
  getRoles: () => axios.get('roles'),
  getTravelExecutives: () => axios.get('users/te'),
  getDetailsById: id => axios.get(`user/${id}`),
  allWithRole: () => axios.get('users/role'),
  login: (username, password) => axios.post('login', { username, password }),
  logout: () => axios.put('logout'),
  passwordUpdate: data => axios.put('passwordupdate', data),
  resetPassword: userId => axios.put(`user/${userId}/password`),
  passwordForgotten: data => axios.post('passwordforgotten', data),
  saveDetails: data => axios.post('user', data),
  setProfilePicture: ({ userId, formData }) => axios.post(`user/${userId}/picture`, formData, {
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' }
  })
})
