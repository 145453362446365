export default class ChargeType {
  static primitiveFields () {
    return ['description', 'id', 'short']
  }

  constructor (obj) {
    this.description = obj.description
    this.id = obj.id
    this.short = obj.short
  }

  static default () {
    this.description = 'per person per night'
    this.id = 2
    this.short = 'pppn'
  }

  static byRoomTypeLodgeProperty (room, lodge, property) {
    if (property.more) {
      const cts = [...(room?.roomType?.chargeTypes || []), ...(lodge?.chargeTypes || []), ...(property.chargeTypes || [])].filter(e => e)
      const chargeTypes = []
      const shortChargeTypes = []

      for (const ct of cts) {
        if (!shortChargeTypes.includes(ct.short)) {
          shortChargeTypes.push(ct.short)
          chargeTypes.push(ct)
        }
      }
      return chargeTypes
    } else {
      return [ChargeType.default(), { id: 3, short: 'ps', description: 'per stay' }].filter(e => e)
    }
  }
}
