
import moment from 'moment'
import Vue from 'vue'
import store from '../store'
import Amount from './Amount'
import DateTime from './DateTime'
import Property from './Property'
import Quote from './Quote'
import QuoteAttachment from './QuoteAttachment'
import QuoteElement from './QuoteElement'
import QuoteExtra from './QuoteExtra'
import QuoteInfo from './QuoteInfo'
import QuotePayment from './QuotePayment'
import QuoteService from './QuoteService'
import QuoteTour from './QuoteTour'
import User from './MyUser'

const primitiveFields = [
  'banner',
  'categoryId',
  'confirmationNum',
  'enquiryId',
  'guestPdf',
  'hasItinerary',
  'id',
  'isConfirmation',
  'itineraryAttached',
  'messageId',
  'opened',
  'pdf',
  'pdfAttached',
  'reference',
  'saved',
  'sent',
  'sentMore',
  'serviceFee',
  'serviceFeeShow',
  'subject',
  'teId',
  'tmp',
  'travelInsuranceRequested',
  'txt1',
  'txt2'
]
const arrayOfStringFields = ['contacts', 'contactsMore', 'images']
const dateFields = ['date', 'dateDelivered', 'dateSent']
const todoFields = [ // TODO
  'travelLogistics',
  'afterSignature'
  // 'remoteAttachments'
]
const amountsFields = ['total']

export default class QuoteOption {
  constructor (objOrId = null) { // if not from store, then it's from API
    this.setKey()
    this.serviceFeeAdd = true
    this.attachments = []
    this.elements = []
    this.payments = []
    this.services = []
    this.tours = []
    if (!objOrId) {
      for (const field of primitiveFields.concat(todoFields)) {
        this[field] = null
      }
      for (const field of arrayOfStringFields) {
        this[field] = []
      }
      this.total = new Amount(0)
      for (const field of dateFields) {
        this[field] = new DateTime()
      }
    } else if (typeof objOrId === 'number') {
      this.id = objOrId
    } else {
      this.process(objOrId)
    }
    this.contactsMore = this.contactsMore || []
    if (!this.contactsMore.includes(User.current().email)) {
      this.contactsMore.push(User.current().email)
    }
  }

  // get canConfirmQuote () {
  //   return this.isQuote && this.sent && !this.isConfirmation
  // }

  // get canForwardQuote () {
  //   return (this.saved && !this.sent) || this.sent
  // }

  // get canSendQuote () {
  //   return this.saved && !this.sent
  // }

  get currency () {
    if (this.elements && this.elements.length > 0) {
      return this.elements[0].currency
    }
    return 'ZAR'
  }

  get dateInMin () {
    return new DateTime(moment.min(this.elements.map(el => el.dateIn.moment)))
  }

  get dateOutMax () {
    return new DateTime(moment.max(this.elements.map(el => el.dateOut.moment)))
  }

  get isQuote () {
    return this.elements && this.elements.length > 0
  }

  get missingTexts () {
    let arrayMissingTexts = []
    if (this.elements?.length > 0) {
      for (const [indexElement, element] of this.elements.entries()) {
        if (!element.isCompleted()) {
          arrayMissingTexts = arrayMissingTexts.concat(element.missingTexts.map(t => `Accommodation ${indexElement + 1} - ${t}`))
        }
      }
    } else {
      arrayMissingTexts.push('Empty')
    }
    return arrayMissingTexts
  }

  get totalCost () { // For calculation when creating / editing a quote
    const total = Amount.sum([
      this.totalCostAccommodations,
      this.totalCostTravelArrangements,
      this.totalCostServices,
      this.totalCostTours
    ])
    if (this.serviceFeeAdd) {
      const serviceFee = this.serviceFee || Quote.serviceFeePerc()
      total.amount += serviceFee * total.amount
    }
    return total
  }

  get totalCostAccommodations () {
    if (this.elements) {
      return Amount.sum(this.elements.map(e => e.totalAmount))
    }
    return new Amount(0, this.currency)
  }

  get totalCostServices () {
    if (this.services && this.services.length > 0) {
      return Amount.sum(this.services.map(s => s.total))
    }
    return new Amount(0, this.currency)
  }

  get totalCostTours () {
    if (this.tours && this.tours.length > 0) {
      return Amount.sum(this.tours.map(t => t.total))
    }
    return new Amount(0, this.currency)
  }

  get totalCostTravelArrangements () {
    if (this.elements && this.elements.length > 0) {
      return Amount.sum(this.elements.map(e => {
        return e.totalCostTravelArrangements
      }))
    }
    return new Amount(0, this.currency)
  }

  addElement (element) {
    this.elements = this.elements || []
    this.elements.push(element)
  }

  addPayment (payment) {
    this.payments = this.payments || []
    this.payments.push(payment || new QuotePayment(null, this.currency))
  }

  // called by addService and addTour
  // category can be 'services' ot 'tours'
  // if index === null, add extra. if index !== null, replace extra.
  addExtra (category, item, index) {
    let myIndex = index
    let num = 1
    if (!(myIndex >= 0)) {
      // add at the end
      myIndex = this[category].length
      num = 0
    }

    this[category].splice(myIndex, num, item)
    this[category] = this[category].sort((a, b) => {
      const isb = DateTime.isBefore(a.date, b.date) ? -1 : 1
      return isb
    })
    this.setKey()
  }

  // if index === null, add service. if index !== null, replace service.
  addService (service, index = null) {
    this.addExtra('services', service, index)
  }

  // if index === null, add tour. if index !== null, replace tour.
  addTour (tour, index = null) {
    this.addExtra('tours', tour, index)
  }

  clearGallery () {
    if (this.images) {
      this.images.splice(0, this.images.length)
    }
  }

  async load () {
    try {
      const { data } = await Vue.$api.quote.getById(this.id)
      this.process(data)
    } catch (error) {
      console.error(error)
    }
  }

  deleteElement (index) {
    this.elements.splice(index, 1)
    this.setKey()
  }

  deletePayment (index) {
    this.payments.splice(index, 1)
    this.setKey()
  }

  deleteService (index) {
    this.services.splice(index, 1)
    this.setKey()
  }

  deleteTour (index) {
    this.tours.splice(index, 1)
    this.setKey()
  }

  hasInfo (type) {
    switch (type) {
      case 'travelDocumentation':
        return (
          this.infos.filter(
            (i) =>
              i.field === 'passport' ||
                i.field === 'visaSA' ||
                i.field === 'visaZim'
          ).length > 0
        )
      case 'needToKnows':
        return (
          this.infos.filter(
            (i) => i.field === 'malaria' || i.field === 'vaccinations'
          ).length > 0
        )
      default:
        return false
    }
  }

  isCompleted () {
    return this.missingTexts.length === 0
  }

  process (obj) {
    this.autosaveId = obj.autosaveId
    for (const field of primitiveFields) {
      this[field] = obj[field]
    }
    for (const field of arrayOfStringFields) {
      this[field] = [...(obj[field] || [])]
    }
    for (const field of todoFields) {
      this[field] = obj[field]
    }
    for (const field of dateFields) {
      this[field] = new DateTime(obj[field])
    }

    this.elements = []
    if (obj.elements) {
      for (const element of obj.elements) {
        this.elements.push(new QuoteElement(element, Property.byId(element._propertyId), element.index, element.currency))
      }
    }

    for (const field of amountsFields) { // it needs to be after elements to have currency
      this[field] = new Amount(obj[field], this.currency)
    }

    this.infos = []
    if (obj.infos) {
      for (const info of obj.infos) {
        this.infos.push(new QuoteInfo(info))
      }
    }
    this.payments = []
    if (obj.payments) {
      for (const payment of obj.payments) {
        this.payments.push(new QuotePayment(payment, this.currency))
      }
    }
    this.services = []
    if (obj.services) {
      for (const service of obj.services) {
        this.services.push(new QuoteService(service, this.currency))
      }
    }
    this.tours = []
    if (obj.tours) {
      for (const tour of obj.tours) {
        this.tours.push(new QuoteTour(tour, this.currency))
      }
    }
    this.attachments = []
    if (obj.attachments) {
      for (const attachment of obj.attachments) {
        this.attachments.push(new QuoteAttachment(attachment))
      }
    }
  }

  reorderElements (oldIndex, newIndex) {
    let currentIndex = oldIndex
    while (currentIndex !== newIndex) {
      if (oldIndex > newIndex) {
        [this.elements[currentIndex - 1], this.elements[currentIndex]] = [
          this.elements[currentIndex],
          this.elements[currentIndex - 1]
        ]
        currentIndex--
      } else if (oldIndex < newIndex) {
        [this.elements[currentIndex], this.elements[currentIndex + 1]] = [
          this.elements[currentIndex + 1],
          this.elements[currentIndex]
        ]
        currentIndex++
      } else {
        currentIndex = newIndex
      }
    }
    for (const element of this.elements) {
      element.setKey()
    }
  }

  // for emails that were saved and not sent
  async send () {
    const formData = new FormData()
    formData.append('ids[0]', this.id)
    const errorMsg = 'Email NOT sent'
    try {
      await Vue.$api.quote.send(formData)
      return true
    } catch (error) {
      console.error(error)
      throw new Error(errorMsg)
    }
  }

  setKey () {
    this.key = moment().valueOf()
  }

  store () {
    store.commit('setQuote', this)
  }

  static clearStore () {
    store.commit('setQuote', null)
  }

  static createFromEnquiry (enquiry, firstCreation) {
    const elements = QuoteElement.multipleFromEnquiry(enquiry)
    const option = new QuoteOption()
    option.enquiryId = enquiry.id
    for (const element of elements) {
      option.addElement(element)
    }
    if (firstCreation) {
      if (enquiry?.guests) {
        const pGuests = enquiry.guests.filter(g => g.primary)
        if (pGuests.length > 0) {
          const pGuest = pGuests[0]
          option.contacts.push(pGuest.email)
          option.txt1 = `Dear ${pGuest.name},<br />`
        }
      }
      const tp = User.current()
      option.txt2 = `<p>Let me know if the above would assist and I will be happy to hold a reservation for you.</p><p><br></p><p>Best regards,</p><p>${tp.name}</p>`
    }
    return option
  }

  static fromAutosave (data, date, autosaveId, autosavedQuote) {
    const option = new QuoteOption()
    option.autosaveId = autosaveId
    option.date = date
    option.elements = []
    for (const element of data.elements) {
      option.elements.push(QuoteElement.fromAutosave(element))
    }
    option.payments = []
    for (const payment of data.payments) {
      option.payments.push(QuotePayment.fromAutosave(payment))
    }
    option.services = []
    for (const service of data.services) {
      const s = new QuoteService()
      s.fromAutosave(service)
      option.services.push(s)
    }
    option.tours = []
    for (const tour of data.tours) {
      const t = new QuoteTour()
      t.fromAutosave(tour)
      option.tours.push(t)
    }
    option.banner = data.banner
    option.categoryId = data.categoryId
    option.guestPdf = data.guestPdf
    option.hasItinerary = data.hasItinerary
    option.id = data.id
    option.images = data.images
    option.messageId = data.messageId
    option.opened = data.opened
    option.pdf = data.pdf
    option.saved = data.saved
    option.sent = data.sent
    option.sentMore = data.sentMore
    option.serviceFee = data.serviceFee
    option.teId = data.teId
    option.tmp = data.tmp
    option.travelInsuranceRequested = data.travelInsuranceRequested
    option.travelLogistics = data.travelLogistics

    option.confirmationNum = autosavedQuote.confirmationNum
    option.enquiryId = autosavedQuote.enquiryId
    option.isConfirmation = autosavedQuote._isConfirmation
    option.itineraryAttached = autosavedQuote.itineraryAttached
    option.pdfAttached = autosavedQuote.pdfAttached
    option.reference = autosavedQuote.reference
    option.serviceFeeAdd = autosavedQuote._serviceFeeAdd
    option.serviceFeeShow = autosavedQuote.serviceFeeShow
    option.subject = autosavedQuote.subject
    option.txt1 = autosavedQuote._txt1
    option.txt2 = autosavedQuote._txt2
    option.contacts = autosavedQuote.contacts
    option.contactsMore = autosavedQuote.contactsMore
    option.afterSignature = autosavedQuote._txtAfterSignature
    try {
      option.total = new Amount(data.total.amount, data.total.currency)
    } catch (error) {}
    return option
  }

  static fromStore () {
    return new QuoteOption(store.state.quotes.quote)
  }

  static objectToSave (option) {
    return {
      total: option.totalCost?.amount || 0,
      currency: option.currency,
      images: option.images,
      services: (option.services || []).map(extra => QuoteExtra.objectToSave(extra)),
      tours: (option.tours || []).map(extra => QuoteExtra.objectToSave(extra)),
      // travelArrangements: (option.travelArrangements || []).map(extra => QuoteExtra.objectToSave(extra)),
      elements: (option.elements || []).map(element => QuoteElement.objectToSave(element))
    }
  }
}
