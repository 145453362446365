import store from '@/store'
import SideBarMenuItem from './SideBarMenuItem'

const defaultItems = [
  {
    id: '01',
    label: 'Dashboard',
    roles: ['HoTEs', 'SU']
    //     'isHoTEs',
    // 'isMT',
    // 'isOC',
    // 'isSU',
    // 'isTC',
    // 'isTE',
    // 'isTPSM',

  },
  {
    id: '02',
    label: 'Enquiries',
    subItems: [
      {
        id: '0201',
        label: 'Create task',
        roles: ['TE', 'OC', 'TC', 'SU', 'HoTEs', 'TPSM'],
        component: 'CreateEnquirySubView'
      },
      {
        id: '0202',
        label: 'New tasks',
        roles: ['TC', 'SU', 'HoTEs', 'TPSM'],
        component: 'EnquiriesSubView',
        type: 'unassigned'
      },
      {
        id: '0203',
        label: 'Unattended tasks',
        roles: ['TE', 'TC', 'SU', 'HoTEs', 'TPSM'],
        component: 'EnquiriesSubView',
        type: 'unattended'
      },
      {
        id: '0204',
        label: 'Completed tasks',
        roles: ['HoTEs', 'MT', 'SU', 'TC', 'TE', 'TPSM'],
        component: 'EnquiriesSubView',
        type: 'contacted'
      },
      {
        id: '0205',
        label: 'Deleted tasks',
        roles: ['HoTEs', 'MT', 'SU', 'TC', 'TE', 'TPSM'],
        component: 'EnquiriesSubView',
        type: 'deleted'
      },
      {
        id: '0206',
        label: 'Archived tasks',
        roles: ['HoTEs', 'MT', 'SU', 'TC', 'TE', 'TPSM'],
        component: 'EnquiriesSubView',
        type: 'archived'
      },
      {
        id: '0207',
        label: 'Search',
        roles: ['HoTEs', 'MT', 'SU', 'TC', 'TE', 'TPSM'],
        component: 'EnquiriesSearchSubView'
      },
      {
        id: '0208',
        label: 'New interests',
        roles: ['HoTEs', 'MT', 'SU', 'TC', 'TE', 'TPSM'],
        component: 'NewInterestsSubView'
      },
      {
        id: '0209',
        label: 'Reports',
        link: 'https://datastudio.google.com/reporting/b28a0e37-cd27-42ba-923f-f8beea7f5ccf',
        roles: ['TC', 'HoTEs', 'TPSM']
      }
    ]
  },
  {
    id: '03',
    label: 'Responses',
    subItems: [
      {
        id: '0301',
        label: 'Responded',
        component: 'ResponsesSubView',
        type: 'responded'
      },
      {
        id: '0302',
        label: 'Unconfirmed',
        component: 'ResponsesSubView',
        type: 'quoted'
      },
      {
        id: '0303',
        label: 'Confirmed',
        component: 'ResponsesSubView',
        type: 'confirmed'
      }
    ]
  },
  {
    id: '04',
    label: 'Guests',
    subItems: [
      {
        id: '0401',
        label: 'Create Profile',
        component: 'GuestsSubView',
        type: 'nolink'
      },
      {
        id: '0402',
        label: 'Incomplete',
        component: 'GuestsSubView',
        type: 'noQuestionnaire'
      },
      {
        id: '0403',
        label: 'Completed',
        component: 'GuestsSubView',
        type: 'withQuestionnaire'
      },
      {
        id: '0404',
        label: 'All Upcoming',
        component: 'GuestsSubView',
        type: 'upcoming'
      },
      {
        id: '0405',
        label: 'All Historical',
        component: 'GuestsSubView',
        type: 'past'
      },
      {
        id: '0406',
        label: 'All',
        component: 'GuestsSubView',
        type: 'all'
      }]
  }, {
    id: '05',
    label: 'Additional',
    subItems: [
      {
        id: '0501',
        label: 'Default Info',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM'],
        component: 'DefaultInfoSubView'
      },
      {
        id: '0502',
        label: 'Blacklist',
        component: 'BlacklistSubView'
      },
      {
        id: '0503',
        label: 'Gallery',
        component: 'GallerySubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0504',
        label: 'Lodge Extras',
        component: 'LodgeExtrasSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0505',
        label: 'Lodge Fees',
        component: 'LodgeFeesSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0506',
        label: 'Property Rooms',
        component: 'PropertyRoomsSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0507',
        label: 'Email Attachments',
        component: 'EmailAttachmentsSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0508',
        label: 'OGP Attachments',
        component: 'OGPAttachmentsSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      // {
      //   id: '0509',
      //   label: 'QR Codes',
      //   component: 'QRCodesSubView',
      //   roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      // },
      {
        id: '0510',
        label: 'Sources',
        component: 'SourcesSubView',
        roles: ['HoTEs', 'SU', 'MT', 'TPSM']
      },
      {
        id: '0511',
        label: 'Third parties',
        component: 'ThirdPartiesSubView'
      },
      {
        id: '0512',
        label: 'Users and roles',
        component: 'UsersRolesSubView'
      }
    ]
  }, {
    id: '06',
    label: 'Tasks List'
  }]
export default class SideBarMenu {
  // constructor (items = null) {
  //   items = items || defaultItems
  //   this._items = []
  //   for (const item of items) {
  //     this._items.push(new SideBarMenuItem(item))
  //   }
  // }
  static selectItemByName (name) {
    try {
      let item = SideBarMenu.getItems().find(
        item => item.name.toLowerCase() === name.toLowerCase() ||
          (item.subItems && item.subItems.find(subItem => subItem.name.toLowerCase() === name.toLowerCase()))
      )
      if (item && item.name.toLowerCase() !== name.toLowerCase()) {
        item = item.subItems.find(subItem => subItem.name.toLowerCase() === name.toLowerCase())
      }
      if (item) {
        item.select()
      }
    } catch (error) {
      console.error(error)
    }
  }

  static generateAndStore () {
    const items = []
    for (const item of defaultItems) {
      const sideBarMenuItem = item.constructor === SideBarMenuItem ? item : new SideBarMenuItem(item)
      if (sideBarMenuItem.canBeAddedToMenu()) {
        if (sideBarMenuItem.hasSubmenu()) {
          const subItems = []
          for (const sideBarMenuSubItem of sideBarMenuItem.subItems) {
            if (sideBarMenuSubItem.canBeAddedToMenu()) {
              subItems.push(sideBarMenuSubItem)
            }
          }
          sideBarMenuItem.subItems = subItems
        }
        items.push(sideBarMenuItem)
      }
    }
    store.commit('setMenu', items)
  }

  static getItemById (id) { // only top level
    return SideBarMenu.getItems().find(mi => mi.id === id)
  }

  static getItems () {
    const menuItems = store.state.settings.menuItems
    if (menuItems && menuItems.length > 0) {
      if (menuItems[0].constructor !== SideBarMenuItem) {
        const myMenuItems = []
        for (const menuItem of menuItems) {
          myMenuItems.push(new SideBarMenuItem(menuItem))
        }
        store.commit('setMenu', myMenuItems)
        return myMenuItems
      }
      return menuItems
    } else {
      SideBarMenu.generateAndStore()
      return store.state.settings.menuItems || []
    }
  }

  static getParentItemByChildId (childId) {
    try {
      return SideBarMenu.getItemById(childId.substring(0, 2))
    } catch (error) { }
  }

  // Get selected MenuItem from store
  static selectedMenuItem () {
    const menuItem = store.state.settings.selectedMenuItem
    if (menuItem && menuItem.constructor !== SideBarMenuItem) {
      const myMenuItem = new SideBarMenuItem(menuItem)
      myMenuItem.select()
      return myMenuItem
    }
    return menuItem
  }

  static additionalItemId () {
    return defaultItems.find(di => di.label === 'Additional').id
  }

  static enquiriesItemId () {
    return defaultItems.find(di => di.label === 'Enquiries').id
  }

  static guestsItemId () {
    return defaultItems.find(di => di.label === 'Guests').id
  }

  static responsesItemId () {
    return defaultItems.find(di => di.label === 'Responses').id
  }

  static tasksListItemId () {
    return defaultItems.find(di => di.label === 'Tasks List').id
  }
}
