export default axios => ({
  autosave: itin => axios.post('itinerary/temp', itin),
  getAutosaved: async enquiryId => {
    const { data } = await axios.get('itinerary/temp', { params: { enquiryId } })
    return data
  },
  get: enqId => axios.get('itinerary/' + enqId),
  getIcons: () => axios.get('itinerary/icons'),
  getPdf: async id => {
    try {
      const { data } = await axios.get('itinerary/pdf/' + id, {
        headers: {
          Accept: 'application/pdf'
        },
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', id + '.pdf')
      document.body.appendChild(link)
      link.click()
      setTimeout(() => {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }, 0)
    } catch (error) {
      console.error(error)
    }
  },
  save: data => axios.post('itinerary', data)
})
