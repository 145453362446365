import DateTime from './DateTime'
import Staff from './Staff'

export default class EnquiryStatus {
  constructor (status) {
    this.id = status.id
    this.deleted = !!status.deleted
    this.archived = !!status.archived
    this._date = status._date || status.date
    this.reason = status.reason
    this.details = status.details
    this._deletedAt = status._deletedAt || status.deletedAt
    this.user = new Staff(status.user)
  }

  get active () {
    return !this._deletedAt
  }

  get date () {
    return new DateTime(this._date)
  }

  get deletedAt () {
    return new DateTime(this._deletedAt)
  }

  get restored () {
    return !this.deleted && !this.archived
  }

  get status () {
    return this.deleted ? 'Deleted' : this.archived ? 'Archived' : 'Restored'
  }

  get text () {
    return `${this.date.short} | ${this.status}`
  }
}
