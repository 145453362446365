import DateTime from './DateTime'
import User from './MyUser'
import Staff from './Staff'
import TravelExecutive from './TravelExecutive'

export default class EnquiryAssignment {
  constructor (obj) {
    this.id = obj.id
    this._handlerId = obj._handlerId || obj.handlerId
    this._date = obj._date || obj.date
    this.reason = obj.reason
    this.user = new Staff(obj.user)
  }

  get assigned () {
    return !!this._handlerId
  }

  get date () {
    return new DateTime(this._date)
  }

  get handler () {
    return TravelExecutive.byId(this._handlerId)
  }

  get status () {
    return this.assigned ? 'Assigned' : 'Unassigned'
  }

  get text () {
    const user = User.current()
    let to = ''
    if (this.assigned) {
      to = `to ${user.id === this._handlerId ? 'you' : this.handler.name} `
    }
    return `${this.date.short} | ${this.status} ${to}`
  }
}
