import moment from 'moment'
import Vue from 'vue'
import Category from '../../classes/Category'
import Country from '../../classes/Country'
import Enquiry from '../../classes/Enquiry'
import EnquirySource from '../../classes/EnquirySource'

const state = {
  categories: null,
  countries: null,
  enquiry: null,
  enquirySources: null,
  itineraryIcons: null,
  selectedEnquiryCard: null,
  selectedId: null
}

const mutations = {
  clearEnquiryState (state) {
    for (const k of Object.keys(state)) {
      Vue.set(state, k, null)
    }
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setCountries (state, countries) {
    if (!state.countries) {
      state.countries = countries
    } else {
      for (const country of countries) {
        const index = state.countries.findIndex(c => c.cd === country.cd)
        if (index) {
          state.countries.splice(index, 1, country)
        } else {
          state.countries.push(country)
        }
      }
    }
    Country.sort(state.countries)
  },
  setDelivered (state, id) {
    const q = state.enquiry.quote
    if (q.id === id) {
      q.sentAt = moment().format('YYYY-MM-DD')
    }
  },
  setEnquirySources (state, enquirySources) {
    state.enquirySources = enquirySources
  },
  setEnquiry (state, enquiry) {
    state.enquiry = enquiry
  },
  setItineraryIcons (state, itineraryIcons) {
    state.itineraryIcons = itineraryIcons
  },
  setSelectedEnquiryCard (state, enquiry = null) {
    state.selectedEnquiryCard = enquiry
  },
  setSelectedEnquiryId (state, enquiryId) {
    state.selectedId = enquiryId
  },
  updateEnquiryAssigned (state, data) {
    if (data.stateVar) {
      const enqs = state[data.stateVar]
      const enq = enqs[data.enqId]
      if (enq) {
        enq.assignedByName = state.user.name
        enq.te_id = data.te.id
        enq.te_name = data.te.name
        enq.date_assigned = moment().toDate()
      }
    }
  }
}

const actions = {
  clearSearchedTopBar ({ commit }) {
    commit('clearSearchedTopBar')
  },
  setEnquiry ({ commit }, enq) {
    commit('setEnquiry', enq)
  },
  setSearchedTopBar ({ commit }, data) {
    commit('setSearchedTopBar', data)
  }
}

const getters = {
  categories: state => Category.parseMultipleFromStore(state.categories),
  // conciergeDesks: state => state.conciergeDesks,
  countries: state => Country.parseMultipleFromStore(state.countries),
  enquirySources: state => EnquirySource.parseMultipleFromStore(state.enquirySources),
  enquiry: state => Enquiry.parseFromStore(state.enquiry)
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters
}
