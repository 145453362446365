import Amount from './Amount'
import DateTime from './DateTime'
import Property from './Property'
import QuoteElementRoomDiscount from './QuoteElementRoomDiscount'
import QuoteElementRoomKid from './QuoteElementRoomKid'
import RoomType from './RoomType'
import QuoteElementRoomTreeHouse from './QuoteElementRoomTreeHouse'

export default class QuoteElementRoom {
  constructor (obj, lodge, currency) { // if not from store, then it's from API
    this.currency = currency
    this.lodge = lodge
    this.treeHouse = new QuoteElementRoomTreeHouse()
    if (obj) {
      this.id = obj.id
      this.adults = obj.adults
      this.children = obj._children || obj.children
      this.infants = obj.infants
      this.totalShow = obj.totalShow !== false
      this.chargeType = obj.chargeType
      if (!this.chargeType) {
        this.resetChargeType()
      }
      this.rate = obj.rate
      this._rateAuto = obj._rateAuto || (isNaN(obj.rateAuto) ? 0 : obj.rateAuto) || 0

      if (obj.roomType) {
        if (obj.roomType.id) {
          this.roomType = RoomType.byId(obj.roomType.id, lodge.roomTypes)
        } else {
          this.roomType = new RoomType(obj.roomType)
        }
      }
      if (obj.kidsAges) {
        this.kidsAges = []
        for (const kidsAge of obj.kidsAges) {
          this.kidsAges.push(new QuoteElementRoomKid(kidsAge, currency))
        }
      }
      if (obj.treehouse || obj.treeHouse) {
        this.treeHouse = new QuoteElementRoomTreeHouse(
          obj.treehouse || obj.treeHouse,
          currency
        )
      }
      this.discount = new QuoteElementRoomDiscount(obj.discount, currency)
    } else { // new room
      this.id = null
      this.adults = 0
      this.children = 0
      this.infants = 0
      this.totalShow = true
      this.rate = 0
      this._rateAuto = 0
      this.roomType = lodge?.hasOnlyOneRoomType() || null
      this.resetChargeType()
      this.kidsAges = []
      this.discount = new QuoteElementRoomDiscount(null, currency)
    }
  }

  get children () {
    return this._children
  }

  set children (numChildren) {
    this._children = numChildren
    this.kidsAges = this.kidsAges || []
    if (this._children < this.kidsAges.length) {
      this.kidsAges.splice(
        this._children,
        this.kidsAges.length - this._children
      )
    } else if (this._children > this.kidsAges.length) {
      const num = this._children - this.kidsAges.length
      for (let i = 0; i < num; i++) {
        this.kidsAges.push(new QuoteElementRoomKid(null, this.currency))
      }
    }
  }

  get childrenOver12 () {
    return (this.kidsAges || []).filter(ka => ka.age >= 12).length
  }

  get childrenUnder2 () {
    return (this.kidsAges || []).filter(ka => ka.age < 2).length
  }

  get childrenOver2 () {
    return this.children - this.childrenUnder2
  }

  get kidsAgesString () {
    let kidsAges = ''
    if (this.children > 0) {
      if (this.kidsAges?.length > 0) {
        kidsAges = this.kidsAges
          .map((ka) => ka.age)
          .filter((age) => age >= 0)
          .sort()
          .join(', ')
      }
    }
    return kidsAges
  }

  get rate () {
    return new Amount(this._rate, this.currency)
  }

  set rate (value) {
    this._rate = Amount.parseValue(value)
  }

  get rateAuto () {
    return new Amount(this._rateAuto, this.currency)
  }

  get rateLabel () {
    return `Rate (${
        this.chargeType === 'pn'
          ? 'pn'
          : this.chargeType === 'ps'
          ? 'total'
          : this.chargeType === 'pppn' || !this.chargeType
          ? 'pppn'
          : ''
      })`
  }

  changeCurrency (newCurrency) {
    this.currency = newCurrency
    this.discount.changeCurrency(newCurrency)
    for (const kidAge of this.kidsAges) {
      kidAge.changeCurrency(newCurrency)
    }
    this.treeHouse?.changeCurrency(newCurrency)
  }

  childRate (j) {
    this.kidsAges[j].rateTxt = this.kidsAges[j].rateTxt || this.kidsAges[j].rate.amount.toString()
    const childrenRatesTxt = this.kidsAges[j].rateTxt
    let val = 0
    if (childrenRatesTxt.includes('%')) {
      try {
        val = parseFloat(childrenRatesTxt.replace('%', ''))
        // this.kidsAges[j].perc = val + '%'
        this.kidsAges[j].rate = Amount.fromPercentage(this.rate, val)
      } catch (e) {
        this.kidsAges[j].rateTxt = '0'
        this.kidsAges[j].rate = 0
        // this.kidsAges[j].perc = null
      }
    } else {
      try {
        val = parseFloat(childrenRatesTxt)
        this.kidsAges[j].rate = val
        // this.kidsAges[j].perc = Amount.percentageString(this.rate, this.kidsAges[j].rate)
      } catch (e) {
        this.kidsAges[j].rateTxt = '0'
        this.kidsAges[j].rate = 0
        // this.kidsAges[j].perc = null
      }
    }
  }

  clearTreeHouse () {
    this.treeHouse.clear()
  }

  defaultChargeType () {
    if (this.lodge?.id) {
      const property = Property.byLodgeId(this.lodge.id)
      return this.roomType
        ? this.roomType.hasOnlyOneChargeType()
        : this.lodge.hasOnlyOneChargeType() ||
        property.hasOnlyOneChargeType() ||
        null
    } else {
      return null
    }
  }

  hasDiscount () {
    return this.discount?.discount
  }

  hasDiscountPercentage () {
    return this.hasDiscount() && this.discount.type === 'percentage'
  }

  resetChargeType () {
    this.chargeType = this.defaultChargeType()?.short || 'pppn'
  }

  selectRoomType (roomType, adults, children) {
    this.roomType = roomType
    if (this.roomType?.id) {
      this.roomType.name = this.roomTypes.find(
        rt => rt.id === this.roomType.id
      ).name
    }
    if (!this.adults && adults) {
      this.adults = adults
      this.children = children
    }
  }

  selectTreeHouse (dateIn) {
    if (this.treeHouse) {
      this.treeHouse.dateIn = new DateTime(dateIn)
    }
  }

  totalAmount (nights) {
    const totalAmountChildren = this.totalAmountChildren(nights)?.amount || 0
    let total = 0
    if (this.discount && this.discount.discount === 'bride' && this.adults === 2) {
      this.discount._amount = 0.2 * nights * this.rate.amount
      total = 1.8 * nights * this.rate.amount + totalAmountChildren
    } else {
      if (this.chargeType === 'pn') {
        total = nights * this._rate
      } else if (this.chargeType === 'ps') {
        total = this.rate.amount
      } else {
        total = this.adults * nights * this.rate.amount + totalAmountChildren
      }
      total -= this.discount.getDiscountAmount(total).amount
    }
    if (this.treeHouse) {
      total += this.treeHouse.getTotalDiscounted(this.adults, this.childrenOver2, this.discount)?.amount || 0
    }
    return new Amount(total, this.currency)
  }

  totalAmountChildren (nights) {
    let total = 0
    if (this.kidsAges) {
      for (const kidAge of this.kidsAges) {
        total += nights * kidAge.rate.amount
      }
    }
    return new Amount(total, this.currency)
  }

  treeHouseSelected () {
    return !!this.treeHouse?.name
  }

  static fromEnquiry (lodge, roomType, currency, adults, children, childrenAges) {
    const room = new QuoteElementRoom(null, lodge, currency, false)
    if (!room.roomType && roomType) {
      room.roomType = roomType
    }
    room.adults = adults
    room.children = children
    for (const [index, childAge] of (childrenAges || []).entries()) {
      if (room.kidsAges.length >= index + 1) {
        room.kidsAges[index].age = childAge
      }
    }
    room.resetChargeType()
    return room
  }

  static fromAutosave (data, lodge) {
    const room = new QuoteElementRoom()
    for (const k of Object.keys(data)) {
      room[k] = data[k]
    }
    room.treeHouse = QuoteElementRoomTreeHouse.fromAutosave(data.treeHouse)
    if (data.kidsAges?.length > 0) {
      room.kidsAges = []
      for (const kidsAge of data.kidsAges) {
        room.kidsAges.push(QuoteElementRoomKid.fromAutosave(kidsAge))
      }
    }
    if (data.roomType?.id) {
      if (lodge) {
        room.roomType = RoomType.byId(data.roomType.id, lodge.roomTypes)
      } else {
        room.roomType = new RoomType(data.roomType)
      }
    }
    room.discount = QuoteElementRoomDiscount.fromAutosave(data.discount)
    return room
  }

  static objectToSave (room, nights) {
    return {
      roomType: {
        id: room.roomType?.id,
        name: room.roomType?.name
      },
      adults: room.adults,
      children: room.children,
      rate: room.rate?.amount || 0,
      chargeType: room.chargeType,
      total: room.totalAmount(nights)?.amount || 0,
      totalShow: room.totalShow,
      discount: QuoteElementRoomDiscount.objectToSave(room.discount),
      kidsAges: (room.kidsAges || []).map(ka => QuoteElementRoomKid.objectToSave(ka)),
      treehouse: QuoteElementRoomTreeHouse.objectToSave(room.treeHouse, room.treeHouse?.getTotalDiscounted(room.adults, room.childrenOver2, room.discount))
    }
  }
}
