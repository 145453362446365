export default class Amount {
  constructor (amount, currency = 'ZAR') {
    currency = currency || 'ZAR'
    if (amount && typeof amount === 'object') {
      this.amount = amount.amount
      this.currency = amount.currency || currency
    } else {
      this.amount = isNaN(amount) ? 0 : parseFloat(amount || 0)
      this.currency = currency
    }
  }

  get amount () {
    return this._amount
  }

  set amount (value) {
    this._amount = Math.round((isNaN(value) ? 0 : value || 0) * 100) / 100
  }

  changeCurrency (newCurrency) {
    if (newCurrency) {
      this.currency = newCurrency
    }
  }

  toString () {
    return new Intl.NumberFormat('en-UK', {
      style: 'currency',
      currency: this.currency || 'ZAR'
    }).format(this.amount)
  }

  static fromPercentage (totalAmount, percentage) {
    return new Amount(Math.round(totalAmount.amount * percentage) / 100, totalAmount.currency)
  }

  static parseValue (value) {
    if (isNaN(value)) {
      if (typeof value === 'object') {
        return value.amount
      } else {
        return 0
      }
    } else {
      return parseFloat(value)
    }
  }

  static percentageString (totalAmount, amount) {
    return (Math.round(
      (amount.amount * 10000) / totalAmount.amount
    ) / 100
    ).toFixed(2) + '%'
  }

  static sum (amounts) {
    const currency = amounts?.length > 0 ? amounts[0].currency : null
    const total = amounts.reduce(
      (previousValue, currentValue) => previousValue + currentValue.amount,
      0
    )
    return new Amount(total, currency)
  }
}
