export default axios => ({
  dismiss: notificationId => axios.put('notification/' + notificationId + '/dismiss'),
  all: async limit => {
    let api = 'notifications'
    if (limit > 0) {
      api += '?limit=' + limit
    }
    return axios.get(api)
  },
  setViewed: () => axios.put('notifications/view')
})
