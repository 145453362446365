import Amount from './Amount'
import DateTime from './DateTime'

export default class QuotePayment {
  constructor (obj = null, currency = 'ZAR') { // if not from store, then it's from API
    this.amount = 0
    this._date = DateTime.today().dateOnlyDB
    this.currency = currency
    this.paid = false
    if (obj) {
      this.id = obj.id
      this._date = obj._date || obj.date
      this.amount = obj._amount || obj.amount || 0
      this.currency = obj.currency || currency
      this.paid = obj.paid
    }
  }

  get amount () {
    return new Amount(this._amount, this.currency)
  }

  set amount (value) {
    this._amount = Amount.parseValue(value)
  }

  get amountTxt () {
    return (this._amountTxt || this._amount || 0).toString()
  }

  set amountTxt (txt) {
    this._amountTxt = txt
  }

  get date () {
    return new DateTime(this._date)
  }

  set date (d) {
    this._date = DateTime.startOfDay(d).dateOnlyDB
  }

  isPercentage () {
    return this.amountTxt.includes('%')
  }

  percentage (totalAmount) {
    let amount
    if (totalAmount.constructor === Amount) {
      amount = totalAmount.amount
    } else {
      amount = totalAmount
    }
    const percentage = Math.round(100 * this._amount / amount)
    return `${percentage}%`
  }

  setAmountFromText (txt, total) {
    this.amountTxt = txt
    let val = 0
    try {
      if (this.isPercentage()) {
        val = parseFloat(txt.replace('%', ''))
        this.amount = Amount.fromPercentage(total, val)
      } else {
        val = parseFloat(txt)
        this.amount = val
      }
    } catch (e) {
      this.amount = 0
    }
  }

  static fromAutosave (data) {
    return new QuotePayment(data, data.currency)
  }

  static objectToSave (payment) {
    return {
      amount: payment.amount?.amount || 0,
      date: payment.date.dateOnlyDB
    }
  }
}
