import store from '../store'
import Vue from 'vue'

export default class Gallery {
  constructor (id = null, name, propertyId = null, lodgeId = null, categoryId = null, subcategoryId = null) {
    this.id = id
    this.name = name
    this.propertyId = propertyId
    this.lodgeId = lodgeId
    this.categoryId = categoryId
    this.subcategoryId = subcategoryId
  }

  static byPropertyId (propertyId) {
    return store.getters.gallery.filter(img => img.propertyId === propertyId)
  }

  // img, propertyId, lodgeId, categories [{id, scId}]
  static copy (data) {
    return Vue.$api.gallery.copyImage(data)
  }

  static filtered (property, lodge, category, subCategory) {
    const gallery = store.getters.gallery.length > 0 ? store.getters.gallery : null
    let filteredImages = []
    if (gallery) {
      filteredImages = gallery.filter(img => img.propertyId === property.id)
      if (lodge && !property.hasOnlyOneLodge()) {
        filteredImages = filteredImages.filter(img => img.lodgeId === lodge.id)
      } else {
        filteredImages = filteredImages.filter(img => !img.lodgeId || (property.hasOnlyOneLodge() && img.lodgeId === property.hasOnlyOneLodge()))
      }
      if (category) {
        filteredImages = filteredImages.filter(img => img.categoryId === category.id)
        if (subCategory) {
          filteredImages = filteredImages.filter(img => img.subcategoryId === subCategory.id)
        } else {
          filteredImages = filteredImages.filter(img => !img.subcategoryId)
        }
      } else {
        filteredImages = filteredImages.filter(img => !img.categoryId)
      }
    }
    return filteredImages
  }

  static firstForProperty (propertyId) {
    if (store.getters.gallery.length > 0) {
      return store.getters.gallery.find(img => img.propertyId === propertyId)
    }
  }

  static imgLink (img) {
    if (img) {
      return store.getters.serverMedia + 'images/small_landscape/' + img
    } else {
      return ''
    }
  }

  static imgLargeLink (img) {
    if (img) {
      return store.getters.serverMedia + 'images/gallery/' + img
    } else {
      return ''
    }
  }

  static async loadAll (reload = false) {
    try {
      if (reload || store.getters.gallery.length === 0) {
        const { data } = await Vue.$api.gallery.get()
        store.commit('setGallery', Gallery.parseMultipleFromStore(data))
      }
      return true
    } catch (error) {
      return false
    }
  }

  static parseMultipleFromStore (objs) {
    if (objs && objs[0].constructor !== Gallery) {
      const gallery = []
      for (const obj of objs) {
        const { id, name, propertyId, lodgeId, categoryId, subcategoryId } = obj
        gallery.push(new Gallery(id, name, propertyId, lodgeId, categoryId, subcategoryId))
      }
      return gallery
    }
    return objs || []
  }

  static remove (id) {
    return Vue.$api.gallery.removeImage(id)
  }
}
