import Gallery from '../../classes/Gallery'
const state = {
  gallery: null
}

const mutations = {
  clearGalleryState (state) {
    state.gallery = null
  },
  setGallery (state, gallery) {
    state.gallery = gallery
  }
}

const getters = {
  gallery: state => Gallery.parseMultipleFromStore(state.gallery)
}

export default {
  state,
  mutations,
  getters
}
