export default axios => ({
  autosave: q => axios.post('quote/temp', q),
  createPdf: ids => axios.post('quote/pdf', { ids }),
  deleteAttachment: id => axios.delete(`attachments/${id}`),
  getAttachment: id => axios.get(`attachments/${id}`),
  getAttachments: () => axios.get('attachments'),
  getAutosaved: enquiryId => axios.get('quote/temp', { params: { enquiryId } }),
  getDefaultInfo: () => axios.get('quote/info'),
  getById: id => axios.get(`quote/${id}`),
  getPdf: async id => {
    const { data } = await axios.get('quote/pdf/' + id, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', id + '.pdf')
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    }, 0)
    return true
  },
  getPdfRaw: id =>
    axios.get('quote/pdf/' + id, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    }),
  getByEnquiryId: enqId => axios.get(`quotes/${enqId}`),
  save: quote => axios.post('quote', quote),
  send: formData =>
    axios.post('quote/send', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      }
    }),
  setDelivered: data => axios.post(`quote/delivered/${data.id}`, data),
  setPaid: paymentId => axios.put(`quote/payment/${paymentId}`),
  toggleAttachmentDefault: id => axios.post(`attachments/${id}/default`),
  updateDefaultInfo: info => axios.post('quote/info', info),
  uploadAttachments: formData =>
    axios.post('attachments', formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      }
    })
})
