import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
import Category from './Category'
import Country from './Country'
import DateTime from './DateTime'
import EnquiryAccommodation from './EnquiryAccommodation'
import EnquiryAssignment from './EnquiryAssignment'
import EnquiryFirstReply from './EnquiryFirstReply'
import EnquirySource from './EnquirySource'
import EnquiryStatus from './EnquiryStatus'
import Guest from './Guest'
import Quote from './Quote'
import Staff from './Staff'
import TravelExecutive from './TravelExecutive'

const newEnquiryDetails = {
  id: null,
  name: null,
  surname: null,
  email: null,
  tel: null,
  comments: null,
  countryCd: null,
  adults: 0,
  children: 0,
  childrenAges: [],
  communicate: 'Email',
  category: null,
  subcategory: null,
  properties: [], // [{property, lodge, roomType}]
  dateStart: null,
  dateEnd: null,
  monthStart: null,
  monthEnd: null,
  noNights: null,
  site: null,
  sourceOption: null,
  sourceOptionDetails: null,
  conciergeDeskId: null,
  transport: null,
  treeHouses: false,
  tours: false,
  news: false
}
const transportOptions = ['Fly', 'Drive', 'Self Drive', 'Transfer']
export default class Enquiry {
  constructor () {
    for (const [k, v] of Object.entries(newEnquiryDetails)) {
      this[k] = Array.isArray(v) ? [] : v
    }
  }

  get audit () {
    const initArray = [{
      _date: this._createdAt,
      date: this.createdAt,
      text: `${this.createdAt.short} | Enquiry`,
      user: this.creator
    }]
    if (this.firstReply) {
      initArray.push({
        _date: this.firstReply.date.d,
        date: this.firstReply.date,
        text: `${this.firstReply.date.short} | Contacted`,
        user: this.firstReply.handler
      })
    }
    return [
      ...initArray,
      ...this.statuses,
      ...this.assignments,
      ...this.quotes.map(q => {
        const _date = q._dateSent || q._dateDelivered || q._date
        const date = new DateTime(_date)
        const status = !q.dateSent.isValid() ? 'Saved' : (q.isConfirmation ? 'Confirmed' : (q.isQuote ? 'Quoted' : 'Responded'))
        const text = `${date.short} | ${status} by ${q.handler?.name}`
        return {
          quoteId: q.id,
          _date,
          date,
          text,
          opened: q.opened,
          user: q.handler
        }
      })
    ].sort((a, b) => (DateTime.isBefore(a.date, b.date) ? -1 : 1))
  }

  get children () {
    return this._children
  }

  set children (children) {
    if (!this.childrenAges) {
      this.childrenAges = []
    }
    const diff = children - this.childrenAges.length
    if (diff > 0) {
      for (let i = 0; i < diff; i++) this.childrenAges.push(null)
    } else if (diff < 0) {
      this.childrenAges.splice(children, -diff)
    }
    this._children = children
  }

  get childrenAgesString () {
    let str = (this.childrenAges || []).join(', ')
    if (str) {
      str = `[${str}]`
    }
    return str
  }

  get conciergeDesk () {
    return EnquirySource.getById(this.conciergeDeskId)
  }

  get country () {
    return Country.byCd(this.countryCd)
  }

  get createdAt () {
    return new DateTime(this._createdAt)
  }

  get fullName () {
    return `${this.surname ? this.surname : ''}${(this.surname && this.name ? ' ' : '')}${this.name ? this.name : ''}`
  }

  get lastTotalAmount () {
    return Quote.getLastTotalAmount(this.quotes)
  }

  get latestEmail () {
    return Quote.getLatestEmail(this.quotes)
  }

  get latestQuote () {
    return Quote.getLatestQuote(this.quotes)
  }

  get periodString () {
    const df = new DateTime(this.dateStart)
    const dt = new DateTime(this.dateEnd)
    if (df.isValid()) {
      if (dt.isValid()) {
        return `from ${df.dateOnly} to ${dt.dateOnly}`
      } else {
        return `from ${df.dateOnly}`
      }
    } else {
      if (dt.isValid()) {
        return `before ${dt.dateOnly}`
      } else {
        const mf = new DateTime(this.monthStart)
        const mt = new DateTime(this.monthEnd)

        if (mf.isValid()) {
          if (mt.isValid() && mf.moment.month !== mt.moment.month) {
            return `between ${mf.monthYearOnly} and ${mt.monthYearOnly}`
          } else {
            return `in ${mf.monthYearOnly}`
          }
        } else {
          return 'dates not selected'
        }
      }
    }
  }

  get source () {
    return EnquirySource.getById(this.sourceId)
  }

  assignToHandler (teId, reason = null) {
    return Vue.$api.enquiry.assign({
      enqId: this.enquiryId || this.id,
      teId,
      reason
    })
  }

  changeStatus (action, reason) {
    return Vue.$api.enquiry.changeStatus(this.id, reason, action)
  }

  create (periodIsMonth = false, guests = [], sendAutoResponse = true) {
    const details = { ...this }
    details.children = this.children
    delete details._children

    if (periodIsMonth) {
      details.dateStart = null
      details.dateEnd = null
      try {
        if (details.monthStart.length > 7) {
          details.monthStart = moment(details.monthStart).startOf('month').format('YYYY-MM-DD')
          details.monthEnd = moment(details.monthEnd).endOf('month').format('YYYY-MM-DD')
        } else {
          details.monthStart = `${details.monthStart}-01`
          details.monthEnd = moment(`${details.monthEnd}-01`).endOf('month').format('YYYY-MM-DD')
        }
      } catch (error) {}
    } else {
      details.monthEnd = null
      details.monthStart = null
    }

    if (guests.length > 0) {
      details.guests = guests
    }

    details.treehouses = details.treeHouses
    delete details.treeHouses

    const accommodations = []
    for (const accommodation of details.properties) {
      accommodations.push({
        id: accommodation.property.id,
        lodgeId: accommodation.lodge?.id || null,
        roomTypeId: accommodation.roomType?.id || null
      })
    }
    details.properties = accommodations

    if (details.site) {
      details.sourceId = details.site.id
      if (details.site.value === 'MPTCD') {
        details.conciergeDeskId = details.sourceOption?.id || details.site.id
        details.sourceId = details.conciergeDeskId
      } else {
        if (details.sourceOption) {
          details.sourceOption = details.sourceOption.name
        }
      }
      details.site = details.site.value
    }

    details.categoryId = details.category?.id || null
    delete details.category
    details.subcategoryId = details.subcategory?.id || null
    delete details.subcategory
    details.sendAutoResponse = sendAutoResponse
    return Vue.$api.enquiry.create(details)
  }

  async loadDetails () {
    try {
      await Category.loadAll()
      const details = await Vue.$api.enquiry.getById({ id: this.id })
      this._createdAt = details.createdAt // detail
      this.createdBy = new Staff(details.creator) // detail

      this.adults = details.adults
      this.agent = details.agent // detail [bool]
      this.assistance = details.assistance // detail [bool || null]
      this.category = Category.byId(details.categoryId)
      this.subcategory = details.subcategoryId && this.category.subcategories ? Category.byId(details.subcategoryId, this.category.subcategories) : null
      this.children = details.children
      this.childrenAges = details.childrenAges
      this.comments = details.comments
      this.communicate = details.communicate
      this.community = details.community // detail [bool || null]
      this.conciergeDeskId = details.conciergeDeskId
      this.countryCd = details.countryCd
      this.dateStart = details.dateStart
      this.dateEnd = details.dateEnd
      this.destinations = details.destinations || []
      this.experiences = details.experiences || []
      this.image = details.img1 // detail
      this.monthStart = details.monthStart
      this.monthEnd = details.monthEnd
      this.news = details.news
      this.noNights = details.noNights
      this.promoCode = details.promoCode
      this.properties = (details.properties || []).map(a => new EnquiryAccommodation(a))
      this.site = details.site
      this._sourceId = details.sourceId
      this.sourceOption = details.sourceOption
      this.sourceOptionDetails = details.sourceOptionDetails
      this.tours = details.tours
      this.transport = details.transport
      this.travellerType = details.travellerType // detail
      this.treeHouses = details.treehouses
      this.url = details.url // detail

      const primaryGuest = (details.guests || []).find(g => g.primary)
      this.name = primaryGuest.name
      this.surname = primaryGuest.surname
      this.tel = primaryGuest.cell
      this.email = primaryGuest.email
      this.primaryGuestHandler = primaryGuest.handler ? TravelExecutive.byId(primaryGuest.handler.id) : null
      this.guests = (details.guests || []).map(guestObj => new Guest(guestObj)).sort(g => g.primary ? -1 : 1)

      this.assignments = (details.assignments || []).map(item => new EnquiryAssignment(item))
      this.statuses = (details.statuses || []).map(item => new EnquiryStatus(item))

      this.detailsLoaded = true

      this.quotes = (details.quotes || []).map(qObj => {
        return new Quote(qObj)
      })

      this.firstReply = details.firstReply ? new EnquiryFirstReply(details.firstReply) : null
      this.store()

      // for (const key of Object.keys(this.details)) {
      //   if (['dateEnd', 'dateStart'].includes(key)) {
      //     this.details[key + 'Orig'] = enq[key]
      //     this.details[key] = new DateTime(enq[key])
      //   } else if (key === 'accommodations' && enq.properties) {
      //     this.details.accommodations = []
      //     for (const p of enq.properties) {
      //       const property = Property.byId(p.property.id)
      //       if (property) {
      //         const accommodation = { property }
      //         if (p.lodge && property.lodges) {
      //           const lodge = Lodge.byId(p.lodge.id, property.lodges)
      //           if (lodge) {
      //             accommodation.lodge = lodge
      //             if (p.roomType && lodge.roomTypes) {
      //               const roomType = RoomType.byId(
      //                 p.roomType.id,
      //                 lodge.roomTypes
      //               )
      //               if (roomType) {
      //                 accommodation.roomType = roomType
      //               }
      //             }
      //           }
      //         }
      //         this.details.accommodations.push(accommodation)
      //       }
      //     }
      //   } else if (key === 'subcategory' && enq[key]) {
      //     const subcategory = Category.byId(enq[key].id, this.category.subcategories)
      //     if (subcategory) {
      //       this.details.subcategory = subcategory
      //     }
      //   } else if (key === 'childrenAges' && enq[key]) {
      //     this.details[key] = []
      //     for (const c of enq[key]) {
      //       this.details[key].push(c.age)
      //     }
      //   } else {
      //     this.details[key] = enq[key]
      //   }
      // }
      // this.details.period = this.generatePeriod()
    } catch (error) {
      console.error(error)
    }
  }

  periodIsMonth () {
    return !!this.monthStart
  }

  populateFromNewInterest (newInterest) {
    this.email = newInterest.email
    this.name = newInterest.name
    this.surname = newInterest.surname
    if (newInterest.property && newInterest.property.id) {
      this.properties[0].property = newInterest.property
    }
    this.comments = newInterest.conversation
  }

  removeGuest (guestId) {
    return Vue.$api.guest.delete(
      guestId, this.id
    )
  }

  async setContacted (dateTime, reason, teId) {
    try {
      await Vue.$api.enquiry.setContacted({ dateTime: dateTime.d, reason, teId, enquiryId: this.id })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  setPrimaryGuest (guestId) {
    return Vue.$api.enquiry.setPrimaryGuest(
      this.id,
      guestId
    )
  }

  store () {
    store.commit('setEnquiry', this)
  }

  static clearStore () {
    store.commit('setEnquiry', null)
  }

  static getTransportOptions () {
    return transportOptions
  }

  // KEEP FOR NOW
  // static async loadFull (id) {
  //   try {
  //     const enq = await Vue.$api.enquiry.getById({ id })
  //     return new Enquiry(enq)
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }

  static parseFromStore (enquiry) {
    if (enquiry && enquiry.constructor !== Enquiry) {
      return new Enquiry(enquiry)
    }
    return enquiry
  }
}
