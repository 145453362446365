import Amount from './Amount'

export default class QuoteElementRoomDiscount {
  constructor (obj, currency) {
    this.currency = currency

    if (obj) {
      this.label = obj.label
      this._amount = obj._amount || (isNaN(obj.amount) ? 0 : obj.amount) || 0
      this.description = obj.description
      this.discount = obj.discount
      this.id = obj.id
      this.quoteEmailElementRoomTypeId = obj.quoteEmailElementRoomTypeId
      this.type = obj.type || 'amount'
      this._txt = obj._txt || ''

      if (!this._txt) {
        if (this.type === 'percentage' && this._amount > 0) {
          this._txt = `${this._amount}%`
        } else {
          this._txt = this._amount
        }
      }
    } else {
      this._txt = ''
      this.label = null
      this._amount = 0
      this.description = null
      this.discount = null
      this.id = null
      this.quoteEmailElementRoomTypeId = null
      this.type = 'amount'
    }
  }

  get amount () {
    if (this.type === 'amount') {
      return new Amount(this._amount, this.currency)
    }
    return this._amount
  }

  get txt () {
    return this._txt
  }

  set txt (txt) {
    this._txt = txt
    this._amount = parseFloat(txt.replace('%', '')) || 0
    this.type = this.txt.indexOf('%') > 0 ? 'percentage' : 'amount'
  }

  changeCurrency (newCurrency) {
    this.currency = newCurrency
  }

  isPercentage () {
    return this.type === 'percentage' ? `${this._amount}%` : false
  }

  getDiscountAmount (amount) {
    if (amount.constructor === Amount) {
      amount = amount.amount
    }
    const discountValue = this.isPercentage() ? amount * this._amount / 100 : this._amount
    return new Amount(discountValue, this.currency)
  }

  static fromAutosave (data) {
    return new QuoteElementRoomDiscount(data, data.currency)
  }

  static objectToSave (discount) {
    return discount && ((discount.type === 'percentage' && discount.amount > 0) || (discount.type === 'amount' && discount.amount?.amount > 0))
      ? {
          discount: discount.discount,
          description: discount.description,
          type: discount.type,
          amount: (discount.type === 'percentage' ? discount.amount : discount.amount.amount) || 0
        }
      : null
  }

  static options (category, adults, currency, selectedOption) {
    const newOptions = [
    ]
    if (selectedOption?.discount) {
      selectedOption.label = selectedOption.discount === 'bride' ? 'Bride -20%' : 'Other'
      newOptions.push(selectedOption)
    }
    if (category.name === 'Wedding' && adults === 2) {
      const brideDiscount = new QuoteElementRoomDiscount({
        _amount: 20,
        type: 'percentage',
        _txt: '20%',
        label: 'Bride -20%',
        discount: 'bride'
      }, currency)
      brideDiscount.text = '20%'
      if (newOptions.findIndex(o => o.discount === 'bride') < 0) {
        newOptions.push(brideDiscount)
      }
    }
    if (newOptions.findIndex(o => o.discount === 'other') < 0) {
      newOptions.push(new QuoteElementRoomDiscount({
        label: 'Other',
        discount: 'other'
      }, currency))
    }
    return newOptions
  }
}
