import Amount from './Amount'

export default class QuoteElementRoomKid {
  constructor (obj, currency) { // if not from store, then it's from API
    this.currency = currency
    if (obj) {
      this.rate = obj.rate
      this.rateTxt = obj.rate?.constructor === Amount ? obj.rate.amount : obj.rate
      this.age = obj.age
      this.id = obj.id
      this.quoteEmailElementRoomTypeId = obj.quoteEmailElementRoomTypeId
    } else {
      this.rate = 0
      this.rateTxt = '0'
      this.age = 0
      this.id = null
      this.quoteEmailElementRoomTypeId = null
    }
  }

  get rate () {
    return new Amount(this._rate, this.currency)
  }

  set rate (value) {
    this._rate = Amount.parseValue(value)
  }

  changeCurrency (newCurrency) {
    this.currency = newCurrency
  }

  static fromAutosave (data) {
    return new QuoteElementRoomKid(data, data.currency)
  }

  static objectToSave (kidAge) {
    return {
      age: kidAge.age,
      rate: kidAge.rate?.amount || 0
    }
  }
}
