// fields to be automatically added to empty quotes
export const defaultFields = Object.freeze([
  'cancellationPolicy',
  'malaria',
  'moreCommunityTrust',
  'passport',
  'vaccinations',
  'popiAct'
])

export const visasZim = Object.freeze([
  {
    country: {
      cd: 'BW',
      name: 'Botswana'
    },
    text: '<p>Travellers who are holders of national passport from Botswana do not require a Visa to enter Zimbabwe for an intended stay of 90 days or less. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origins whether the regulations have been amended.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'ZA',
      name: 'South Africa'
    },
    text: '<p>Travellers who are holders of a national passport from South Africa does not require a visa to enter Zimbabwe and will receive a complimentary visa upon arrival.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'ZM',
      name: 'Zambia'
    },
    text: '<p>Travellers who are holders of national passport from Zambia do not require a Visa to enter Zimbabwe for an intended stay of 90 days or less. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origins whether the regulations have been amended.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'AU',
      name: 'Australia'
    },
    text: '<p>Travellers from Australia do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'FR',
      name: 'France'
    },
    text: '<p>Travellers from France do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'BE',
      name: 'Belgium'
    },
    text: '<p>Travellers from Belgium do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'DE',
      name: 'Germany'
    },
    text: '<p>Travellers from Germany do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'CA',
      name: 'Canada'
    },
    text: '<p>Travellers from Canada do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'GB',
      name: 'United Kingdom'
    },
    text: '<p>Travellers from the United Kingdom do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'US',
      name: 'United States'
    },
    text: '<p>Travellers from the United States of America do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'AE',
      name: 'United Arab Emirates'
    },
    text: '<p>Travellers from the United Arab Emirates do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'IT',
      name: 'Italy'
    },
    text: '<p>Travellers from Italy do not need to apply for a travel visa before their departure. A visa can be obtained upon arrival at the airport to gain entry to the country.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'QA',
      name: 'Qatar'
    },
    text: '<p>Travellers from Qatar need to obtain a visa before they arrive in Zimbabwe and need to ensure that all required documentation is in their possession upon arrival.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  },
  {
    country: {
      cd: 'CN',
      name: 'China'
    },
    text: '<p>Travellers from China need to obtain a visa before they arrive in Zimbabwe and need to ensure that all required documentation is in their possession upon arrival.</p><p><br></p><p>All Categories need:<ul><li>Passport valid for a least 6 months from your date of entry</li><li>Return ticket to your country (or enough money to buy one)</li><li>Sufficient funds to cover your stay in Zimbabwe</li><li>Enough blank pages in your passport to fit the required entry visa</li></ul></p>'
  }

])

export const visasSA = Object.freeze([
  {
    country: {
      cd: 'BW',
      name: 'Botswana'
    },
    text: '<p>Passport holders from Botswana do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'ZM',
      name: 'Zambia'
    },
    text: '<p>Zambian passport holders do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'ZW',
      name: 'Zimbabwe'
    },
    text: '<p>Passport holders from Zimbabwe do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'BR',
      name: 'Brazil'
    },
    text: '<p>Passport holders from Brazil do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'AU',
      name: 'Australia'
    },
    text: '<p>Passport holders from Australia do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'FR',
      name: 'France'
    },
    text: '<p>Passport holders from France do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'DE',
      name: 'Germany'
    },
    text: '<p>Passport holders from Germany do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'SE',
      name: 'Sweden'
    },
    text: '<p>Passport holders from Sweden do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'FI',
      name: 'Finland'
    },
    text: '<p>Passport holders from Finland do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'IS',
      name: 'Iceland'
    },
    text: '<p>Passport holders from Iceland do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'JP',
      name: 'Japan'
    },
    text: '<p>Passport holders from Japan do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'IE',
      name: 'Ireland'
    },
    text: '<p>Passport holders from Finland do not require a visa to enter South Africa for travel up to 90 days. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'GB',
      name: 'United Kingdom'
    },
    text: '<p>Passport holders from the United Kingdom do not require a visa to enter South Africa or travel for 90 days or less. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'US',
      name: 'United States'
    },
    text: '<p>Passport holders from the United States of America do not require a visa to enter South Africa for travel for 90 days or less. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  },
  {
    country: {
      cd: 'AE',
      name: 'United Arab Emirates'
    },
    text: '<p>Passport holders from the United Arab Emirates do not require a visa to enter South Africa for travel for 90 days or less. However, it remains the traveller’s sole responsibility to confirm with their relevant consulate office in their country of origin whether the regulations have been amended.</p>'
  }
])
