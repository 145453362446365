<template>
  <div id="app">
    <Login v-if="currentMainView === 'Login'" />
    <b-container
      v-if="currentMainView !== 'Login'"
      fluid
      class="p-0"
      ref="topPage"
    >
      <b-row no-gutters>
        <b-col cols="12" md="2">
          <LeftBar @search="findEnquiries" />
        </b-col>
        <b-col cols="12" md="10" class="d-none d-md-block right-col">
          <component v-bind:is="currentMainView" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import User from './classes/MyUser'
import SideBarMenu from './classes/SideBarMenu'
export default {
  name: 'app',
  components: {
    Additional: () =>
      import(/* webpackPrefetch: true */ '@/views/AdditionalView'),
    Dashboard: () =>
      import(/* webpackPrefetch: true */ '@/views/DashboardView'),
    Enquiries: () =>
      import(/* webpackPrefetch: true */ '@/views/EnquiriesView'),
    Responses: () =>
      import(/* webpackPrefetch: true */ '@/views/ResponsesView'),
    Guests: () => import(/* webpackPrefetch: true */ '@/views/GuestsView'),
    Login: () => import(/* webpackPrefetch: true */ '@/views/LoginView'),
    TasksList: () =>
      import(/* webpackPrefetch: true */ '@/views/TasksListView'),
    LeftBar: () =>
      import(/* webpackPrefetch: true */ '@/components/LeftBar.vue')
  },
  data () {
    return {
      currentMainView: null,
      user: null
    }
  },
  computed: {
    ...mapGetters(['selectedMenuItem'])
  },
  watch: {
    selectedMenuItem: {
      handler (newVal, oldVal) {
        this.setCurrentPage(newVal)
      },
      deep: true,
      immediate: true
    },
    user: {
      handler (newVal, oldVal) {
        if (!newVal) {
          SideBarMenu.selectItemByName('login')
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.user = User.current()
    if (window.location.hostname.includes('mptconsol.co.za')) {
      this.setServerLive()
    } else if (window.location.hostname === 'localhost') {
      this.setServerLocal()
    } else {
      this.setServerTest()
    }
  },
  mounted () {
    this.getHeight()
    window.addEventListener('resize', () => {
      this.getHeight()
    })
  },
  methods: {
    ...mapActions([
      'setServerLive',
      'setServerLocal',
      'setServerTest',
      'setWindowHeight'
    ]),
    findEnquiries (value) {
      console.log('findEnquiries left bar!', value)
    },
    getHeight () {
      const windowHeight = window.innerHeight
      let topHeight = 0
      if (this.$refs.topBar) {
        topHeight = this.$refs.topBar.clientHeight
      }
      this.setWindowHeight(windowHeight - topHeight)
    },
    setCurrentPage (sideBarMenuItem) {
      if (!sideBarMenuItem) {
        this.currentMainView = 'Login'
      } else {
        if (sideBarMenuItem.hasTopMenu()) {
          this.currentMainView = sideBarMenuItem.parent.to
        } else if (
          sideBarMenuItem.to &&
          (!sideBarMenuItem.hasSubmenu() || sideBarMenuItem.to === 'Enquiries')
        ) {
          this.currentMainView = sideBarMenuItem.to
        }
      }
    }
  }
}
</script>
